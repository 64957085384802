import React, { FC, useEffect, useRef, useState } from 'react';
import PanelAChart from '../PanelAChart';
import {
  CommentsCell,
  FindingCell,
  FormattedText,
  Marker,
  MarkerCell,
  Row,
  TitleCell,
  PrintLink,
} from './styles';
import { Props } from './types';

const RowA: FC<Props> = ({
  examination,
  concernLevel,
  findingLabel,
  findingData,
  comments,
  ...rest
}) => {
  const element = useRef<HTMLTableDataCellElement>(null);
  const [urlList, setUrlList] = useState<string[]>([]);

  useEffect(() => {
    const urls: string[] = [];
    const anchors = Array.from(
      element.current?.getElementsByTagName('a') ?? [],
    );
    anchors.forEach(
      (anchor: HTMLAnchorElement) => anchor?.href && urls.push(anchor?.href),
    );
    setUrlList(urls);
  }, [element, setUrlList]);

  return (
    <Row {...rest}>
      <TitleCell>{examination}</TitleCell>
      <MarkerCell>
        {(findingData === undefined || findingData === null) && (
          <Marker marker={concernLevel} />
        )}
      </MarkerCell>
      <FindingCell>
        {findingLabel && <FormattedText>{findingLabel}</FormattedText>}
        {(findingData?.value !== undefined ||
          (findingData?.lowerBound !== undefined &&
            findingData?.upperBound !== undefined)) && (
          <PanelAChart
            max={findingData.max}
            min={findingData.min}
            value={findingData.value}
            unit={findingData.unit}
            color={concernLevel ?? '#7EB85B'}
            lowerBound={findingData?.lowerBound}
            upperBound={findingData?.upperBound}
          />
        )}
      </FindingCell>
      <CommentsCell ref={element}>
        <FormattedText>{comments}</FormattedText>
        {urlList.length > 0 &&
          urlList?.map(url => <PrintLink key={url}>{url}</PrintLink>)}
      </CommentsCell>
    </Row>
  );
};

export default RowA;
