import React, { FC } from 'react';
import percent from 'utils/percent';
import ChartBar from './DataBlockChartBar';
import {
  Container,
  FindingData,
  Info,
  Label,
  ChartContainer,
  Tag,
  TagContainer,
  Unit,
  Value,
  ValueBox,
} from './styles';
import { Props } from './types';

const DataBlockChart: FC<Props> = ({
  id,
  name,
  value,
  unit,
  concernLevel,
  min,
  max,
  lowerBound,
  upperBound,
  ...rest
}) => {
  const rangeExtension = max - min;
  const highlightStart = percent(lowerBound ?? 0, rangeExtension);
  const highlightEnd = percent(upperBound ?? 0, rangeExtension);
  const valuePosition = percent(value, rangeExtension);

  return (
    <Container {...rest}>
      <ValueBox>
        <Value>{value}</Value>
        <FindingData>
          <Info>
            <Label>{name}</Label>
            {concernLevel && (
              <TagContainer>
                <Tag label={concernLevel.name} />
              </TagContainer>
            )}
          </Info>
          <Unit>{unit}</Unit>
        </FindingData>
      </ValueBox>
      <ChartContainer>
        <ChartBar
          displayValue={value}
          highlightColor={concernLevel?.color ?? undefined}
          highlightEnd={highlightEnd}
          highlightMin={lowerBound}
          highlightMax={upperBound}
          highlightStart={highlightStart}
          rangeMax={max}
          rangeMin={min}
          valuePosition={valuePosition}
        />
      </ChartContainer>
    </Container>
  );
};

export default DataBlockChart;
