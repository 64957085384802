import DefaultTag from 'components/Tag';
import styled from 'styled-components';

export const Container = styled.div`
  @media print {
    break-inside: avoid;
  }
`;

export const ValueBox = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const Value = styled.span`
  font-family: ${({ theme }) => theme.fonts.aeonik};
  font-size: 3rem;
  color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: 100%;
  margin-right: 1rem;
`;

export const FindingData = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Info = styled.div`
  align-items: center;
  display: flex;
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.4;
  margin-right: 1rem;
`;

export const Unit = styled.span`
  color: ${({ theme }) => theme.colors.lightGray};
  font-weight: ${({ theme }) => theme.weights.medium};
  font-size: 0.875rem;
  line-height: 1;
`;

export const TagContainer = styled.div``;

export const Tag = styled(DefaultTag)`
  font-size: 0.875rem;
  line-height: 1.2;
  font-weight: 500;
  padding: 0.125rem 0.5rem;
  align-self: flex-start;
`;

export const ChartContainer = styled.div``;
