import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ZIndex } from 'styles/metrics';

export const Container = styled(motion.div).attrs(() => ({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}))`
  align-items: center;
  background-color: #f5f4f2;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 2rem;
  position: fixed;
  right: 2rem;
  top: 0;
  z-index: ${ZIndex.LOADER};
`;

export const Animation = styled.div`
  height: 5rem;
  width: 5rem;
`;
