import { useState, useEffect, useCallback } from 'react';

const useIsInView = () => {
  const [showSectionNav, setShowSectionNav] = useState(false);
  const biographScoreSection = document.querySelector('#biograph-score');

  const listener = useCallback(() => {
    const viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight,
    );

    const top = biographScoreSection?.getBoundingClientRect()?.top ?? 0;
    setShowSectionNav(top - viewHeight <= 0);
  }, [biographScoreSection]);

  useEffect(() => {
    window.addEventListener('scroll', listener);
    return () => window.removeEventListener('scroll', listener);
  }, [listener]);

  return { showSectionNav };
};

export default useIsInView;
