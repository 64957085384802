import React, { FC } from 'react';
import {
  Container,
  Title,
  Chart,
  BarContainer,
  TableAChartBar,
  TitleContainer,
  Value,
} from './styles';
import { Props } from './types';

const PanelAChart: FC<Props> = ({
  value,
  unit,
  min,
  max,
  color,
  lowerBound,
  upperBound,
  ...rest
}) => (
  <Container {...rest}>
    <TitleContainer>
      <Title>
        {lowerBound !== undefined && upperBound !== undefined
          ? `${lowerBound} - ${upperBound}`
          : value}
      </Title>
      <Value>{unit}</Value>
    </TitleContainer>
    <Chart>
      <BarContainer>
        <TableAChartBar
          value={value}
          min={min}
          max={max}
          color={color ?? '#7EB85B'}
          lowerBound={lowerBound}
          upperBound={upperBound}
        />
      </BarContainer>
    </Chart>
  </Container>
);

export default PanelAChart;
