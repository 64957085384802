import DefaultBiographScore from 'components/BiographScore';
import DefaultBiologicalAge from 'components/BiologicalAge';
import DefaultHeadline from 'components/Headline';
import DefaultPrintArea from 'components/PrintArea';
import DefaultLogo from 'components/vectors/Logo';
import styled from 'styled-components';

export const Container = styled.div``;

export const PrintArea = styled(DefaultPrintArea)`
  @media print {
    break-after: always;
    padding-top: 3rem;
    height: 100vh;
  }
`;

export const Logo = styled(DefaultLogo)`
  height: 1.5rem;
  display: none;

  @media print {
    display: block;
  }
`;

export const Headline = styled(DefaultHeadline)`
  @media print {
    margin-top: 2.375rem;
  }
`;

export const BiographScore = styled(DefaultBiographScore)`
  padding: 10rem 0 0;

  @media print {
    padding: 2rem 0 0;
  }
`;

export const BiologicalAge = styled(DefaultBiologicalAge)`
  padding-top: 6rem;

  @media print {
    padding-top: 2rem;
  }
`;
