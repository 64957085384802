import DefaultBlockTitle from 'components/BlockTitle';
import styled from 'styled-components';
import DefaultSimpleDataBlockChart from './SimpleDataBlockChart';

export const Container = styled.div`
  width: 100%;
  padding-top: 1rem;
  border-top: 0.0625rem solid ${({ theme }) => theme.colors.ivory};
  display: inline-flex;
`;

export const Left = styled.div``;

export const BlockTitle = styled(DefaultBlockTitle)`
  flex: 1 0 50%;
`;

export const Charts = styled.div`
  flex: 1 0 50%;
  padding-top: 0.25rem;
  padding-left: 1rem;
  > * + * {
    margin-top: 1.75rem;
  }
`;

export const Chart = styled(DefaultSimpleDataBlockChart)`
  @media print {
    break-inside: avoid;
  }
`;
