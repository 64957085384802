import React, { FC } from 'react';
import { Svg, Line, Text, Polygon, SecondaryLine } from './styles';
import { Props } from './types';

const TRIANGLE_Y = 4;
const TRIANGLE_SIZE = 0.75;

const Bar: FC<Props> = ({
  displayValue,
  value,
  secondDisplayValue,
  secondValue,
  ...rest
}) => (
  <Svg viewBox="0 0 100 8" {...rest}>
    <Text
      dominantBaseline="middle"
      fill="currentColor"
      textAnchor="middle"
      x={value}
      y="1.5"
    >
      {displayValue ?? value}
    </Text>
    <Polygon
      fill="currentColor"
      points={`
        ${value - TRIANGLE_SIZE},${TRIANGLE_Y}
        ${value + TRIANGLE_SIZE},${TRIANGLE_Y}
        ${value},${TRIANGLE_Y + TRIANGLE_SIZE}`}
    />
    <Line
      stroke="currentColor"
      strokeWidth="0.1rem"
      x1={0}
      y1={8}
      x2={value - 0.25}
      y2={8}
    />
    {secondValue && (
      <>
        <Text
          dominantBaseline="middle"
          fill="currentColor"
          textAnchor="middle"
          x={secondValue}
          y="1.5"
        >
          {secondDisplayValue ?? secondValue}
        </Text>
        <Polygon
          fill="currentColor"
          points={`
            ${secondValue - TRIANGLE_SIZE},${TRIANGLE_Y}
            ${secondValue + TRIANGLE_SIZE},${TRIANGLE_Y}
            ${secondValue},${TRIANGLE_Y + TRIANGLE_SIZE}`}
        />
        <SecondaryLine
          stroke="currentColor"
          strokeWidth="0.1rem"
          x1={value + 0.25}
          y1={8}
          x2={secondValue}
          y2={8}
        />
      </>
    )}
    <Line
      stroke="currentColor"
      strokeWidth="0.01rem"
      x1={0}
      y1={8}
      x2={100}
      y2={8}
    />
  </Svg>
);

export default Bar;
