import React, { FC } from 'react';
import { Props } from './types';
import { Container, Tag } from './styles';

const Tags: FC<Props> = ({ items, ...rest }) => (
  <Container {...rest}>
    {items.map(tag => (
      <Tag key={tag.value} label={tag.value} />
    ))}
  </Container>
);

export default Tags;
