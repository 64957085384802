import BlockTitle from 'components/BlockTitle';
import React, { FC } from 'react';
import { Container, Content } from './styles';
import { Props } from './types';

const ReportSection: FC<Props> = ({ title, subtitle, children, ...rest }) => (
  <Container {...rest}>
    <Content>
      <BlockTitle index={subtitle} title={title} />
      {children}
    </Content>
  </Container>
);

export default ReportSection;
