export enum ResultType {
  TableA = 'ComponentTableA',
  TableB = 'ComponentTableB',
  TableC = 'ComponentTableC',
  SectionHeader = 'ComponentSectionHeader',
  BlockImage = 'ComponentBlockImage',
}

export type TableAFindingData = {
  value?: number;
  unit: string;
  min: number;
  max: number;
  lowerBound?: number;
  upperBound?: number;
};

export type TableARow = {
  id: string;
  examination?: string;
  concernLevel?: string;
  findingLabel?: string;
  findingData?: TableAFindingData;
  comments?: string;
};

export interface TableAItem {
  id: string;
  name: string;
  rows: TableARow[];
  type: ResultType.TableA;
}

export type TableBRow = {
  id: string;
  therapeutic?: string;
  drug?: string;
  phenotype?: string;
  comments?: string;
  gene?: string;
  genotype?: string;
};

export interface TableBItem {
  id: string;
  name: string;
  rows: TableBRow[];
  type: ResultType.TableB;
}

export type TableCRow = {
  id: string;
  healthCategory?: string;
  gene?: string;
  classification?: string;
  associatedCondition?: string;
  inh?: string;
  zygosity?: string;
  transcript?: string;
  variant?: string;
  alleleFrequency?: string;
};

export interface TableCItem {
  id: string;
  name: string;
  rows: TableCRow[];
  type: ResultType.TableC;
}

export interface SectionHeaderItem {
  id: string;
  name: string;
  type: ResultType.SectionHeader;
}

export interface BlockImageItem {
  id: string;
  file?: string;
  title: string;
  subtitle?: string;
  type: ResultType.BlockImage;
}

export type Result =
  | TableAItem
  | TableBItem
  | TableCItem
  | SectionHeaderItem
  | BlockImageItem;

export * from './logic';
