import React, { FC, useEffect, useState } from 'react';
import lottie from 'lottie-web';
import lottieAnimation from 'assets/animations/loader.json';
import { AnimatePresence } from 'framer-motion';
import { Container, Animation } from './styles';
import { Props } from './types';

const animationName = 'loader';

const Loader: FC<Props> = ({ loading, ...rest }) => {
  const [internalLoading, setInternalLoading] = useState(loading);

  useEffect(() => {
    const element = document.getElementById('lottie-loader');
    lottie.loadAnimation({
      container: element!,
      renderer: 'svg',
      animationData: lottieAnimation,
      name: animationName,
      autoplay: false,
      loop: true,
    });
  }, []);

  useEffect(() => {
    if (loading) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      document.body.style.overflow = 'hidden';
      setInternalLoading(true);
      lottie.play(animationName);
    } else {
      setTimeout(() => {
        document.body.style.overflow = '';
        setInternalLoading(false);
      }, 2400);
    }
  }, [loading]);

  return (
    <AnimatePresence initial={false}>
      {internalLoading && (
        <Container>
          <Animation {...rest} id="lottie-loader" />
        </Container>
      )}
    </AnimatePresence>
  );
};

export default Loader;
