import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg width={947} height={956} fill="none" viewBox="0 0 947 956" {...props}>
    <path
      d="M516.832-458.131c.753.781 95.491 125.611 69.094 203.112-26.556 77.956-292.264 36.783-229.056 165.214C420.778 39.092 584.049 3.823 575.742 65.287c-6.36 46.838-104.159 68.045-153.535 111.714-17.933 15.836-29.304 34.329-28.133 57.816 2.241 45.211 98.469 31.65 200.965 22.083 98.574-9.197 205.064-19.299 240.003 27.048 59.728 79.216-172.898 176.12-142.299 235.937 11.706 22.882 15.023 49.31 13.516 86.216-.949 23.217-.215 42.715-2.517 67.665-2.99 32.332-12.77 62.327-10.297 90.546 1.895 21.816 8.885 40.734 17.904 53.511 46.114 65.292 321.391-19.442 339.181 8.194 17.88 27.784-125.296 57.609-113.221 105.103 12.202 47.392 104.741 18.827 127.321 82.17 10.65 29.91-36.7 82.37-83.489 133.18-54.659 59.27-107.392 119.24-75.658 144.34"
      stroke="#CDC3B0"
      strokeWidth=".032"
      strokeMiterlimit="10"
    />
    <path
      d="M499.61-451.932c1.498 1.559 93.684 122.982 69.11 198.581-24.883 76.548-281.571 37.629-219.138 162.195C412.699 34.772 571.954.76 563.622 61.994c-6.656 48.428-105.679 69.822-153.616 113.883-17.408 15.937-27.749 34.276-25.556 57.409 4.168 44.521 100.901 31.546 202.438 24.59 99.035-6.785 206.923-17.182 239.867 30.814 55.472 80.839-177.141 171.759-147.019 235.187 12.873 27.096 19.931 49.193 20.15 87.753.137 25.452 4.627 41.828 2.568 69.123-2.602 34.422-19.762 64.172-17.2 94.135 1.995 23.78 11.437 43.852 20.638 55.468 47.9 60.505 313.048-20.467 330.718 5.636 17.83 26.358-121.126 55.086-108.985 100.74 12.121 45.554 99.905 18.964 124.755 79.128 12.04 29.17-35.93 82.83-82.233 133.49-53.951 58.85-103.318 119.53-72.261 144.7"
      stroke="#CDC3B0"
      strokeWidth=".065"
      strokeMiterlimit="10"
    />
    <path
      d="M482.368-445.752c2.262 2.343 91.883 120.352 69.14 194.037-23.192 75.157-270.619 38.358-209.155 159.18C404.845 30.325 559.893-2.342 551.514 58.67c-6.948 50.008-107.218 71.604-153.723 116.052-16.888 16.052-26.183 34.223-22.96 57.002 6.111 43.84 103.371 31.491 203.919 27.109 99.527-4.335 208.812-15.128 239.732 34.594 51.228 82.357-181.636 167.536-151.783 234.433 14.004 31.373 24.976 49.078 26.82 89.292 1.268 27.695 9.502 40.93 7.69 70.585-2.223 36.52-26.809 66.019-24.165 97.745 2.107 25.743 13.975 46.913 23.394 57.439 49.689 55.507 304.652-21.49 322.202 3.073 17.81 24.923-116.866 52.533-104.724 96.364 12.095 43.687 95.244 19.118 122.174 76.063 13.43 28.39-35.14 83.3-80.97 133.81-53.231 58.43-99.218 119.84-68.834 145.06"
      stroke="#CDC3B0"
      strokeWidth=".097"
      strokeMiterlimit="10"
    />
    <path
      d="M465.071-439.553c3.032 3.13 90.077 117.736 69.173 189.483-21.497 73.778-259.578 39.096-199.102 156.161C397.016 25.867 547.807-5.442 539.403 55.34c-7.251 51.603-108.683 73.47-153.822 118.228-16.33 16.197-24.608 34.161-20.346 56.594 8.078 43.143 105.878 31.484 205.417 29.634 100.055-1.859 210.728-13.116 239.613 38.386 46.996 83.783-186.312 163.389-156.586 233.677 15.098 35.677 30.129 48.953 33.549 90.828 2.447 29.948 14.404 40.037 12.861 72.046-1.855 38.618-33.899 67.863-31.172 101.356 2.206 27.714 16.457 49.904 26.171 59.414 51.431 50.307 296.2-22.54 313.642.513 17.76 23.48-112.574 49.97-100.429 91.984 12.084 41.821 97.059 15.822 119.589 73.001 11.57 29.38-34.356 83.76-79.711 134.13-52.517 58.01-95.089 120.15-65.394 145.41"
      stroke="#CDC3B0"
      strokeWidth=".129"
      strokeMiterlimit="10"
    />
    <path
      d="M447.735-433.361c3.792 3.913 88.265 115.123 69.21 184.919-19.776 72.4-248.468 39.826-188.981 153.137C389.232 21.388 535.741-8.563 527.299 51.991c-7.56 53.193-110.236 75.271-153.929 120.408-15.791 16.309-23.02 34.109-17.713 56.187 10.052 42.459 108.421 31.516 206.943 32.159 100.604.66 212.676-11.147 239.489 42.183 42.797 85.103-191.148 159.294-161.433 232.915 16.159 40.019 35.372 48.83 40.33 92.37 3.665 32.192 19.332 39.148 18.06 73.517-1.509 40.729-41.057 69.713-38.237 104.971 2.307 29.69 16.839 55.342 28.969 61.395 40.024 56.201 287.69-23.589 305.023-2.064 17.729 22.021-108.245 47.413-96.112 87.591 12.073 39.952 94.001 15.062 116.991 69.927 12.19 29.1-33.473 84.3-78.436 134.45-51.69 57.65-90.939 120.45-61.921 145.77"
      stroke="#CDC3B0"
      strokeWidth=".161"
      strokeMiterlimit="10"
    />
    <path
      d="M430.374-427.175c4.573 4.703 86.473 112.524 69.24 180.35-18.044 71.055-237.28 40.571-178.791 150.1C381.48 16.866 523.678-11.722 515.198 48.604c-7.861 54.781-111.795 77.07-154.058 122.587-15.249 16.42-21.421 34.056-15.06 55.778 12.041 41.775 110.992 31.602 208.477 34.696 101.182 3.219 214.673-9.24 239.381 45.983 38.625 86.335-196.1 155.231-166.313 232.146 17.186 44.399 40.693 48.702 47.156 93.93 4.92 34.45 24.298 38.258 23.308 74.987-1.149 42.84-48.27 71.564-45.368 108.596 2.407 31.671 18.998 58.922 31.788 63.38 39.14 53.446 279.104-24.664 296.352-4.646 17.694 20.549-104.732 45.211-91.755 83.185 12.889 37.704 90.979 14.284 114.364 66.843 12.82 28.84-32.665 84.78-77.156 134.76-50.952 57.25-86.756 120.76-58.424 146.13"
      stroke="#CDC3B0"
      strokeWidth=".194"
      strokeMiterlimit="10"
    />
    <path
      d="M412.512-419.933c5.347 5.49 84.489 109.855 69.142 175.655-16.245 69.683-225.466 41.304-168.112 146.987 59.921 110.425 197.452 83.207 188.96 143.267-8.148 56.354-113.095 78.834-153.803 124.711-14.665 16.533-19.76 33.984-12.364 55.341 14.016 41.054 113.347 31.696 209.552 37.196 101.539 5.801 208.843-3.325 238.717 49.757 45.692 81.2-200.661 151.104-170.82 231.249 18.158 48.757 45.998 48.524 53.928 95.419 6.207 36.676 29.229 37.347 28.533 76.426-.803 44.938-55.394 73.373-52.416 112.148 2.517 33.629 21.117 62.464 34.567 65.322 38.171 50.661 269.819-25.767 286.95-7.251 17.617 19.05-100.257 42.702-87.16 78.742 12.986 35.734 87.813 13.467 111.479 63.714 13.455 28.57-31.765 85.21-75.68 135.01-50.078 56.8-82.336 121-54.765 146.42"
      stroke="#CDC3B0"
      strokeWidth=".226"
      strokeMiterlimit="10"
    />
    <path
      d="M394.548-412.404c6.106 6.273 82.468 107.187 69.005 170.923-14.423 68.32-213.459 42.032-157.288 143.853C365.411 9.605 498.167-16.249 489.671 43.535c-8.432 57.907-114.331 80.59-153.454 126.811-14.071 16.633-18.029 33.873-9.638 54.89 16.084 40.281 115.625 31.838 210.476 39.691 101.831 8.435 209.383-1.331 237.895 53.505 42.649 82.02-198.949 144.302-175.214 230.303 15.131 54.837 51.31 48.337 60.682 96.891 7.504 38.892 34.15 36.419 33.749 77.843-.446 47.014-62.504 75.174-59.447 115.697 2.614 35.587 23.242 66.001 37.317 67.248 37.171 47.856 260.322-26.881 277.328-9.863 17.511 17.533-94.58 39.673-82.483 74.271 11.979 34.278 84.648 12.625 108.503 60.568 14.089 28.3-30.842 85.61-74.141 135.22-49.172 56.34-77.848 121.22-51.045 146.66"
      stroke="#CDC3B0"
      strokeWidth=".258"
      strokeMiterlimit="10"
    />
    <path
      d="M376.596-404.87c6.88 7.061 80.452 104.521 68.863 166.187-12.586 66.982-201.42 42.75-146.444 140.72C357.392 6.07 485.347-18.407 476.856 41.087c-8.712 59.469-115.553 82.331-153.079 128.914-13.476 16.734-16.345 33.796-6.908 54.438 18.069 39.546 117.901 32.025 211.391 42.196 102.126 11.098 210.016.529 237.049 57.268 39.513 82.92-203.449 140.237-179.601 229.363 15.855 59.271 56.647 48.129 67.433 98.367 8.825 41.098 39.07 35.504 38.983 79.253-.107 49.111-69.623 76.976-66.476 119.241 2.711 37.545 25.355 69.535 40.072 69.184 36.168 45.047 250.761-28.016 267.662-12.473 17.398 15.996-89.858 37.08-77.803 69.802 11.931 32.38 81.531 11.768 105.529 57.41 14.74 28.06-29.904 86.03-72.593 135.44-48.266 55.88-73.345 121.44-47.328 146.92"
      stroke="#CDC3B0"
      strokeWidth=".29"
      strokeMiterlimit="10"
    />
    <path
      d="M358.665-397.33c7.654 7.849 78.456 101.87 68.704 161.449-10.74 65.663-189.33 43.474-135.563 137.568C349.419 2.512 472.541-20.59 464.05 38.635 455.067 99.65 347.275 122.71 311.359 169.64c-12.878 16.834-14.649 33.703-4.173 53.985 20.044 38.813 120.164 32.267 212.295 44.685 102.415 13.805 210.773 2.25 236.177 61.021 36.279 83.89-208.019 136.18-183.976 228.416 16.597 63.687 62.012 47.92 74.202 99.846 10.169 43.295 44.001 34.574 44.21 80.669.241 51.191-76.745 78.775-73.519 122.787 2.809 39.503 27.482 73.072 42.827 71.119 35.165 42.245 241.157-29.171 257.97-15.081 17.259 14.458-85.131 34.491-73.105 65.34 11.893 30.482 78.489 10.872 102.527 54.262 15.406 27.8-28.974 86.43-71.032 135.65-47.352 55.42-68.827 121.66-43.588 147.16"
      stroke="#CDC3B0"
      strokeWidth=".323"
      strokeMiterlimit="10"
    />
    <path
      d="M340.746-389.723c8.413 8.631 76.465 99.236 68.544 156.696-8.87 64.353-177.174 44.192-124.634 134.418C341.497-.988 459.741-22.711 451.259 36.216c-9.265 62.565-117.964 85.814-152.278 133.108-12.277 16.933-12.955 33.622-1.432 53.528 22.031 38.085 122.401 32.541 213.144 47.176 102.683 16.556 222.087-.452 235.264 64.765 18.371 90.925-212.636 132.156-188.315 227.459 17.373 68.095 67.378 47.694 80.962 101.31 11.523 45.485 48.918 33.646 49.445 82.074.578 53.275-83.847 80.566-80.546 126.315 2.917 41.454 29.593 76.602 45.577 73.049 34.15 39.432 231.444-30.35 248.209-17.698 17.095 12.893-80.378 31.907-68.377 60.857 11.842 28.581 75.485 9.96 99.512 51.091 16.098 27.56-28.035 86.84-69.467 135.86-46.428 54.96-64.291 121.86-39.844 147.4"
      stroke="#CDC3B0"
      strokeWidth=".355"
      strokeMiterlimit="10"
    />
    <path
      d="M322.838-382.085c9.186 9.419 74.487 96.598 68.376 151.936-6.974 63.075-164.974 44.906-113.679 131.266C333.603-4.49 446.951-24.831 438.471 33.814c-9.541 64.121-119.152 87.543-151.837 135.191-11.676 17.022-11.261 33.531 1.3 53.074 24.004 37.349 124.618 32.862 213.968 49.666 102.93 19.353 223.617 1.185 234.315 68.521C750.79 431.917 518.94 468.402 543.584 566.76c18.159 72.498 72.752 47.461 87.706 102.775 12.889 47.67 53.823 32.723 54.673 83.493.915 55.357-90.957 82.36-87.567 129.847 3.014 43.411 31.717 80.135 48.325 74.979 33.129 36.615 221.662-31.55 238.409-20.318 16.904 11.328-75.61 29.324-63.644 56.388 11.793 26.686 72.546 9.016 96.474 47.936 16.802 27.33-27.093 87.23-67.877 136.07-45.5 54.48-59.74 122.07-36.077 147.64"
      stroke="#CDC3B0"
      strokeWidth=".387"
      strokeMiterlimit="10"
    />
    <path
      d="M304.957-374.424c9.959 10.206 72.546 93.981 68.202 147.17-5.058 61.81-152.733 45.624-102.704 128.109C325.749-7.97 434.184-26.931 425.707 31.428c-9.808 65.663-120.313 89.277-151.374 137.282-11.082 17.125-9.55 33.444 4.045 52.613 25.976 36.613 126.808 33.22 214.779 52.149 103.154 22.199 225.179 2.809 233.345 72.261 10.848 92.341-221.941 124.121-196.93 225.523 18.965 76.888 78.147 47.226 94.457 104.233 14.265 49.848 58.738 31.802 59.904 84.893 1.254 57.439-98.059 84.152-94.591 133.383 3.112 45.364 33.824 83.661 51.07 76.905 32.097 33.81 211.805-32.764 228.574-22.939 16.675 9.768-70.819 26.736-58.892 51.902 11.753 24.787 69.662 8.037 93.432 44.767 17.53 27.09-26.148 87.62-66.281 136.26-44.562 54.01-55.178 122.28-32.306 147.87"
      stroke="#CDC3B0"
      strokeWidth=".419"
      strokeMiterlimit="10"
    />
    <path
      d="M287.104-366.754c10.717 10.988 70.637 91.365 68.011 142.406-3.109 60.562-140.466 46.326-91.718 124.938 54.536 87.945 158.021 70.364 149.549 128.437C402.864 96.244 291.469 120.03 262.039 168.4c-10.476 17.223-7.852 33.351 6.791 52.152 27.945 35.875 128.978 33.625 215.559 54.635 103.347 25.088 226.789 4.403 232.347 76.013 7.216 93.01-226.642 120.128-201.21 224.547 19.796 81.278 80.592 48.268 101.205 105.698 18.249 50.873 63.637 30.876 65.123 86.298 1.595 59.528-105.145 85.937-101.598 136.909 3.219 47.314 35.943 87.191 53.81 78.832 31.07 30.995 199.988-30.729 218.715-25.56 14.023 11.128-66.026 24.159-54.144 47.415 11.706 22.91 66.838 7.03 90.373 41.61 18.271 26.86-25.21 88.02-64.674 136.46-43.623 53.54-50.611 122.48-28.541 148.1"
      stroke="#CDC3B0"
      strokeWidth=".452"
      strokeMiterlimit="10"
    />
    <path
      d="M269.271-359.057c11.488 11.775 68.752 88.767 67.825 137.632-1.128 59.332-128.159 47.019-80.716 121.775 53.776 84.722 152.3 68.522 143.833 126.306-10.358 68.76-122.632 92.718-150.416 141.448-9.871 17.311-6.15 33.268 9.536 51.69 29.914 35.136 131.122 34.058 216.32 57.12 103.516 28.013 228.446 5.986 231.332 79.747 3.663 93.652-236.649 118.212-205.469 223.566 24.859 84 86.099 47.988 107.931 107.155 19.57 53.039 68.541 29.944 70.335 87.7 1.923 61.611-112.231 87.726-108.606 140.438 3.317 49.267 38.046 90.71 56.544 80.745 30.039 28.175 190.07-31.606 208.831-28.181 13.711 9.822-61.223 21.593-49.374 42.936 11.67 21.03 64.079 5.99 87.311 38.44 19.044 26.6-24.258 88.4-63.063 136.65-42.679 53.05-46.035 122.67-24.76 148.31"
      stroke="#CDC3B0"
      strokeWidth=".484"
      strokeMiterlimit="10"
    />
    <path
      d="M251.469-351.353c12.259 12.562 66.912 86.166 67.633 132.855.897 58.115-115.839 47.712-69.705 118.61 53.02 81.481 146.565 66.676 138.101 124.161-10.618 70.306-123.771 94.442-149.908 143.53-9.265 17.399-4.449 33.174 12.283 51.226 31.882 34.407 133.228 34.533 217.07 59.597 103.657 30.992 230.143 7.555 230.287 83.485.181 94.282-241.118 114.099-209.698 222.575 25.61 88.42 91.62 47.696 114.658 108.605 20.894 55.214 73.429 29.016 75.554 89.094 2.262 63.688-119.307 89.513-115.597 143.951 3.414 51.218 40.16 94.234 59.276 82.664 29.009 25.36 180.142-32.495 198.929-30.801 13.408 8.511-56.415 19.027-44.615 38.451 11.623 19.16 61.381 4.91 84.231 35.27 19.843 26.36-23.303 88.79-61.453 136.84-41.741 52.58-41.464 122.88-20.987 148.54"
      stroke="#CDC3B0"
      strokeWidth=".516"
      strokeMiterlimit="10"
    />
    <path
      d="M233.696-343.626c13.014 13.343 65.118 83.564 67.437 128.075 2.963 56.912-103.49 48.394-58.69 115.442 52.27 78.235 140.816 64.82 132.359 122.014-10.891 71.846-124.903 96.153-149.401 145.605-8.667 17.5-2.746 33.088 15.027 50.761 33.845 33.665 135.309 35.035 217.793 62.077 103.751 34.007 231.887 9.136 229.237 87.224-3.221 94.921-245.573 109.999-213.919 221.585 26.34 92.857 97.141 47.39 121.376 110.052 22.193 57.398 78.31 28.087 80.755 90.492 2.589 65.768-126.362 91.293-122.573 147.469 3.51 53.168 42.256 97.76 62.013 84.58 27.974 22.54 170.201-33.388 189.014-33.414 13.095 7.204-51.588 16.464-39.837 33.974 11.604 17.29 65.151-.47 81.159 32.1 25.962 21.37-22.361 89.17-59.83 137.03-40.79 52.09-36.883 123.06-17.201 148.74"
      stroke="#CDC3B0"
      strokeWidth=".548"
      strokeMiterlimit="10"
    />
    <path
      d="M215.954-335.892c13.782 14.129 63.361 80.974 67.225 123.298 5.078 55.707-91.116 49.064-47.677 112.262 51.543 74.986 135.051 62.976 126.613 119.874-11.164 73.384-126.037 97.868-148.869 147.681-8.059 17.587-1.091 33.045 17.762 50.309 35.718 33.007 137.356 35.58 218.499 64.556 103.817 37.074 233.66 10.708 228.156 90.957-6.559 95.552-250.022 105.892-218.121 220.591 27.053 97.286 105.071 45.924 128.075 111.511 21.275 60.626 83.172 27.162 85.963 91.891 2.917 67.848-133.415 93.077-129.549 150.99 3.622 55.122 44.364 101.282 64.735 86.502 26.94 19.72 160.253-34.27 179.085-36.039 12.779 5.899-46.752 13.919-35.072 29.489 11.575 15.44 62.338-1.61 78.067 28.92 26.916 20.81-21.405 89.55-58.203 137.21-39.848 51.61-32.296 123.26-13.422 148.97"
      stroke="#CDC3B0"
      strokeWidth=".581"
      strokeMiterlimit="10"
    />
    <path
      d="M198.241-328.137c14.549 14.914 61.669 78.363 67.018 118.513 7.269 54.502-78.727 49.73-36.661 109.09C279.42-28.802 357.883-39.417 349.455 17.188c-11.432 74.93-127.159 99.581-148.338 149.749-7.463 17.678.609 32.95 20.502 49.842 37.677 32.275 139.361 36.148 219.198 67.027 103.837 40.179 235.495 12.299 227.068 94.682-9.84 96.201-254.448 101.785-222.303 219.593 27.755 101.718 110.445 45.648 134.769 112.953 22.695 62.763 88.037 26.231 91.148 93.284C674.743 874.243 531.045 899.177 535 958.82c3.718 57.07 46.453 104.79 67.451 88.41 25.901 16.91 150.292-35.16 169.145-38.65 12.466 4.6-41.893 11.37-30.307 25 11.56 13.59 59.529-2.75 74.985 25.76 27.88 20.24-20.456 89.93-56.568 137.39-38.901 51.13-27.709 123.45-9.635 149.18"
      stroke="#CDC3B0"
      strokeWidth=".613"
      strokeMiterlimit="10"
    />
    <path
      d="M180.543-320.375c15.3 15.694 60.025 75.751 66.809 113.727 9.514 53.282-66.309 50.38-25.65 105.917C271.82-32.273 345.22-41.464 336.798 14.838c-11.69 76.46-128.271 101.291-147.783 151.819-6.866 17.769 2.311 32.863 23.239 49.375 39.63 31.532 141.337 36.734 219.873 69.501 103.825 43.316 237.354 13.892 225.961 98.418-13.058 96.877-258.86 97.668-226.469 218.593 28.443 106.152 115.809 45.371 141.452 114.392 24.112 64.899 92.893 25.3 96.323 94.675 3.572 72-147.468 96.634-143.449 158.007 3.815 59.012 48.552 108.302 70.16 90.322 24.862 14.09 140.333-36.03 159.202-41.27 12.16 3.29-37.029 8.84-25.528 20.52 11.575 11.77 56.706-3.89 71.885 22.59 28.842 19.68-19.509 90.3-54.944 137.57-37.956 50.64-23.122 123.64-5.85 149.38"
      stroke="#CDC3B0"
      strokeWidth=".645"
      strokeMiterlimit="10"
    />
    <path
      d="M162.881-312.599c16.063 16.479 50.057 77.049 66.594 108.941 24.025 46.344-53.887 51.014-14.644 102.742 49.436 65.183 117.744 57.407 109.336 113.423-11.955 78.003-129.386 103.006-147.223 153.879-6.258 17.854 4.012 32.774 25.974 48.905 41.582 30.799 143.277 37.353 220.534 71.974 103.761 46.504 239.264 15.499 224.837 102.142-16.241 97.571-263.27 93.57-230.616 217.59 29.118 110.589 121.169 45.078 148.109 115.833 25.525 67.023 97.73 24.373 101.502 96.059 3.899 74.075-154.479 98.413-150.372 161.515 3.911 60.966 50.634 111.816 72.865 92.236 23.824 11.27 130.357-36.92 149.254-43.89 11.843 1.98-32.125 6.31-20.765 16.04 11.615 9.93 53.882-5.04 68.794 19.4 29.802 19.13-18.564 90.67-53.302 137.76-37.009 50.15-18.54 123.81-2.078 149.59"
      stroke="#CDC3B0"
      strokeWidth=".677"
      strokeMiterlimit="10"
    />
    <path
      d="M145.241-304.819c16.826 17.263 49.076 74.476 66.362 104.148 26.06 44.737-41.439 51.634-3.65 99.557C256.735-39.23 319.925-45.559 311.525 10.152 299.302 89.687 181.041 114.856 164.864 166.1c-5.662 17.945 5.722 32.681 28.704 48.436 43.537 30.05 145.187 37.989 221.195 74.448 103.66 49.715 241.205 17.14 223.713 105.869-19.374 98.287-267.651 89.456-234.744 216.583 29.781 115.027 126.509 44.8 154.766 117.276 26.949 69.151 102.556 23.447 106.658 97.446 4.216 76.152-161.473 100.191-157.277 165.019 4.019 62.902 52.725 115.322 75.559 94.132 22.772 8.46 120.392-37.81 139.306-46.5 11.524.67-27.189 3.77-15.995 11.54 11.682 8.12 51.068-6.18 65.694 16.25 30.759 18.57-17.616 91.04-51.662 137.92-36.06 49.66-13.957 124 1.702 149.79"
      stroke="#CDC3B0"
      strokeWidth=".71"
      strokeMiterlimit="10"
    />
    <path
      d="M127.625-297.024c17.573 18.042 48.26 71.796 66.142 99.361 28.011 43.188-28.971 52.227 7.337 96.38 48.156 58.56 106.197 53.694 97.812 109.118-12.475 81.072-131.586 106.406-146.082 158.013-5.065 18.035 7.416 32.583 31.42 47.971 45.481 29.316 147.057 38.64 221.832 76.917 103.511 52.977 243.179 18.793 222.57 109.587-22.47 99.032-272.027 85.359-238.86 215.576 30.428 119.459 131.859 44.501 161.409 118.708 28.378 71.263 107.384 22.514 111.819 98.836 4.543 78.223-168.444 101.963-164.181 168.527 4.115 64.84 54.798 118.83 78.252 96.04 21.73 5.64 110.434-38.69 129.364-49.11 11.207-.63-22.201 1.22-11.237 7.07 11.81 6.3 48.245-7.32 62.602 13.07 31.711 18-16.67 91.4-50.018 138.1-35.112 49.16-9.377 124.18 5.473 150.01"
      stroke="#CDC3B0"
      strokeWidth=".742"
      strokeMiterlimit="10"
    />
    <path
      d="M110.045-289.211c18.332 18.825 47.58 68.966 65.918 94.572 29.861 41.725-16.482 52.805 18.312 93.203 47.561 55.223 100.413 51.846 92.048 106.96-12.739 82.6-132.685 108.12-145.496 160.063-4.481 18.13 9.111 32.494 34.141 47.5 47.421 28.582 148.886 39.314 222.456 79.383 103.311 56.256 245.168 20.475 221.423 113.298-25.536 99.809-276.383 81.252-242.957 214.556 31.077 123.896 137.183 44.206 168.023 120.142 29.805 73.373 112.037 21.619 116.955 100.218 4.717 80.326-175.407 103.738-171.056 172.026 4.212 66.78 56.881 122.33 80.937 97.95 20.692 2.82 100.477-39.58 119.421-51.73 10.888-1.94-17.165-1.37-6.474 2.59 12.002 4.44 45.42-8.47 59.509 9.89 32.652 17.44-15.732 91.77-48.375 138.27-34.173 48.67-4.805 124.35 9.246 150.19"
      stroke="#CDC3B0"
      strokeWidth=".774"
      strokeMiterlimit="10"
    />
    <path
      d="M92.48-281.402c19.087 19.598 52.674 62.293 65.692 89.784 22.073 46.604-3.977 53.359 29.274 90.025 47.012 51.842 94.64 49.985 86.287 104.8-13.003 84.129-133.77 109.819-144.913 162.128-3.882 18.229 10.804 32.404 36.859 47.028 49.352 27.838 150.685 39.996 223.08 81.842 103.061 59.578 247.192 22.179 220.263 117.021-28.571 100.628-280.737 77.142-247.037 213.545 31.709 128.325 142.507 43.904 174.634 121.568 31.23 75.481 116.858 20.691 122.091 101.593 5.055 82.39-182.354 105.512-177.929 175.518 4.309 68.72 58.945 125.83 83.616 99.85 19.65.01 90.528-40.46 109.474-54.33 10.58-3.25-12.057-4.03-1.726-1.89 12.247 2.53 39.388-6.6 56.409 6.73 28.972 22.67-14.786 92.13-46.743 138.44-33.235 48.18-.234 124.53 13.016 150.39"
      stroke="#CDC3B0"
      strokeWidth=".806"
      strokeMiterlimit="10"
    />
    <path
      d="M74.95-273.586c19.832 20.385 51.342 59.473 65.452 85.002 24.884 45.025 8.539 53.876 40.206 86.841C227.122-53.314 269.483-53.61 261.154.904c-13.251 85.659-134.868 111.534-144.318 164.173-3.286 18.319 12.495 32.315 39.572 46.557 51.283 27.103 152.445 40.691 223.686 84.304 102.776 62.921 249.227 23.917 219.104 120.727-31.581 101.469-285.062 73.036-251.093 212.526 32.33 132.758 147.82 43.603 181.235 123.003 32.651 77.588 121.665 19.753 127.206 102.972 5.394 84.461-189.287 107.284-184.773 179.014 4.416 70.65 61.018 129.33 86.288 101.75 18.61-2.81 78.686-44.04 99.546-56.94 9.641-5.97-6.922-6.77 3.014-6.37 12.556.51 37.127-8.09 53.319 3.56 30.337 21.84-13.855 92.48-45.097 138.61-32.297 47.68 4.332 124.71 16.769 150.58"
      stroke="#CDC3B0"
      strokeWidth=".839"
      strokeMiterlimit="10"
    />
    <path
      d="M57.436-265.765c20.586 21.167 50.049 56.582 65.221 80.215 27.801 43.322 21.088 54.35 51.138 83.663C219.873-56.944 256.904-55.615 248.588-1.4c-13.513 87.184-135.949 113.234-143.724 166.233-2.702 18.412 14.179 32.214 42.28 46.083 53.206 26.359 154.172 41.396 224.292 86.76 102.434 66.286 251.294 25.677 217.927 124.435-34.57 102.357-289.381 68.937-255.142 211.511 32.952 137.197 153.115 43.291 187.804 124.43 34.081 79.689 126.461 18.815 132.309 104.349 5.742 86.516-196.19 109.051-191.599 182.499 4.512 72.6 63.073 132.82 88.966 103.65 17.571-5.62 68.902-44.24 89.626-59.55 9.311-6.88-1.801-9.61 7.757-10.85 12.842-1.66 34.9-9.65 50.222.4 31.816 20.84-12.921 92.84-43.453 138.77-31.36 47.18 8.888 124.87 20.528 150.78"
      stroke="#CDC3B0"
      strokeWidth=".871"
      strokeMiterlimit="10"
    />
    <path
      d="M39.942-257.934c21.336 21.938 48.783 53.623 64.988 75.43 30.815 41.473 33.655 54.783 62.052 80.484 45.713 41.372 77.352 44.42 69.048 98.327C222.259 85.025 98.995 111.255 92.91 164.582c-2.109 18.501 15.864 32.124 44.983 45.611 55.139 25.619 155.869 42.1 224.88 89.219 102.05 69.678 253.366 27.473 216.761 128.145-37.555 103.279-293.689 64.829-259.179 210.496 33.558 141.627 158.404 42.988 194.372 125.85 35.507 81.788 131.249 17.886 137.412 105.719 6.082 88.584-203.09 110.821-198.421 185.998 4.608 74.53 65.138 136.31 91.623 105.53 16.531-8.43 59.29-44.48 79.717-62.16 9.066-7.83 3.228-12.52 12.486-15.31 13.041-3.94 32.659-11.25 47.136-2.78 33.445 19.59-11.991 93.2-41.808 138.95-30.425 46.67 13.441 125.05 24.271 150.97"
      stroke="#CDC3B0"
      strokeWidth=".903"
      strokeMiterlimit="10"
    />
    <path
      d="M22.482-250.109c22.077 22.723 47.522 50.599 64.753 70.646 33.907 39.457 46.247 55.153 72.95 77.305 45.438 37.697 71.599 42.56 63.311 96.178C209.466 84.26 85.308 110.68 80.987 164.346c-1.551 18.594 17.558 32.027 47.67 45.142 57.054 24.875 157.528 42.817 225.464 91.676 101.62 73.088 255.448 29.294 215.577 131.848-40.514 104.223-297.992 60.738-263.204 209.479 34.15 146.051 163.677 42.676 200.926 127.269 36.931 83.886 136.014 16.952 142.497 107.103 6.418 90.641-209.975 112.59-205.221 189.477 4.705 76.46 63.103 136.48 94.278 107.43 13.961-13.01 49.803-44.81 69.823-64.76 8.889-8.86 8.144-15.45 17.216-19.79 13.105-6.29 30.409-12.92 44.042-5.94 35.217 18.01-11.144 93.52-40.178 139.1-29.573 46.15 17.988 125.23 28.022 151.17"
      stroke="#CDC3B0"
      strokeWidth=".935"
      strokeMiterlimit="10"
    />
    <path
      d="M5.04-242.274c22.827 23.503 46.25 47.503 64.507 65.867 37.053 37.284 58.854 55.441 83.825 74.117 45.28 33.85 65.854 40.71 57.578 94.02C196.675 83.497 71.724 110.094 69.042 164.099c-.96 18.682 19.237 31.936 50.362 44.669 58.968 24.139 159.165 43.528 226.05 94.125 101.145 76.515 257.54 31.137 214.402 135.554-43.476 105.214-302.459 56.705-267.214 208.462 34.566 150.548 169.047 42.313 207.456 128.699 38.459 85.924 140.782 16.023 147.579 108.47 6.767 92.702-216.832 114.354-212.011 192.962 4.812 78.39 67.151 141.81 96.925 109.33 13.569-14.8 40.399-45.22 59.94-67.36 8.766-9.94 12.938-18.35 21.929-24.26 13.031-8.59 28.117-14.65 40.964-9.1 37.154 16.03-10.187 93.88-38.534 139.27-28.609 45.66 22.524 125.39 31.752 151.36"
      stroke="#CDC3B0"
      strokeWidth=".968"
      strokeMiterlimit="10"
    />
    <path
      d="M-12.38-234.442C155.89-61.097 212.964-103.833 198.431-10.546 183.899 82.741 58.172 109.523 57.139 163.87c-1.032 54.346 179.046 60.815 279.671 140.774s259.637 33.017 213.213 139.252c-46.425 106.234-306.55 52.535-271.215 207.444 35.336 154.909 174.202 42.052 213.981 130.114 39.78 88.062 145.537 15.085 152.639 109.841 7.103 94.756-223.684 116.125-218.776 196.445 4.909 80.33 71.28 146.78 99.568 111.22 28.289-35.56 75.354-124.48 114.589-110.96 39.235 13.51-9.24 94.25-36.896 139.43-27.656 45.18 27.056 125.56 35.488 151.54"
      stroke="#CDC3B0"
      strokeMiterlimit="10"
    />
  </svg>
));
