import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Text = styled.h2`
  color: ${({ theme }) => theme.colors.darkerGray};
  font-family: ${({ theme }) => theme.fonts.aeonik};
  font-size: 2.5rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  line-height: 1.4;

  @media print {
    font-size: ${({ theme }) => theme.fontSize.printHeader};
  }
`;
