import styled from 'styled-components';

export const Container = styled.div`
  max-width: 36rem;
  margin: 2.4375rem auto 0;
  display: flex;
  align-items: flex-start;

  &:first-child {
    margin-top: 0;
  }
`;

export const Bullet = styled.div`
  background-color: ${({ theme }) => theme.colors.darkerGray};
  width: 2rem;
  height: 0.125rem;
  margin-right: 1rem;
  margin-top: 0.625rem;
`;

export const Text = styled.span`
  color: ${({ theme }) => theme.colors.darkerGray};
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.4;
  flex: 1;
`;
