import { Risk } from 'components/Rating/types';
import {
  GetPatient_insights_blocks_ComponentBlockSimpleData,
  GetPatient_keyFindings_blocks_ComponentBlockData,
  GetPatient_keyFindings_blocks_ComponentBlockImage,
  GetPatient_keyFindings_blocks_ComponentBlockText,
  GetPatient_keyFindings_blocks_ComponentBlockTreatment,
} from 'graphql/types/GetPatient';
import { ENUM_KEYFINDING_RISK } from 'graphql/types/globalTypes';
import { Block, BlockType as Blocks } from '.';

// eslint-disable-next-line import/prefer-default-export
export const formatBlock = (
  block:
    | GetPatient_insights_blocks_ComponentBlockSimpleData
    | GetPatient_keyFindings_blocks_ComponentBlockData
    | GetPatient_keyFindings_blocks_ComponentBlockText
    | GetPatient_keyFindings_blocks_ComponentBlockImage
    | GetPatient_keyFindings_blocks_ComponentBlockTreatment,
): Block => {
  // eslint-disable-next-line no-underscore-dangle
  switch (block.__typename) {
    case 'ComponentBlockData':
      return {
        id: block.id,
        title: block.title,
        items:
          block.items
            ?.map(it => it!)
            .map(
              ({
                id,
                name,
                value,
                min,
                max,
                unit,
                concern_level,
                lower_bound,
                upper_bound,
              }) => ({
                id,
                name,
                value,
                min,
                max,
                unit: unit ?? undefined,
                concernLevel: concern_level ?? undefined,
                lowerBound: lower_bound ?? undefined,
                upperBound: upper_bound ?? undefined,
              }),
            ) ?? [],
        type: Blocks.Data,
      };
    case 'ComponentBlockText':
      return {
        id: block.id,
        title: block.title,
        description: block.description,
        type: Blocks.Text,
      };
    case 'ComponentBlockImage':
      return {
        id: block.id,
        title: block.title,
        subtitle: block.subtitle,
        file: (process.env.REACT_APP_API_URI ?? '') + (block.file?.url ?? ''),
        type: Blocks.Image,
      };
    case 'ComponentBlockSimpleData':
      return {
        id: block.id,
        title: block.title,
        items:
          block.items
            ?.map(it => it!)
            .map(({ id, name, value, min, max, label }) => ({
              id,
              name,
              value,
              min,
              max,
              label,
            })) ?? [],
        type: Blocks.SimpleData,
      };
    case 'ComponentBlockTreatment':
    default:
      return {
        id: block.id,
        description: block.description,
        type: Blocks.Treatment,
      };
  }
};

export const formatRisk = (risk: ENUM_KEYFINDING_RISK): Risk => {
  switch (risk) {
    case ENUM_KEYFINDING_RISK.high:
      return Risk.HIGH;
    case ENUM_KEYFINDING_RISK.increased:
      return Risk.INCREASED;
    case ENUM_KEYFINDING_RISK.low:
    default:
      return Risk.LOW;
  }
};
