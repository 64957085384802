import PrintArea from 'components/PrintArea';
import React, { FC } from 'react';
import { Container, HeaderContent, TextHighlight, Waves } from './styles';

const ResultsHeader: FC = ({ ...rest }) => (
  <Container {...rest}>
    <Waves />
    <PrintArea>
      <HeaderContent>
        <TextHighlight text="Your comprehensive, head&#8209;to&#8209;toe diagnostic assessment" />
      </HeaderContent>
    </PrintArea>
  </Container>
);

export default ResultsHeader;
