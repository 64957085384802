import React, { FC } from 'react';
import {
  AnimatedChart,
  Container,
  Content,
  GraphContent,
  Info,
  LongevityWaves,
  StaticChart,
  Subtitle,
  Title,
} from './styles';
import { Props } from './types';

const Longevity: FC<Props> = ({ title, ...rest }) => (
  <Container {...rest}>
    <Content>
      <Title text={title} />
      <GraphContent>
        <Subtitle>Longevity = ƒ (lifespan, healthspan)</Subtitle>
        <AnimatedChart />
        <StaticChart />
        <Info>
          When we speak about human longevity, we are in fact referring to two
          distinct concepts: <br /> 1. healthspan - the period of time we live
          healthy and free of disease, and <br /> 2. lifespan - the overall
          chronological length of our existence.
        </Info>
      </GraphContent>
    </Content>
    <LongevityWaves />
  </Container>
);

export default Longevity;
