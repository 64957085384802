import styled from 'styled-components';

export default styled.div<{ dontBreak: boolean }>`
  max-width: 50rem;
  margin: 0 auto;

  @media print {
    padding: 0 3rem;
    break-inside: ${({ dontBreak }) => (dontBreak ? 'avoid' : 'auto')};
  }
`;
