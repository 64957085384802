import {
  GetPatient_patient_comprehensive_result_blocks_ComponentBlockImage,
  GetPatient_patient_comprehensive_result_blocks_ComponentTableSectionHeader,
  GetPatient_patient_comprehensive_result_blocks_ComponentTableTypeATable,
  GetPatient_patient_comprehensive_result_blocks_ComponentTableTypeATable_rows_finding_data,
  GetPatient_patient_comprehensive_result_blocks_ComponentTableTypeBTable,
  GetPatient_patient_comprehensive_result_blocks_ComponentTableTypeCTable,
} from 'graphql/types/GetPatient';
import { Result, ResultType as Results } from '.';

export const formatFindingData = (
  findingDataItem: GetPatient_patient_comprehensive_result_blocks_ComponentTableTypeATable_rows_finding_data | null,
) =>
  findingDataItem
    ? {
        value: findingDataItem.value ?? undefined,
        unit: findingDataItem.unit ?? '',
        min: findingDataItem.min ?? 0,
        max: findingDataItem.max ?? 0,
        lowerBound: findingDataItem.lower_bound ?? undefined,
        upperBound: findingDataItem.upper_bound ?? undefined,
      }
    : null;

export const formatComprehensiveResultItems = (
  block:
    | GetPatient_patient_comprehensive_result_blocks_ComponentTableTypeATable
    | GetPatient_patient_comprehensive_result_blocks_ComponentTableTypeBTable
    | GetPatient_patient_comprehensive_result_blocks_ComponentTableTypeCTable
    | GetPatient_patient_comprehensive_result_blocks_ComponentTableSectionHeader
    | GetPatient_patient_comprehensive_result_blocks_ComponentBlockImage,
): Result => {
  // eslint-disable-next-line no-underscore-dangle
  switch (block.__typename) {
    case 'ComponentBlockImage': {
      return {
        id: block.id,
        file: `${process.env.REACT_APP_API_URI}${block.file?.url}`,
        title: block.title,
        subtitle: block.subtitle ?? undefined,
        type: Results.BlockImage,
      };
    }
    case 'ComponentTableTypeATable': {
      return {
        id: block.id,
        name: block.name,
        rows:
          block.rows
            ?.map(x => x!)
            .map(
              ({
                id,
                examination,
                concern_level,
                finding_label,
                finding_data,
                comments,
              }) => ({
                id,
                examination: examination ?? undefined,
                concernLevel: concern_level?.color ?? '',
                findingLabel: finding_label ?? undefined,
                findingData: formatFindingData(finding_data) ?? undefined,
                comments: comments ?? undefined,
              }),
            ) || [],
        type: Results.TableA,
      };
    }
    case 'ComponentTableTypeBTable': {
      return {
        id: block.id,
        name: block.name,
        rows:
          block.rows
            ?.map(x => x!)
            .map(
              ({
                id,
                therapeutic,
                drug,
                phenotype,
                comments,
                gene,
                genotype,
              }) => ({
                id,
                therapeutic: therapeutic ?? undefined,
                drug: drug ?? undefined,
                phenotype: phenotype ?? undefined,
                comments: comments ?? undefined,
                gene: gene ?? undefined,
                genotype: genotype ?? undefined,
              }),
            ) || [],
        type: Results.TableB,
      };
    }
    case 'ComponentTableTypeCTable': {
      return {
        id: block.id,
        name: block.name,
        rows:
          block.rows
            ?.map(x => x!)
            .map(
              ({
                id,
                health_category,
                gene,
                classification,
                associated_condition,
                inh,
                zygosity,
                transcript,
                variant,
                allele_frequency,
              }) => ({
                id,
                healthCategory: health_category ?? undefined,
                gene: gene ?? undefined,
                classification: classification ?? undefined,
                associatedCondition: associated_condition ?? undefined,
                inh: inh ?? undefined,
                zygosity: zygosity ?? undefined,
                transcript: transcript ?? undefined,
                variant: variant ?? undefined,
                alleleFrequency: allele_frequency ?? undefined,
              }),
            ) || [],
        type: Results.TableC,
      };
    }
    case 'ComponentTableSectionHeader':
    default: {
      return {
        id: block.id,
        name: block.name,
        type: Results.SectionHeader,
      };
    }
  }
};
