import PrintArea from 'components/PrintArea';
import React, { FC, useEffect, useRef, useState } from 'react';
import {
  BlockTitle,
  Container,
  Description,
  CommentsContainer,
  PrintLink,
} from './styles';
import { Props } from './types';

const TextBlock: FC<Props> = ({
  index,
  title,
  description,
  textBlockType = 'findings',
  ...rest
}) => {
  const element = useRef<HTMLDivElement>(null);
  const [urlList, setUrlList] = useState<string[]>([]);

  useEffect(() => {
    const urls: string[] = [];

    const anchors = Array.from(
      element.current?.getElementsByTagName('a') ?? [],
    );

    anchors.forEach(
      (anchor: HTMLAnchorElement) => anchor?.href && urls.push(anchor?.href),
    );
    setUrlList(urls);
  }, [element, setUrlList]);

  return (
    <PrintArea {...rest}>
      <Container textBlockType={textBlockType}>
        <BlockTitle index={index} title={title} />
        <CommentsContainer ref={element}>
          <Description source={description} />
          {urlList.length > 0 &&
            urlList?.map(url => <PrintLink key={url}>{url}</PrintLink>)}
        </CommentsContainer>
      </Container>
    </PrintArea>
  );
};

export default TextBlock;
