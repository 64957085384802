import React, { FC } from 'react';
import { Container, Text } from './styles';
import { Props } from './types';

const Tag: FC<Props> = ({ label, ...rest }) => (
  <Container {...rest}>
    <Text>{label}</Text>
  </Container>
);

export default Tag;
