import DefaultBlockTitle from 'components/BlockTitle';
import styled from 'styled-components';
import DefaultDataBlockChart from './DataBlockChart';

export const Container = styled.div`
  width: 100%;
  padding-top: 1rem;
  border-top: 0.0625rem solid ${({ theme }) => theme.colors.ivory};
  display: inline-flex;
`;

export const BlockTitle = styled(DefaultBlockTitle)`
  flex: 0 0 39%;
`;

export const Charts = styled.div`
  flex: 0 0 26rem;
  padding-top: 0.25rem;
  /* padding-right: 4.5625rem; */

  > * + * {
    margin-top: 1.75rem;
  }
`;

export const Chart = styled(DefaultDataBlockChart)``;
