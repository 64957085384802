import React from 'react';
import { render } from 'react-dom';
import App from './App';
import './styles';

const rootEl = document.getElementById('root');
render(<App />, rootEl);

if (module.hot) {
  module.hot.accept('./App', () => {
    // eslint-disable-next-line global-require
    const NextApp = require('./App').default;
    render(<NextApp />, rootEl);
  });
}
