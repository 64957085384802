import DefaultTextHighlight from 'components/TextHighlight';
import DefaultCircularLogo from 'components/vectors/CircularLogo';
import styled from 'styled-components';
import DefaultPrintArea from 'components/PrintArea';

export const Container = styled.div<{ withTitle: boolean }>`
  background-color: ${({ theme }) => theme.colors.lightIvory};
  color: ${({ theme }) => theme.colors.darkerGray};
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: ${({ withTitle }) =>
    withTitle ? '14.875rem 0 10.0625rem' : '12.875rem 0 13.25rem'};

  @media print {
    break-inside: avoid;
    padding: 10rem 0 8rem;
    height: 100vh;
  }
`;

export const PrintArea = styled(DefaultPrintArea)`
  width: 100%;
`;

export const TextHighlight = styled(DefaultTextHighlight)`
  max-width: 46rem;
  margin-bottom: 20.125rem;
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-width: 40rem;
`;

export const Logo = styled(DefaultCircularLogo)`
  height: 8rem;
`;

export const ContactData = styled.div`
  display: flex;
`;

export const Text = styled.div<{ isTitle?: boolean }>`
  font-size: 1rem;
  line-height: 1.5;

  p:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

export const Email = styled.p`
  text-decoration: underline;
`;
