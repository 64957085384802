import React, { FC } from 'react';
import { Props } from './types';
import { Svg, Circle, Text } from './styles';

const WIDTH = 100;
const STROKE_WIDTH = 4;

const radius = (WIDTH - STROKE_WIDTH) / 2;
const circumferenceLength = 2 * Math.PI * radius;

const Score: FC<Props> = ({ max, min, value, ...rest }) => {
  const position = (value - min) / (max - min);
  const dashOffset = circumferenceLength - circumferenceLength * position;

  return (
    <Svg {...rest} viewBox={`0 0 ${WIDTH} ${WIDTH}`}>
      <Circle
        cx={WIDTH / 2}
        cy={WIDTH / 2}
        fill="none"
        r={radius}
        stroke="currentColor"
        strokeDasharray={`${circumferenceLength} ${circumferenceLength}`}
        strokeDashoffset={dashOffset}
        strokeWidth={STROKE_WIDTH}
      />
      <Text
        dominantBaseline="middle"
        fill="currentColor"
        textAnchor="middle"
        x="50%"
        y="50%"
      >
        {value}
      </Text>
    </Svg>
  );
};

export default Score;
