import React, { FC } from 'react';
import ReportTitle from 'components/ReportTitle';
import { BiographChart, Container, DescriptionText } from './styles';
import { Props } from './types';

const BiographScore: FC<Props> = ({
  radarOptions,
  radarSeries,
  score,
  maxScore,
  ...rest
}) => (
  <Container {...rest}>
    <ReportTitle title="Your Biograph score" />
    <DescriptionText id="biograph-score">
      Your Biograph score shows how you’re doing across all{' '}
      {radarOptions?.xaxis?.categories?.length ?? 12} of our longevity
      dimensions. We will dive deeper into each under the Insights section.
    </DescriptionText>
    <BiographChart
      options={radarOptions}
      series={radarSeries}
      score={score}
      maxScore={maxScore}
    />
  </Container>
);

export default BiographScore;
