import React, { FC } from 'react';
import {
  ArrowIcon,
  Container,
  Content,
  Description,
  Heading,
  Link,
  Text,
  Url,
} from './styles';
import { Props } from './types';

const Document: FC<Props> = ({ title, description, file, ...rest }) => (
  <Container {...rest}>
    <Link href={file || '#'} target="_blank">
      <Content>
        <Text>
          <Heading>{title}</Heading>
          <Url>{file}</Url>
          {description && <Description>{description}</Description>}
        </Text>
        <ArrowIcon />
      </Content>
    </Link>
  </Container>
);

export default Document;
