import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg width={345} height={1215} viewBox="0 0 345 1215" fill="none" {...props}>
    <g opacity=".3" stroke="#626262" strokeWidth=".806" strokeMiterlimit="10">
      <path
        d="M269.824 1293.66s53.652-325.41 7.086-500.214c-38.599-144.873 128.562-325.406 97.181-553.997C346.344 37.321 140.846 287.077 123.04 75.402 103.806-153.19 394.337-245.97 394.337-245.97"
        strokeOpacity=".3"
      />
      <path
        d="M252.675 1294.11s51.526-322.127 15.66-480.931C238.159 679.575 381.42 489.71 355.728 264.373c-22.99-201.617-221.766 27.35-240.048-180.91-19.71-224.637 253.207-329.439 253.207-329.439"
        strokeOpacity=".5"
      />
      <path
        d="M235.537 1294.54s49.542-318.655 24.225-461.672c-21.633-122.229 97.667-321.749 77.613-543.589-18.201-201.321-210.286 7.073-229.054-197.798C88.146-129.176 343.449-246 343.449-246"
        strokeOpacity=".7"
      />
      <path
        d="M218.396 1294.99s47.73-314.644 32.799-442.386c-12.917-110.476 82.31-320.242 67.824-538.398-13.362-201.241-198.806-13.232-218.05-214.66C80.318-117.16 318.007-245.977 318.007-245.977"
        strokeOpacity=".9"
      />
      <path d="M201.251 1295.45s46.09-309.566 41.373-423.108c-4.07-97.944 67.045-318.951 58.035-533.208-8.473-201.348-187.326-33.508-207.046-231.549C72.486-105.138 292.56-245.977 292.56-245.977" />
      <path d="M184.11 1295.89s44.349-302.712 49.936-403.857c4.647-84.095 51.891-317.849 48.257-527.991-3.482-201.618-175.847-53.787-196.053-248.412C64.658-93.14 267.12-245.974 267.12-245.974" />
      <path d="M166.975 1296.34s41.93-294.29 58.511-384.572c12.704-69.169 36.848-316.827 38.468-522.801 1.589-201.993-164.368-74.09-185.05-265.272C56.838-81.123 241.684-245.977 241.684-245.977" />
      <path d="M149.823 1296.79s38.478-286.59 67.085-365.284c19.923-54.808 21.927-315.886 28.679-517.61 6.782-202.451-152.898-94.368-174.045-282.162C48.998-69.103 216.23-245.979 216.23-245.979" />
      <path d="M132.686 1297.23s34.55-281.49 75.65-346.035c26.866-42.168 7.126-314.917 18.899-512.393C239.332 235.921 85.817 324.157 64.184 139.779 41.174-57.105 190.792-245.974 190.792-245.974" />
      <path d="M115.55 1297.69s30.835-278.45 84.223-326.752c34.034-30.793-7.561-313.896 9.111-507.203 17.533-203.258-129.94-134.95-152.048-315.913C33.351-45.082 165.355-245.973 165.355-245.973" />
      <path d="M98.394 1298.14s27.524-276.22 92.798-307.47c41.514-19.9-22.139-312.739-.679-502.012 23.081-203.5-118.46-155.227-141.044-332.775-23.96-188.95 90.429-401.862 90.429-401.862" />
      <path d="M81.264 1298.58s24.507-274.04 101.362-288.22c49.218-9.06-36.636-311.419-10.458-496.792 28.74-203.554-106.98-175.504-130.05-349.637-24.427-184.997 72.349-409.903 72.349-409.903" />
      <path d="M64.124 1299.03s21.714-263.01 109.937-268.93c57.053-3.82-51.061-309.887-20.246-491.603 34.479-203.365-95.501-195.782-119.047-366.526C9.865-9.045 89.028-245.973 89.028-245.973" />
      <path d="M46.973 1299.48s18.98-269.22 118.511-249.64c64.989 12.77-65.446-308.117-30.035-486.417 40.299-202.908-84.032-216.086-108.044-383.387C2.028 2.972 63.576-245.95 63.576-245.95" />
      <path d="M29.833 1299.92s16.339-266.54 127.075-230.39c72.977 23.82-79.8-306.127-39.814-481.2 46.161-202.182-72.552-236.363-97.05-400.276C-5.8 14.97 38.134-246 38.134-246" />
      <path d="M12.698 1300.37s13.747-263.74 135.648-211.11c80.985 34.96-94.144-303.887-49.603-476.003 52.063-201.186-61.072-256.64-86.045-417.137-26.32-169.13 0-442.094 0-442.094" />
    </g>
  </svg>
));
