import PrintArea from 'components/PrintArea';
import React, { FC } from 'react';
import { ActionItem, Container, Heading, List, Title } from './styles';
import { Props } from './types';

const ActionItems: FC<Props> = ({ actionListItems, description, ...rest }) => (
  <Container {...rest}>
    <PrintArea dontBreak>
      <Title title="Action items" />
      <Heading text={description} />
    </PrintArea>
    <PrintArea>
      <List>
        {actionListItems.map(actionListItem => (
          <ActionItem
            key={actionListItem.id}
            id={actionListItem.id}
            title={actionListItem.title}
            description={actionListItem.description}
            labels={actionListItem.labels}
          />
        ))}
      </List>
    </PrintArea>
  </Container>
);

export default ActionItems;
