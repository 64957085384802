import styled from 'styled-components';

export const Svg = styled.svg`
  overflow: visible;
`;

export const Polygon = styled.polygon``;

export const HighlightedBar = styled.line``;

export const Line = styled.line``;

export const RangeText = styled.text`
  color: ${({ theme }) => theme.colors.lightGray};
  font-size: 0.35rem;
  font-weight: 500;
`;
