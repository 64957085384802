import Markdown from 'containers/Markdown';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

const commonStyles = css`
  color: ${({ theme }) => theme.colors.darkerGray};
  font-weight: ${({ theme }) => theme.weights.medium};
`;

export const Description = styled(Markdown)`
  h1,
  h2,
  h3,
  h4,
  h5 {
    ${commonStyles};
    font-family: ${({ theme }) => theme.fonts.aeonik};
    font-size: 2.5rem;
    line-height: 1.4;
    margin-bottom: 4rem;
    max-width: 38rem;
    :first-letter {
      text-transform: capitalize;
    }

    @media print {
      font-size: ${({ theme }) => theme.fontSize.printHeader};
    }
  }

  ul {
    * + * {
      margin-top: 1rem;
    }
  }

  li {
    ${commonStyles};
    font-size: 1.5rem;
    line-height: 1.4;
    margin-left: 9.5rem;
    position: relative;
  }

  p {
    ${commonStyles};
    font-size: 1.5rem;
    line-height: 1.4;
  }

  li::before {
    content: '';
    height: 0.125rem;
    background-color: ${({ theme }) => theme.colors.darkerGray};
    width: 2rem;
    position: absolute;
    top: 1rem;
    left: -3rem;
  }
`;
