import styled from 'styled-components';

export const Svg = styled.svg``;

export const Text = styled.text`
  font-size: 0.2rem;
`;

export const Polygon = styled.polygon``;

export const Line = styled.line``;

export const SecondaryLine = styled(Line)`
  opacity: 0.6;
`;
