import styled from 'styled-components';
import { ZIndex } from 'styles/metrics';
import DefaultNavbar from './Navbar';

export const Container = styled.div`
  padding: 2rem;

  @media print {
    padding: 0;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
`;

export const NavContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 5rem;
  z-index: ${ZIndex.NAVBAR};

  @media print {
    padding-top: 3rem;
  }
`;

export const Navbar = styled(DefaultNavbar)`
  margin: 0 auto;
  padding: 0 5rem;
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
`;
