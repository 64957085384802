import PrintArea from 'components/PrintArea';
import React, { FC } from 'react';
import { BlockTitle, Chart, Charts, Container } from './styles';
import { Props } from './types';

const DataBlock: FC<Props> = ({ charts, index, title, ...rest }) => {
  return (
    <PrintArea {...rest}>
      <Container>
        <BlockTitle index={index} title={title} />
        <Charts {...rest}>
          {charts.map(chart => {
            const { id, ...chartRest } = chart;
            return <Chart key={id} id={id} {...chartRest} />;
          })}
        </Charts>
      </Container>
    </PrintArea>
  );
};

export default DataBlock;
