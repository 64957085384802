import InsightsBlocks from 'components/Blocks';
import PentagonGraph from 'components/PentagonGraph';
import PrintArea from 'components/PrintArea';
import React, { FC } from 'react';
import defaultInsightDescription from './constants';
import {
  Container,
  Content,
  Description,
  InsightRow,
  ReportTitle,
  SectionHeader,
  SectionTitle,
  Title,
  GraphContainer,
} from './styles';
import { Props } from './types';

const Insights: FC<Props> = ({ insights, ...rest }) => {
  return (
    <Container {...rest}>
      <PrintArea dontBreak>
        <ReportTitle title="Insights" />
      </PrintArea>
      <PrintArea>
        <Title
          text={`The Biograph Method consists of ${insights.length} dimensions.`}
        />
        <Description>{defaultInsightDescription}</Description>
      </PrintArea>
      <Content>
        {insights.map(
          ({ id, title, description, score, blocks, disabled }, index) => (
            <InsightRow key={id}>
              <PrintArea dontBreak>
                <SectionHeader>
                  <SectionTitle
                    listIndex={`0000${index + 1}/`.slice(-3)}
                    title={title}
                    description={description}
                    disabledSection={disabled}
                  />
                  <GraphContainer>
                    <PentagonGraph value={disabled ? 0 : score} />
                  </GraphContainer>
                </SectionHeader>
              </PrintArea>
              {!disabled && (
                <InsightsBlocks blocks={blocks} blockType="insights" />
              )}
            </InsightRow>
          ),
        )}
      </Content>
    </Container>
  );
};

export default Insights;
