import React, { FC } from 'react';
import ContentsIndex from './ContentsIndex';
import {
  BiographScore,
  BiologicalAge,
  Container,
  Headline,
  Logo,
  PrintArea,
} from './styles';
import { Props } from './types';

const ReportSummary: FC<Props> = ({
  summaryDescription,
  biographScore,
  dashboard,
  ...rest
}) => {
  const isBiologicalAgeAvailable =
    (!!dashboard?.biologicalAge && !!dashboard?.calendarAge) ||
    !!dashboard?.biologicalAgeIndex;
  return (
    <Container {...rest}>
      <PrintArea dontBreak>
        <Logo />
        <Headline description={summaryDescription} />
        <ContentsIndex />
      </PrintArea>
      <PrintArea dontBreak>
        <Logo />
        <BiographScore
          radarOptions={biographScore.options}
          radarSeries={biographScore.series}
          score={biographScore.score}
          maxScore={biographScore.maxScore}
        />
        {isBiologicalAgeAvailable && (
          <BiologicalAge
            age={dashboard.biologicalAge ?? 0}
            calendar={dashboard.calendarAge ?? 0}
            ageIndex={dashboard.biologicalAgeIndex ?? 0}
          />
        )}
      </PrintArea>
    </Container>
  );
};

export default ReportSummary;
