import React, { FC, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import htmlParser from 'react-markdown/plugins/html-parser';
import getUnderlineHeight from 'utils/getUnderlineHeight';
import { Props } from './types';
import useConnect from './connect';

const parseHtml = htmlParser({
  isValidNode: (node: { type: string; name: string }) =>
    node.type === 'tag' && node.name === 'span',
});

const Markdown: FC<Props> = ({ source, ...rest }) => {
  const element = useRef<HTMLDivElement>(null);
  const { allowedColors } = useConnect();

  useEffect(() => {
    const spans = Array.from(
      element.current?.getElementsByTagName('span') ?? [],
    );
    spans.forEach(span => {
      const underlineHeight = getUnderlineHeight(
        window.getComputedStyle(span, null).getPropertyValue('font-size'),
      );
      const { color } = span.style;
      if (color && allowedColors.includes(color)) {
        span.setAttribute(
          'style',
          `box-shadow: inset 0 ${underlineHeight} ${color};`,
        );
      }
    });
  }, [allowedColors, element]);

  return (
    <div ref={element}>
      <ReactMarkdown
        astPlugins={[parseHtml]}
        escapeHtml={false}
        source={source}
        {...rest}
      />
    </div>
  );
};

export default Markdown;
