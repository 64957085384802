import React, { FC } from 'react';
import { Line, Polygon, HighlightedBar, RangeText, Svg } from './styles';
import { Props } from './types';

const TRIANGLE_Y = 1.25;
const TRIANGLE_SIZE = 1.25;

const DataBlockChartBar: FC<Props> = ({
  displayValue,
  highlightColor,
  highlightEnd,
  highlightMax,
  highlightMin,
  highlightStart,
  rangeMax,
  rangeMin,
  valuePosition,
  ...rest
}) => {
  return (
    <Svg viewBox="0 0 100 8" {...rest}>
      <Polygon
        fill="currentColor"
        points={`
        ${valuePosition - TRIANGLE_SIZE}, ${TRIANGLE_Y}
        ${valuePosition + TRIANGLE_SIZE}, ${TRIANGLE_Y}
        ${valuePosition}, ${TRIANGLE_Y + TRIANGLE_SIZE * 1.75}`}
      />
      <Line
        stroke="currentColor"
        strokeWidth="0.015rem"
        x1={0}
        y1={4.5}
        x2={100}
        y2={4.5}
      />
      <HighlightedBar
        highlightColor={highlightColor}
        stroke="currentColor"
        strokeWidth="0.05rem"
        x1={highlightStart}
        y1={4.5}
        x2={highlightEnd}
        y2={4.5}
      />
      <RangeText
        dominantBaseline="middle"
        fill="currentColor"
        textAnchor="middle"
        x={highlightEnd}
        y="7.5"
      >
        {highlightMax}
      </RangeText>
      <RangeText
        dominantBaseline="middle"
        fill="currentColor"
        textAnchor="middle"
        x={highlightStart}
        y="7.5"
      >
        {highlightMin}
      </RangeText>
    </Svg>
  );
};

export default DataBlockChartBar;
