export enum BlockType {
  SimpleData = 'ComponentBlockData',
  Data = 'ComponentBlockSimpleData',
  Image = 'ComponentBlockImage',
  Text = 'ComponentBlockText',
  Treatment = 'ComponentBlockTreatment',
}

export interface ConcernLevel {
  color: string;
  name: string;
}

export type SimpleDataBlockItem = {
  id: string;
  name: string;
  value: number;
  min: number;
  max: number | null;
  label: string | null;
};

export interface SimpleDataBlock {
  id: string;
  title: string;
  items: SimpleDataBlockItem[];
  type: BlockType.SimpleData;
}

export type DataBlockItem = {
  id: string;
  name: string;
  value: number;
  min: number;
  max: number;
  unit?: string;
  concernLevel?: ConcernLevel;
  lowerBound?: number;
  upperBound?: number;
};

export interface DataBlock {
  id: string;
  title: string;
  items: DataBlockItem[];
  type: BlockType.Data;
}

export interface ImageBlock {
  id: string;
  title: string;
  file: string;
  subtitle: string | null;
  type: BlockType.Image;
}

export interface TextBlock {
  id: string;
  title: string;
  description: string;
  type: BlockType.Text;
}

export interface TreatmentBlock {
  id: string;
  description: string;
  type: BlockType.Treatment;
}

export type Block =
  | TextBlock
  | ImageBlock
  | DataBlock
  | TreatmentBlock
  | SimpleDataBlock;

export * from './logic';
