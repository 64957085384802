import styled from 'styled-components';

export const Container = styled.span`
  align-items: center;
  background: ${({ theme }) => theme.colors.lightIvory};
  display: flex;
  padding: 0.25rem 1.5rem;
  justify-content: center;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.5;
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.gray};
  text-transform: capitalize;
`;
