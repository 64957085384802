import React, { FC, useEffect, useRef, useState } from 'react';
import { FormattedText, Row, TextCell, TitleCell, PrintLink } from './styles';
import { Props } from './types';

const RowB: FC<Props> = ({
  therapeutic,
  drug,
  phenotype,
  comments,
  gene,
  genotype,
}) => {
  const element = useRef<HTMLTableDataCellElement>(null);
  const [urlList, setUrlList] = useState<string[]>([]);

  useEffect(() => {
    const urls: string[] = [];
    const anchors = Array.from(
      element.current?.getElementsByTagName('a') ?? [],
    );
    anchors.forEach(
      (anchor: HTMLAnchorElement) => anchor?.href && urls.push(anchor?.href),
    );
    setUrlList(urls);
  }, [element, setUrlList]);

  return (
    <Row>
      <TitleCell>{therapeutic}</TitleCell>
      <TextCell>
        <FormattedText>{drug}</FormattedText>
      </TextCell>
      <TextCell>
        <FormattedText>{phenotype}</FormattedText>
      </TextCell>
      <TextCell ref={element}>
        <FormattedText>{comments}</FormattedText>
        {urlList.length > 0 &&
          urlList?.map(url => <PrintLink key={url}>{url}</PrintLink>)}
      </TextCell>
      <TextCell>
        <FormattedText>{gene}</FormattedText>
      </TextCell>
      <TextCell>
        <FormattedText>{genotype}</FormattedText>
      </TextCell>
    </Row>
  );
};

export default RowB;
