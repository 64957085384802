import Markdown from 'containers/Markdown';
import PrintArea from 'components/PrintArea';
import DefaultReportSection from 'components/ReportSection';
import DefaultTreatmentWaves from 'components/vectors/TreatmentWaves';
import styled from 'styled-components';
import { ZIndex } from 'styles/metrics';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.darkIvory};
`;

export const Content = styled(PrintArea)`
  position: relative;
`;

export const ReportSection = styled(DefaultReportSection)`
  border-top: none;
  padding: 6rem 0;
  position: relative;
  z-index: ${ZIndex.OVER_BACKGROUND};
`;

export const TreatmentInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Description = styled(Markdown)`
  font-size: 1rem;
  line-height: 1.5;

  p:not(:last-child) {
    margin-bottom: 1rem;
  }

  strong,
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 1.125rem;
    line-height: 1.4;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.darkerGray};

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`;

export const Waves = styled(DefaultTreatmentWaves)`
  position: absolute;
  top: 0;
  left: -14rem;
  width: 100%;
  height: 100%;
`;
