import PrintArea from 'components/PrintArea';
import React, { FC } from 'react';
import {
  Container,
  CoverBackground,
  CoverContent,
  CoverLabel,
  CoverLabelFaded,
  CoverText,
  CoverTitle,
  ScanImage,
} from './styles';
import { Props } from './types';

const bodyScan = require('assets/body-scan-example/bodyScan62.png');

const ReportCover: FC<Props> = ({
  name,
  date,
  scanImg,
  resultsLabel,
  ...rest
}) => (
  <Container {...rest}>
    <PrintArea>
      <CoverContent>
        <CoverText>
          <CoverTitle>{name}</CoverTitle>
          <CoverLabel>{resultsLabel}</CoverLabel>
          {date && <CoverLabelFaded>{date}</CoverLabelFaded>}
        </CoverText>
        <ScanImage src={scanImg || bodyScan} />
        <CoverBackground />
      </CoverContent>
    </PrintArea>
  </Container>
);

export default ReportCover;
