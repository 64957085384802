import React, { FC } from 'react';
import {
  ContactData,
  Container,
  Content,
  Logo,
  PrintArea,
  Text,
  TextHighlight,
  Email,
} from './styles';
import { Props } from './types';

const Footer: FC<Props> = ({ title, ...rest }) => (
  <Container withTitle={!!title} {...rest}>
    <PrintArea dontBreak>
      {title && <TextHighlight text={title} />}
      <Content>
        <Logo />
        <ContactData>
          <Text>
            <p>Biograph Clinic</p>
            <p>2850 S. Delaware St. Ste 100</p>
            <p>San Mateo, CA 94403</p>
            <Email>info@biograph.com</Email>
          </Text>
        </ContactData>
      </Content>
    </PrintArea>
  </Container>
);

export default Footer;
