import React, { FC } from 'react';
import percent from 'utils/percent';
import {
  Chart,
  Container,
  Note,
  NotesContainer,
  radarOptions,
  ScoreContainer,
  ScoreNumber,
  ScoreSection,
  ScoreText,
  ScoreTitle,
} from './styles';
import { Props } from './types';

const RadarChart: FC<Props> = ({
  options,
  series,
  score,
  maxScore,
  ...rest
}) => {
  const biographChartOptions = {
    ...radarOptions,
    ...options,
  };
  const percentScore =
    score && maxScore ? Math.round(percent(score, maxScore)) : 0;

  return (
    <Container {...rest} id="chart">
      <Chart
        options={biographChartOptions}
        type={radarOptions?.chart?.type}
        series={series}
      />
      {score && maxScore && (
        <ScoreContainer>
          <ScoreNumber>{percentScore}</ScoreNumber>
          <ScoreSection>
            <ScoreTitle>Total score</ScoreTitle>
            <ScoreText>/100</ScoreText>
          </ScoreSection>
        </ScoreContainer>
      )}
      <NotesContainer>
        <Note>
          Note: The higher the score the better you fare in each area.
        </Note>
      </NotesContainer>
    </Container>
  );
};

export default RadarChart;
