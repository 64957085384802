import React, { FC } from 'react';
import { Props } from './types';
import { Container, Bullet, Text } from './styles';

const Behavior: FC<Props> = ({ name, ...rest }) => (
  <Container {...rest}>
    <Bullet />
    <Text>{name}</Text>
  </Container>
);

export default Behavior;
