import { useQuery } from '@apollo/react-hooks';
import {
  GetPatient,
  GetPatientVariables,
  GetPatient_insights,
  GetPatient_keyFindings,
  GetPatient_patient_action_items,
  GetPatient_patient_documents,
  GetPatient_patient_longevity_behaviors,
} from 'graphql/types/GetPatient';
import { formatComprehensiveResultItems, Result } from 'model/Results';
import { Block, formatBlock, formatRisk } from 'model/Block';
import GET_PATIENT from './GetPatient';

const usePatient = (patientId: string) => {
  const { data, loading, ...rest } = useQuery<GetPatient, GetPatientVariables>(
    GET_PATIENT,
    {
      variables: {
        patientId,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  let coverImage =
    (data?.patient?.cover_image?.length ?? 0) > 0
      ? data?.patient?.cover_image?.[0]?.url
      : undefined;

  if (coverImage) {
    coverImage = `${process.env.REACT_APP_API_URI}${coverImage}`;
  }

  const actionList = (
    data?.patient?.action_items || ([] as GetPatient_patient_action_items[])
  )
    .map(x => x!)
    .map(({ id, description, labels, title }) => ({
      id,
      description,
      labels:
        labels
          ?.map(x => x!)
          .map(({ value }) => ({
            value,
          })) ?? [],
      title,
    }));

  const longevityBehaviors = (
    data?.patient?.longevity_behaviors ||
    ([] as GetPatient_patient_longevity_behaviors[])
  )
    .map(x => x!)
    .map(({ id, name }) => ({
      id,
      name: name ?? undefined,
    }));

  const documents = (
    data?.patient?.documents || ([] as GetPatient_patient_documents[])
  )
    .map(x => x!)
    .map(({ id, title, description, file }) => ({
      id,
      title,
      description: description ?? undefined,
      file: (process.env.REACT_APP_API_URI ?? '') + (file?.url ?? ''),
    }));

  const keyFindings = (data?.keyFindings || ([] as GetPatient_keyFindings[]))
    .map(x => x!)
    .sort((a, b) => (a.order ?? Infinity) - (b.order ?? Infinity))
    .map(({ id, title, description, risk, blocks }) => ({
      id,
      title,
      description,
      risk: formatRisk(risk),
      blocks: (blocks?.map(x => x!).map(block => formatBlock(block)) ||
        []) as Block[],
    }));

  const insights = (data?.insights || ([] as GetPatient_insights[]))
    .map(x => x!)
    .sort((a, b) => {
      if (a.disabled === b.disabled) {
        return a.order - b.order;
      }
      return a.disabled ? 1 : -1;
    })
    .map(({ id, title, description, score, disabled, blocks }) => ({
      id,
      title,
      description,
      score,
      disabled,
      blocks: (blocks?.map(x => x!).map(block => formatBlock(block)) ||
        []) as Block[],
    }));

  const scoreInsights = insights.filter(insight => !insight.disabled);

  const totalScore =
    scoreInsights
      .map(insight => insight.score)
      .reduce(
        (acumScore = 0, insightScore) => (insightScore ?? 0) + acumScore,
        0,
      ) ?? 0;

  const maxScore = (scoreInsights.length ?? 0) * 5 ?? 0;

  const summaryDescription = data?.patient?.summary?.description ?? undefined;

  const dashboard = {
    biologicalAge: data?.patient?.dashboard?.biological_age ?? undefined,
    calendarAge: data?.patient?.dashboard?.calendar_age ?? undefined,
    biologicalAgeIndex:
      data?.patient?.dashboard?.biological_age_index ?? undefined,
  };

  const comprehensiveResultsBlocks =
    data?.patient?.comprehensive_result?.blocks
      ?.map(x => x!)
      .map(block => formatComprehensiveResultItems(block)) ||
    null ||
    ([] as Result[]);

  const resultsCoverLabel =
    data?.patient?.results_type === 'Final'
      ? 'Your results'
      : 'Your preliminary results';

  return {
    id: data?.patient?.id,
    firstName: data?.patient?.first_name,
    lastName: data?.patient?.last_name,
    date: data?.patient?.date,
    coverImage,
    summaryDescription,
    dashboard,
    actionItems: actionList,
    longevityBehaviors,
    documents,
    patient: data?.patient,
    keyFindings,
    insights,
    totalScore,
    maxScore,
    comprehensiveResultsBlocks,
    loading: data?.patient?.id ? false : loading,
    resultsCoverLabel,
    ...rest,
  };
};

export default usePatient;
