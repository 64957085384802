import useGlobals from 'queries/globals';
import usePatient from 'queries/patients';
import { useParams } from 'react-router';

const useConnect = () => {
  const { id = '1' } = useParams();
  const { headers } = useGlobals();
  const {
    firstName,
    lastName,
    date,
    dashboard,
    summaryDescription,
    keyFindings,
    actionItems,
    longevityBehaviors,
    documents,
    loading,
    insights,
    totalScore,
    maxScore,
    coverImage,
    resultsCoverLabel,
  } = usePatient(id);

  const scoreInsights = insights.filter(({ disabled }) => !disabled);

  // BIOGRAPH SCORE
  const biographScore = {
    options: {
      xaxis: {
        categories: scoreInsights.map(({ title }) => title),
      },
    },
    series: [
      {
        data: scoreInsights.map(({ score }) => score),
      },
    ],
    score: totalScore ?? 0,
    maxScore,
  };

  const nav = [
    { name: 'Intro', id: 'intro' },
    { name: 'Biograph score', id: 'score' },
    { name: 'Key findings', id: 'findings' },
    { name: 'Insights', id: 'insights' },
  ];

  if (actionItems.length > 0) {
    nav.push({ name: 'Action Items', id: 'actions' });
  }

  if (longevityBehaviors.length > 0) {
    nav.push({
      name: 'Longevity behaviors',
      id: 'longevity',
    });
  }

  if (documents.length > 0) {
    nav.push({ name: 'Documents', id: 'documents' });
  }

  return {
    firstName,
    lastName,
    date,
    dashboard,
    summaryDescription,
    actionItems,
    longevityBehaviors,
    documents,
    biographScore,
    keyFindings,
    insights,
    loading,
    nav,
    coverImage,
    resultsCoverLabel,
    headers,
  };
};

export default useConnect;
