import styled, { css } from 'styled-components';
import Markdown from 'containers/Markdown';
import { Props } from './types';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const Content = styled.div``;

const disabledOpacityElements = css`
  opacity: 0.3;
`;

export const ListIndex = styled.h3<{
  disabledSection?: Props['disabledSection'];
}>`
  color: ${({ theme }) => theme.colors.lightGray};
  font-weight: ${({ theme }) => theme.weights.medium};
  font-size: 1.5rem;
  line-height: 140%;
  display: block;

  ${({ disabledSection }) =>
    disabledSection &&
    css`
      ${disabledOpacityElements};
    `};
`;

export const Title = styled.h3<{
  disabledSection?: Props['disabledSection'];
}>`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.aeonik};
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 1.4;
  margin-bottom: 1rem;
  display: block;

  :first-letter {
    text-transform: capitalize;
  }

  ${({ disabledSection }) =>
    disabledSection &&
    css`
      ${disabledOpacityElements};
    `};
`;

export const Text = styled(Markdown)<{
  disabledSection?: Props['disabledSection'];
}>`
  * + * {
    margin-top: 0.5rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    ${({ disabledSection }) =>
      disabledSection &&
      css`
        color: rgba(0, 0, 0, 0.3);
      `};
  }
  span,
  p {
    color: ${({ theme }) => theme.colors.black};
    font-size: 1rem;
    line-height: 1.5;
    max-width: 24rem;

    ${({ disabledSection }) =>
      disabledSection &&
      css`
        color: rgba(0, 0, 0, 0.3);
      `};
  }
  strong {
    font-weight: ${({ theme }) => theme.weights.bold};
  }

  em {
    font-style: italic;
  }
  a {
    ${({ theme }) => theme.anchorElement};
  }
  li {
    font-size: 1rem;
    line-height: 1.5;
    margin-left: 3rem;
    position: relative;
    ${({ disabledSection }) =>
      disabledSection &&
      css`
        color: rgba(0, 0, 0, 0.3);
      `};
  }
  li::before {
    content: '';
    height: 0.125rem;
    background-color: ${({ theme }) => theme.colors.black};
    width: 2rem;
    position: absolute;
    top: 0.5rem;
    left: -3rem;
    ${({ disabledSection }) =>
      disabledSection &&
      css`
        background-color: rgba(0, 0, 0, 0.3);
      `};
  }
`;

export const CommentsContainer = styled.div``;

export const PrintLink = styled.span`
  font-size: 0.75rem;
  display: none;
  line-height: 1.5;
  padding-top: 0.5rem;

  @media print {
    display: block;
  }

  :last-child {
    padding-bottom: 0.75rem;
  }
`;
