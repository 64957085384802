import styled, { css } from 'styled-components';
import DefaultInitialLetterLogo from 'components/vectors/InitialLetterLogo';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 2.5rem;
  padding-bottom: 6rem;
`;

export const HeadingContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InitialLetterLogo = styled(DefaultInitialLetterLogo)`
  height: 3rem;
`;

const titleDefault = css`
  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.125rem;
`;

export const HeadingTitle = styled.h1`
  ${titleDefault};
  margin-bottom: 0.5rem;
  text-transform: capitalize;
`;

export const HeadingSubtitle = styled.h2`
  ${titleDefault};
  opacity: 0.6;
  :first-letter {
    text-transform: capitalize;
  }
`;
export const Logo = styled.img`
  height: 3rem;
`;
