import React, { FC } from 'react';
import { Container, Description } from './styles';
import { Props } from './types';

const Headline: FC<Props> = ({ description, ...rest }) => (
  <Container {...rest}>
    <Description>{description}</Description>
  </Container>
);

export default Headline;
