import styled from 'styled-components';
import DefaultReportTitle from 'components/ReportTitle';
import DefaultTextHighlight from 'components/TextHighlight';
import DefaultActionItem from './ActionItem';

export const Container = styled.div`
  padding: 10rem 0 0;
  background: ${({ theme }) => theme.colors.ivory};

  @media print {
    padding: 3rem 0 7rem;
    page-break-before: always;
  }
`;

export const Title = styled(DefaultReportTitle)`
  margin-bottom: 6rem;
`;

export const ActionItem = styled(DefaultActionItem)``;

export const Heading = styled(DefaultTextHighlight)`
  max-width: 40rem;
  margin-bottom: -1.5rem;

  @media print {
    break-after: avoid;
  }
`;

export const List = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: block;
  margin: 0 -2.5rem;
  padding: 5.4375rem 9.375rem 10rem 9rem;

  > li:not(:last-child) {
    margin-bottom: 3.5rem;
  }
`;
