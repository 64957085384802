import styled, { css } from 'styled-components';

export const Svg = styled.svg`
  overflow: visible;
`;

export const Polygon = styled.polygon``;

export const HighlightedBar = styled.line<{ highlightColor?: string }>`
  ${({ highlightColor }) =>
    highlightColor
      ? css`
          color: ${highlightColor};
        `
      : css`
          color: ${({ theme }) => theme.colors.radarGreen};
        `}
`;

export const Line = styled.line``;

export const RangeText = styled.text`
  color: ${({ theme }) => theme.colors.lightGray};
  font-size: 0.2rem;
  font-weight: 500;
`;
