import React, { FC } from 'react';
import { BlockType } from 'model/Block';
import getStringIndex from 'utils/getStringIndex';
import {
  Container,
  DataBlock,
  ImageBlock,
  SimpleDataBlock,
  TextBlock,
  Treatment,
} from './styles';
import { Props } from './types';

const Blocks: FC<Props> = ({ blocks, blockType = 'findings', ...rest }) => (
  <Container {...rest}>
    {blocks &&
      blocks.map((block, index) => {
        switch (block.type) {
          case BlockType.Data: {
            const { id, items, title: dataTitle, type } = block;
            return (
              <DataBlock
                key={type + id}
                charts={items}
                index={getStringIndex(index)}
                title={dataTitle}
              />
            );
          }
          case BlockType.Image: {
            const { id, file, subtitle, title: imgTitle, type } = block;
            return (
              <ImageBlock
                key={type + id}
                description={subtitle || 'Hola'}
                index={blockType === 'findings' ? getStringIndex(index) : ''}
                image={file}
                title={imgTitle}
              />
            );
          }
          case BlockType.Text: {
            const {
              id,
              description: textDescription,
              title: textTitle,
              type,
            } = block;

            return (
              <TextBlock
                key={type + id}
                index={blockType === 'findings' ? getStringIndex(index) : ''}
                title={textTitle}
                description={textDescription}
                textBlockType={blockType}
              />
            );
          }
          case BlockType.Treatment: {
            const { id, description: treatmentDescription, type } = block;
            return (
              <Treatment
                key={type + id}
                description={treatmentDescription}
                subtitle={getStringIndex(index)}
              />
            );
          }
          case BlockType.SimpleData: {
            const { id, title, items } = block;
            return (
              <SimpleDataBlock
                key={id}
                index={id}
                title={title}
                charts={items}
              />
            );
          }
          default:
            return null;
        }
      })}
  </Container>
);

export default Blocks;
