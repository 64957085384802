import styled, { css } from 'styled-components';
import DefaultChartBar from './ChartBar';

export const Container = styled.div`
  @media print {
    padding-bottom: 1rem;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 40% auto;
  grid-gap: 4.75rem;
  margin-top: 2rem;
  width: 100%;
`;

const containers = css`
  display: flex;
  flex-direction: column;

  @media print {
    break-inside: avoid;
  }
`;
export const BiologicalAgeContainer = styled.div`
  ${containers};
`;

export const BiologicalAgeIndexContainer = styled.div`
  ${containers};
`;

export const DescriptionText = styled.p`
  color: ${({ theme }) => theme.colors.darkerGray};
  font-size: 0.75rem;
  line-height: 1.5;
`;

export const Graph = styled.div``;

export const Bar = styled(DefaultChartBar)``;

export const GraphContent = styled.div`
  > * + * {
    margin-top: 2rem;
  }
`;

export const Info = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 0.25rem;
`;

export const Value = styled.h1`
  color: ${({ theme }) => theme.colors.darkerGray};
  font-family: ${({ theme }) => theme.fonts.aeonik};
  font-weight: bold;
  font-size: 3rem;
  line-height: 100%;
  margin-right: 1rem;
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 140%;
`;

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.lightGray};
  font-weight: ${({ theme }) => theme.weights.medium};
  font-size: 0.875rem;
  line-height: 100%;
`;

export const Description = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;
