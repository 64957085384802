import styled from 'styled-components';
import DefaultTextHighlight from 'components/TextHighlight';
import DefaultReportTitle from 'components/ReportTitle';
import PrintArea from 'components/PrintArea';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.ivory};
  padding: 10rem 0;

  @media print {
    page-break-before: always;
    padding: 3rem 0 6rem;
    min-height: 100vh;
  }
`;

export const ListPrintArea = styled(PrintArea)`
  @media print {
    break-before: avoid;
  }
`;

export const List = styled.ul`
  margin-top: 4rem;
`;

export const Title = styled(DefaultReportTitle)`
  margin-bottom: 6rem;
`;

export const Description = styled(DefaultTextHighlight)`
  max-width: 45rem;
`;
