import DefaultReportTitle from 'components/ReportTitle';
import DefaultSectionTitle from 'components/SectionTitle';
import DefaultTextHighlight from 'components/TextHighlight';
import styled from 'styled-components';

export const Container = styled.section`
  padding: 10rem 0;

  @media print {
    page-break-before: always;
    padding: 3rem 0;
  }
`;

export const ReportTitle = styled(DefaultReportTitle)`
  margin-bottom: 6rem;
`;

export const Title = styled(DefaultTextHighlight)`
  margin-bottom: 2rem;
  max-width: 35rem;
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.gray};
  font-weight: ${({ theme }) => theme.weights.medium};
  font-size: 1.5rem;
  line-height: 1.4;
  margin: 0 0 6.1875rem 6.5rem;
`;

export const InsightRow = styled.div``;

export const SectionHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 3.5625rem;
`;

export const Content = styled.div`
  > * + * {
    margin-top: 6.375rem;
  }
`;

export const SectionTitle = styled(DefaultSectionTitle)`
  @media print {
    display: block;
  }
`;

export const GraphContainer = styled.div``;
