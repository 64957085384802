import { css } from 'styled-components';

export default {
  weights: {
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
  },
  fonts: {
    apercu: 'Apercu',
    aeonik: 'Aeonik',
  },
  colors: {
    black: '#000000',
    darkerGray: '#16120F',
    darkGray: '#45413F',
    mediumGray: '#4D4D4D',
    gray: '#73716F',
    lightGray: '#A2A09F',
    darkIvory: '#CDC3B0',
    mediumIvory: '#D7CFC0',
    ivory: '#ECE9E6',
    lightIvory: '#F2EEEC',
    white: '#FFFFFF',
    radarGreen: '#7EB85B',
  },
  fontSize: {
    printHeader: '2rem',
  },
  anchorElement: css`
    background-color: ${({ theme }) => theme.colors.ivory};
    color: ${({ theme }) => theme.colors.darkGray};
    display: inline-block;
    font-size: 0.75rem;
    line-height: 150%;
    padding: 0.25rem 1.75rem 0.25rem 0.5rem;
    position: relative;
    text-decoration: none;

    :after {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 13'%3E
        \\t%3Cpath d='M6.667.167h-.5v1h.5v-1zm6 .5h.5v-.5h-.5v.5zm-.5 6v.5h1v-.5h-1zm-5.5-5.5h6v-1h-6v1zm5.5-.5v6h1v-6h-1z' fill='%2373716F'/%3E
        \\t%3Cpath d='M1.313 11.313l-.354.353.708.708.353-.354-.707-.707zM13.02 1.02a.5.5 0 00-.707-.707l.707.707zm-11 11l11-11-.707-.707-11 11 .707.707z' fill='%2373716F'/%3E
        %3C/svg%3E")
        center right no-repeat;
      background-size: 0.8125rem;
      content: '';
      right: 0.5rem;
      position: absolute;
      top: 50%;
      width: 0.8125rem;
      box-sizing: inherit;
      height: 0.8125rem;
      transform: scale(0.85) translateY(-60%);
    }
  `,
};
