import styled from 'styled-components';

export const Container = styled.div`
  width: 11rem;
`;

export const Content = styled.div``;

export const Svg = styled.svg`
  overflow: visible;
`;

export const Polygon = styled.polygon``;

export const HighlightedBar = styled.line``;

export const Line = styled.line``;

export const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -0.5rem;
`;

export const Label = styled.div`
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  line-height: 1.25;
  color: ${({ theme }) => theme.colors.lightGray};
`;
