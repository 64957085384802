import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg width={369} height={354} viewBox="0 0 369 354" fill="none" {...props}>
    <g opacity=".1" stroke="#16120F" strokeWidth=".6" strokeMiterlimit="10">
      <path
        opacity=".1"
        d="M57.293 521.958s-.413-4.855-1.005-11.927c-1.552-18.588-4.33-48.193-5.832-65.127-3.824-43.095 42.768-72.712 39.721-107.042-4.63-52.182-28.842-75.167-40.23-118.792-11.39-43.624 11.805-114.544-2.954-174.13-6.31-25.464-24.908-55.446-33.467-68.37a32.906 32.906 0 01-5.308-14.568L5.254-64.531"
      />
      <path
        opacity=".2"
        d="M67.553 520.921s-.236-2.654-.419-4.821c-.859-9.783-5.138-13.349-5.74-20.037-1.658-18.604-3.878-47.422-4.972-63.832-2.77-41.667 42.847-69.956 40.68-103.043-3.288-50.304-26.11-72.679-36.57-115.845-10.587-43.722 10.063-111.608-4.591-170.908-2.143-8.668-5.68-17.876-9.728-26.768-7.652-16.77-17.174-32.285-22.572-40.714-2.81-4.371-4.617-9.268-5.13-14.365-.033-.363-2.908-26.137-2.908-26.137"
      />
      <path
        opacity=".3"
        d="M77.783 519.888s-.4-4.514-.65-7.442c-1.54-17.48-9.777-21.187-10.623-30.338-1.711-18.623-3.422-46.644-4.11-62.529-1.731-40.239 42.913-67.192 41.626-99.046-1.956-48.416-23.385-70.192-32.938-112.897-9.8-43.827 8.331-108.672-6.209-167.694-2.097-8.53-5.53-17.596-9.43-26.39-7.365-16.525-16.497-31.81-21.713-40.21-2.699-4.34-4.45-9.165-4.946-14.163-.073-.736-2.84-25.735-2.84-25.735"
      />
      <path
        opacity=".4"
        d="M87.986 518.842s-.565-6.383-.882-10.063c-2.22-25.178-14.378-29.018-15.467-40.638-1.746-18.638-2.977-45.857-3.264-61.235-.71-38.793 42.979-64.411 42.563-95.048-.635-46.52-20.675-67.713-29.327-109.943-9.004-43.94 6.614-105.743-7.82-164.479-2.06-8.381-5.373-17.315-9.127-26.003-7.08-16.28-15.831-31.336-20.867-39.708-2.58-4.298-4.275-9.053-4.762-13.968-.106-1.1-2.776-25.34-2.776-25.34"
      />
      <path
        opacity=".5"
        d="M98.156 517.81s-.73-8.244-1.114-12.693c-2.899-32.877-18.934-36.857-20.276-50.947-1.772-18.66-2.535-45.069-2.416-59.933.304-37.338 43.042-61.63 43.482-91.04.669-44.609-17.97-65.246-25.754-106.997-8.216-44.063 4.914-102.807-9.414-161.263-2.024-8.243-5.225-17.027-8.826-25.618-6.796-16.035-15.18-30.86-20.02-39.206-2.47-4.264-4.1-8.94-4.58-13.765-.146-1.462-2.717-24.936-2.717-24.936"
      />
      <path
        opacity=".6"
        d="M108.275 516.765s-.894-10.112-1.345-15.314c-3.578-40.583-23.452-44.695-25.036-61.247-1.797-18.674-2.105-44.274-1.576-58.639 1.301-35.867 43.093-58.832 44.397-87.043 1.962-42.68-15.278-62.779-22.203-104.051-7.416-44.187 3.229-99.878-10.992-158.04-1.978-8.095-5.052-16.756-8.535-25.24-6.481-15.8-14.525-30.385-19.178-38.703-2.362-4.222-3.937-8.836-4.399-13.57-.178-1.826-2.652-24.534-2.652-24.534"
      />
      <path
        opacity=".7"
        d="M118.374 515.729s-1.057-11.973-1.575-17.935c-4.255-48.281-27.925-52.534-29.767-71.546-1.815-18.698-1.67-43.471-.751-57.337 2.283-34.387 43.146-56.018 45.296-83.045 3.236-40.742-12.601-60.321-18.691-101.105-6.617-44.321 1.562-96.941-12.554-154.825-1.943-7.956-4.899-16.475-8.238-24.853-6.195-15.557-13.884-29.91-18.343-38.201-2.256-4.19-3.767-8.725-4.22-13.368-.211-2.198-2.596-24.138-2.596-24.138"
      />
      <path
        opacity=".8"
        d="M128.435 514.685s-1.222-13.842-1.807-20.565c-4.932-55.979-32.366-60.363-34.46-81.852-1.823-18.722-1.246-42.658.075-56.036 3.248-32.897 43.189-53.186 46.192-79.047 4.494-38.779-9.93-57.865-15.2-98.16-5.826-44.454-.09-94.013-14.101-151.608-1.898-7.819-4.753-16.195-7.951-24.468-5.918-15.311-13.238-29.427-17.515-37.698-2.148-4.148-3.604-8.613-4.049-13.165-.252-2.56-2.532-23.735-2.532-23.735"
      />
      <path
        opacity=".9"
        d="M138.464 513.644s-1.385-15.702-2.036-23.186c-5.609-63.677-36.763-68.2-39.115-92.151-1.84-18.737-.834-41.845.884-54.741 4.181-31.39 43.231-50.347 47.072-75.05 5.724-36.798-7.267-55.41-11.749-95.206-5.025-44.598-1.724-91.076-15.631-148.393-1.862-7.67-4.601-15.915-7.658-24.09-5.635-15.067-12.607-28.952-16.702-37.195-2.043-4.114-3.436-8.5-3.873-12.97-.284-2.925-2.477-23.34-2.477-23.34"
      />
      <path d="M148.445 512.612s-1.549-17.571-2.266-25.807c-6.285-71.384-41.12-76.038-43.729-102.448-1.857-18.76-.425-41.015 1.695-53.44 5.096-29.873 43.254-47.481 47.947-71.054 6.928-34.799-4.618-52.964-8.321-92.261-4.216-44.742-3.342-88.146-17.143-145.168-1.818-7.532-4.45-15.634-7.368-23.704-5.363-14.83-11.971-28.469-15.886-36.693-1.937-4.072-3.269-8.397-3.697-12.767-.316-3.297-2.412-22.937-2.412-22.937" />
      <path d="M158.406 511.562s-1.712-19.431-2.497-28.437c-6.961-79.081-45.431-83.874-48.305-112.752-1.866-18.776-.029-40.185 2.488-52.146 5.973-28.33 43.261-44.606 48.815-67.058 8.097-32.773-1.987-50.52-4.933-89.316-3.408-44.897-4.943-85.208-18.64-141.952-1.783-7.392-4.309-15.353-7.089-23.326-5.085-14.585-11.349-27.993-15.078-36.19-1.832-4.03-3.111-8.285-3.523-12.573-.356-3.66-2.349-22.542-2.349-22.542" />
      <path d="M168.328 510.523s-1.877-21.3-2.728-31.057c-7.637-86.78-49.708-91.701-52.839-123.048-1.883-18.8.357-39.346 3.284-50.845 6.815-26.783 43.256-41.706 49.659-63.061 9.222-30.737.636-48.087-1.577-86.373-2.599-45.043-6.525-82.27-20.111-138.734-1.739-7.246-4.159-15.065-6.801-22.94-4.818-14.341-10.733-27.51-14.276-35.689-1.73-3.997-2.947-8.173-3.35-12.37-.388-4.024-2.295-22.138-2.295-22.138" />
      <path d="M178.125 509.479s-2.039-23.16-2.957-33.678c-8.313-94.486-53.864-99.535-57.259-133.341-1.891-18.823.717-38.489 4.049-49.552 7.609-25.226 43.142-38.787 50.411-59.067 10.277-28.672 3.232-45.645 1.741-83.429-1.786-45.2-8.089-79.34-21.557-135.517-1.702-7.106-4.006-14.785-6.517-22.553-4.546-14.096-10.109-27.034-13.464-35.186-1.625-3.956-2.789-8.069-3.175-12.168-.419-4.387-2.231-21.735-2.231-21.735" />
      <path d="M187.889 508.434s-2.203-25.029-3.188-36.308c-8.989-102.183-57.959-107.366-61.625-143.64-1.899-18.839 1.062-37.639 4.811-48.251 8.352-23.659 43.002-35.858 51.14-55.073 11.276-26.604 5.81-43.215 5.007-80.478-.977-45.357-9.628-76.4-22.979-132.29-1.658-6.968-3.863-14.504-6.229-22.175-4.27-13.851-9.491-26.55-12.661-34.684-1.529-3.912-2.623-7.956-3.01-11.972-.452-4.76-2.177-21.34-2.177-21.34" />
      <path d="M197.634 507.385l-3.428-38.928c-9.675-109.88-62.045-115.198-65.974-153.931-1.916-18.862 1.391-36.771 5.561-46.949 9.052-22.079 42.858-32.919 51.902-51.08 12.206-24.523 8.369-40.778 8.246-77.538-.161-45.515-11.147-73.467-24.387-129.069-1.622-6.82-3.716-14.224-5.947-21.79-4.007-13.606-8.885-26.067-11.881-34.18-1.428-3.872-2.47-7.844-2.84-11.77-.484-5.123-2.115-20.936-2.115-20.936" />
      <path d="M207.382 506.338l-3.659-41.549c-10.354-117.577-66.111-123.028-70.312-164.219-1.925-18.878 1.703-35.911 6.316-45.658 9.701-20.511 42.702-29.974 52.678-47.085 13.086-22.445 10.924-38.351 11.45-74.596.663-45.677-12.648-70.528-25.784-125.85-1.58-6.682-3.579-13.943-5.678-21.412-3.748-13.352-8.298-25.582-11.107-33.677-1.329-3.839-2.311-7.733-2.674-11.576-.516-5.487-2.063-20.54-2.063-20.54" />
      <path d="M217.115 505.285l-3.891-44.178c-11.036-125.283-70.166-130.85-74.639-174.508-1.934-18.902 1.997-35.049 7.068-44.357 10.309-18.94 42.537-27.036 53.483-43.092 13.897-20.378 13.466-35.917 14.636-71.657 1.494-45.838-14.135-67.594-27.171-122.628-1.546-6.543-3.444-13.654-5.405-21.026-3.493-13.108-7.71-25.1-10.345-33.176-1.238-3.796-2.155-7.63-2.509-11.373-.548-5.86-2.002-20.137-2.002-20.137" />
      <path d="M226.834 504.246l-4.123-46.799c-11.718-132.979-74.213-138.678-78.959-184.803-1.951-18.925 2.266-34.192 7.809-43.064 10.874-17.398 42.336-24.117 54.307-39.099 14.65-18.347 16.008-33.484 17.793-68.716 2.337-45.994-15.606-64.653-28.547-119.4-1.513-6.395-3.303-13.373-5.136-20.64-3.25-12.862-7.14-24.623-9.593-32.674-1.142-3.754-2.009-7.517-2.347-11.17-.58-6.223-1.949-19.733-1.949-19.733" />
      <path d="M236.565 503.187l-4.356-49.419c-12.402-140.675-78.246-146.507-83.271-195.089-1.96-18.94 2.517-33.351 8.557-41.764 11.39-15.868 42.121-21.227 55.139-35.096 15.343-16.352 18.545-31.045 20.941-65.769 3.181-46.15-17.066-61.711-29.916-116.178-1.472-6.257-3.173-13.093-4.879-20.262-3.001-12.617-6.576-24.14-8.851-32.173-1.053-3.711-1.855-7.405-2.194-10.974-.613-6.587-1.891-19.34-1.891-19.34" />
      <path d="M246.28 502.143l-4.591-52.048c-13.086-148.371-82.271-154.334-87.577-205.373-1.97-18.965 2.744-32.525 9.295-40.472 11.863-14.377 41.879-18.383 55.994-31.103 15.992-14.411 21.075-28.615 24.064-62.83 4.045-46.3-18.515-58.777-31.278-112.956-1.441-6.118-3.044-12.812-4.616-19.876-2.756-12.373-6.018-23.656-8.124-31.67-.967-3.67-1.713-7.293-2.036-10.772a4165.778 4165.778 0 00-1.831-18.936" />
      <path d="M256.005 501.089l-4.825-54.669c-13.773-156.075-86.292-162.153-91.88-215.666-1.98-18.988 2.952-31.721 10.041-39.171 12.308-12.932 41.623-15.611 56.853-27.109 16.621-12.544 23.613-26.17 27.182-59.892 4.92-46.452-19.956-55.835-32.627-109.734-1.4-5.971-2.916-12.532-4.363-19.49-2.52-12.127-5.466-23.172-7.397-31.17-.873-3.635-1.563-7.189-1.879-10.577-.678-7.323-1.78-18.54-1.78-18.54" />
      <path d="M265.71 500.028l-5.058-57.288c-14.462-163.771-90.311-169.98-96.171-225.95-1.99-19.004 3.142-30.958 10.777-37.879 12.735-11.536 41.367-12.913 57.733-23.115 17.235-10.751 26.14-23.727 30.278-56.955 5.8-46.597-21.387-52.9-33.975-106.504-1.369-5.831-2.789-12.242-4.106-19.113-2.287-11.88-4.917-22.688-6.676-30.667-.788-3.594-1.415-7.078-1.722-10.375-.703-7.687-1.721-18.137-1.721-18.137" />
      <path d="M275.425 498.988l-5.294-59.917c-15.15-171.466-94.317-177.805-100.466-236.232-2.008-19.027 3.321-30.227 11.52-36.579 13.152-10.189 41.128-10.29 58.617-19.121 17.87-9.018 28.674-21.285 33.365-54.017 6.7-46.728-22.811-49.958-35.32-103.282a339.87 339.87 0 01-3.851-18.727c-2.054-11.628-4.381-22.204-5.96-30.166-.703-3.552-1.276-6.966-1.567-10.173-.735-8.05-1.67-17.733-1.67-17.733" />
      <path d="M285.132 497.925l-5.529-62.537c-15.84-179.16-98.227-185.639-104.76-246.521-1.986-19.053 3.497-29.537 12.255-35.278 13.58-8.901 40.909-7.743 59.517-15.128 18.539-7.362 31.21-18.828 36.451-51.073 7.614-46.86-24.228-47.022-36.66-100.058a260.958 260.958 0 01-3.605-18.35c-1.824-11.381-3.848-21.719-5.249-29.665-.62-3.51-1.13-6.862-1.414-9.978a5839.034 5839.034 0 00-1.612-17.338" />
      <path d="M294.84 496.88l-5.764-65.157c-16.532-186.862-102.245-193.462-109.051-256.801-2.003-19.068 3.678-28.889 12.997-33.987 14.035-7.674 40.742-5.257 60.426-11.135 19.253-5.744 33.747-16.371 39.52-48.136 8.541-46.977-25.638-44.079-37.995-96.835a209.042 209.042 0 01-3.354-17.964c-1.594-11.137-3.318-21.235-4.542-29.164-.536-3.467-.993-6.75-1.268-9.776-.793-8.787-1.562-16.934-1.562-16.934" />
      <path d="M304.543 495.822l-6.001-67.785c-17.224-194.557-106.268-201.283-113.34-267.081-2.021-19.091 3.872-28.284 13.739-32.688 14.519-6.49 40.621-2.817 61.336-7.14 20.019-4.174 36.286-13.908 42.59-45.2 9.481-47.088-27.045-41.144-39.327-93.605-1.231-5.26-2.293-11.11-3.11-17.578-1.367-10.89-2.792-20.751-3.848-28.662-.453-3.425-.848-6.64-1.115-9.573-.811-9.152-1.504-16.54-1.504-16.54" />
      <path d="M314.243 494.774l-6.238-70.404c-17.917-202.25-110.282-209.105-117.628-277.368-2.039-19.106 4.086-27.706 14.471-31.395 15.048-5.346 40.554-.406 62.263-3.147 20.845-2.626 38.828-11.438 45.644-42.265 10.443-47.192-28.452-38.2-40.654-90.38-1.194-5.122-2.188-10.83-2.862-17.202-1.156-10.644-2.269-20.276-3.149-28.162-.379-3.39-.704-6.527-.964-9.379-.844-9.524-1.453-16.135-1.453-16.135" />
      <path d="M323.924 493.719l-6.473-73.024C298.839 210.752 203.149 203.779 195.535 133.05c-7.851-72.891 106.804 15.5 127.102-68.572 12.51-51.814-38.249-32.404-44.593-103.972l-4.661-52.579" />
    </g>
  </svg>
));
