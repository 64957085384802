import React, { FC, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import lottieAnimation from 'assets/animations/chart-animation.json';
import lottie from 'lottie-web';
import { Container } from './styles';

const animationName = 'chart-animation';

const AnimatedChart: FC = props => {
  const [played, setPlayed] = useState();
  const [ref, inView] = useInView({
    threshold: 0.9,
    triggerOnce: true,
  });

  useEffect(() => {
    const element = document.getElementById('lottie-anim');
    lottie.loadAnimation({
      container: element!,
      renderer: 'svg',
      animationData: lottieAnimation,
      name: animationName,
      autoplay: false,
      loop: false,
    });
  }, []);

  useEffect(() => {
    if (inView && !played) {
      setPlayed(true);
      lottie.play(animationName);
    }
  }, [inView, played]);

  return <Container {...props} id="lottie-anim" ref={ref} />;
};

export default AnimatedChart;
