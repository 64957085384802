import styled from 'styled-components';

export const Container = styled.h3`
  display: flex;
  align-items: flex-start;
`;

export const Index = styled.span`
  color: ${({ theme }) => theme.colors.gray};
  font-size: 1rem;
  line-height: 1.5;
  margin-right: 0.125rem;
`;

export const Title = styled.span`
  font-weight: ${({ theme }) => theme.weights.medium};
  font-size: 1.5rem;
  line-height: 1.4;

  :first-letter {
    text-transform: capitalize;
  }
`;
