import styled from 'styled-components';

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.darkerGray};
  display: flex;
  padding-top: 0.25rem;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
`;

export const Title = styled.div`
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.4;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  height: max-content;
  margin-top: 0.24rem;
  margin-right: 3rem;

  @media print {
    break-before: avoid;
    break-inside: avoid;
    break-after: avoid;
  }
`;

export const Table = styled.table`
  @media print {
    display: block;
    break-before: avoid;
  }
`;

export const Head = styled.thead``;

export const TableRow = styled.tr``;

export const HeadCell = styled.th`
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.gray};
  padding-bottom: 1rem;

  &:not(:last-child) {
    padding-right: 1rem;
  }

  &:first-child {
    width: 7.5625rem;
  }

  &:nth-child(2) {
    width: 6.5625rem;
  }

  &:nth-child(3) {
    width: 6.6875rem;
  }

  &:nth-child(4) {
    width: 11.3125rem;
  }

  &:nth-child(5) {
    width: 4.875rem;
  }

  &:nth-child(5) {
    width: 4.875rem;
  }
`;

export const Body = styled.tbody`
  @media print {
    break-before: avoid;

    tr:nth-child(-n + 3) {
      break-after: avoid;
    }

    tr:first-child {
      page-break-before: avoid;
    }
  }
`;
