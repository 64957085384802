import styled from 'styled-components';
import DefaultReportCover from 'components/ReportCover';
import DefaultReportSummary from 'components/ReportSummary';
import { Loader as DefaultLoader } from 'components';

export const Container = styled.div`
  overflow: hidden;
`;

export const Cover = styled(DefaultReportCover)`
  @media print {
    page-break-after: always;
    height: 100vh;
  }
`;

export const ReportSummary = styled(DefaultReportSummary)`
  background-color: ${({ theme }) => theme.colors.lightIvory};
  padding: 10rem 0;

  @media print {
    padding: 0;
  }
`;

export const Loader = styled(DefaultLoader)``;
