import styled from 'styled-components';
import DefaultWaves from 'components/vectors/Waves';
import { ZIndex } from 'styles/metrics';
import DefaultTextHighlight from 'components/TextHighlight';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 15rem 0 6rem;
  background-color: ${({ theme }) => theme.colors.ivory};
  position: relative;
`;

export const Waves = styled(DefaultWaves)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  z-index: ${ZIndex.BACKGROUND};
`;

export const HeaderContent = styled.div`
  width: 50rem;
  margin: 0 auto;
  position: relative;
  z-index: ${ZIndex.OVER_BACKGROUND};
`;

export const TextHighlight = styled(DefaultTextHighlight)`
  max-width: 37rem;
`;
