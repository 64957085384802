import { useQuery } from '@apollo/react-hooks';
import { GetGlobals } from 'graphql/types/GetGlobals';
import hexToRGB from 'utils/hexToRgb';
import GET_GLOBALS from './GetGlobals';

const useGlobals = () => {
  const { data, ...rest } = useQuery<GetGlobals>(GET_GLOBALS, {
    fetchPolicy: 'cache-and-network',
  });

  const concernColors: string[] =
    data?.concernLevels?.map(color => color!).map(({ color }) => color) ?? [];

  const rgbColors: string[] =
    concernColors?.map(color => hexToRGB(color)) ?? [];

  const allowedColors = [...concernColors, ...rgbColors];

  const headers = {
    keyFindings: data?.header?.conditions ?? '',
    actionItems: data?.header?.action_items ?? '',
    footer: data?.header?.footer ?? '',
    behavior: data?.header?.behavior ?? '',
    longevity: data?.header?.longevity_graph ?? '',
    documents: data?.header?.documents ?? '',
  };

  return {
    allowedColors,
    headers,
    ...rest,
  };
};

export default useGlobals;
