import Markdown from 'containers/Markdown';
import styled, { css } from 'styled-components';
import { Props } from './types';

export const Row = styled.tr`
  border-top: 1px solid #e5e5e5;
`;

const cell = css`
  font-size: 0.75rem;
  line-height: 1.5;
  padding: 0.25rem 0 1rem;
  vertical-align: top;
`;

export const TitleCell = styled.td`
  width: 10rem;
  font-weight: bold;
  ${cell};
`;

export const MarkerCell = styled.td`
  padding: 0.625rem 1rem;
  vertical-align: top;
`;

export const Marker = styled.div<{ marker?: Props['concernLevel'] }>`
  width: 1rem;
  height: 0.25rem;
  background-color: ${({ marker }) => marker ?? 'transparent'};
`;

export const PrintLink = styled.span`
  font-size: 0.75rem;
  display: none;
  line-height: 1.5;
  padding-bottom: 0.5rem;

  @media print {
    display: block;
  }

  :last-child {
    padding-bottom: 0.75rem;
  }
`;

export const FindingCell = styled.td`
  ${cell};
  width: 14rem;
  padding-right: 1rem;
`;

export const CommentsCell = styled.td`
  ${cell};
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  hyphens: auto;
`;

export const FormattedText = styled(Markdown)`
  padding-right: 0.5rem;
  padding-bottom: 0;
  max-width: 15.625rem;

  p:not(:last-child) {
    margin-bottom: 0.75rem;
  }

  a {
    ${({ theme }) => theme.anchorElement};
  }

  @media print {
    display: block;
  }

  p {
    display: block;
    break-inside: avoid;
  }
`;
