import DefaultChart from 'react-apexcharts';
import styled from 'styled-components';
import addAlpha from 'utils/addAlpha';
import { ApexOptions, ApexStroke } from './types';

export const Container = styled.div`
  margin: 0;
  margin-top: 1rem;
  position: relative;
`;

export const ChartContainer = styled.div``;

export const Chart = styled(DefaultChart)`
  max-width: 690px;
  margin-left: -2rem;

  [id^='SvgjsPolygon'] {
    stroke: #a2a09f;
    stroke-width: 0.03125rem;
  }
  [id^='SvgjsLine'] {
    stroke: #a2a09f;
    stroke-width: 0.03125rem;
    stroke-dasharray: 2;
  }
  .apexcharts-grid {
    display: none;
  }
  .apexcharts-xaxis {
    transform: scale(1.1);
  }

  .apexcharts-datalabel {
    color: ${({ theme }) => theme.colors.gray};
    font-size: 0.7rem;
  }
  .apexcharts-tooltip {
    background: rgba(69, 65, 63, 0.6);
    backdrop-filter: blur(1.0625rem);
    color: ${({ theme }) => theme.colors.white};
    min-width: 9.5rem;
    padding: 0.5rem 1rem;
  }
  .tooltip-top {
    border-bottom: 0.03125rem solid
      ${({ theme }) => addAlpha(theme.colors.white, 0.33)};
    font-size: 1rem;
    line-height: 150%;
    padding-bottom: 0.125rem;
  }
  .tooltip-bottom {
    align-items: baseline;
    display: flex;
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 140%;
    padding-top: 0.125rem;
    .tooltip-fade-text {
      color: ${({ theme }) => theme.colors.white};
      font-size: 0.75rem;
      line-height: 150%;
      opacity: 0.6;
    }
  }
`;

export const ScoreContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: -1rem;
  right: 0;
`;

export const ScoreNumber = styled.span`
  font-family: ${({ theme }) => theme.fonts.aeonik};
  align-items: center;
  color: ${({ theme }) => theme.colors.darkerGray};
  display: flex;
  font-weight: bold;
  font-size: 3rem;
  line-height: 1;
  margin-right: 0.5rem;
`;

export const ScoreSection = styled.div``;

export const ScoreTitle = styled.span`
  color: ${({ theme }) => theme.colors.darkerGray};
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.4;
`;

export const ScoreText = styled.p`
  align-items: center;
  color: ${({ theme }) => theme.colors.lightGray};
  display: flex;
  font-weight: ${({ theme }) => theme.weights.medium};
  font-size: 0.875rem;
  line-height: 1;
`;

export const NotesContainer = styled.div`
  bottom: 1rem;
  max-width: 13rem;
  position: absolute;
  right: 0;
`;

export const Note = styled.p`
  color: ${({ theme }) => theme.colors.lightGray};
  font-style: italic;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 150%;
`;

export const radarOptions: ApexOptions = {
  chart: {
    id: 'basic-bar',
    toolbar: { show: false },
    foreColor: '#A2A09F',
    type: 'radar',
  },
  tooltip: {
    custom({
      series,
      seriesIndex,
      dataPointIndex,
      w,
    }: {
      series: any[];
      seriesIndex: number;
      dataPointIndex: number;
      w: any;
    }) {
      return `<div class="tooltip-top">${w.globals.labels[dataPointIndex]}</div>
        <div class="tooltip-bottom">${series[seriesIndex][dataPointIndex]}<p class="tooltip-fade-text">/5</p></div>`;
    },
  },
  fill: {
    colors: ['#7EB85B'],
    opacity: 0.1,
    type: 'solid',
  },
  yaxis: {
    tickAmount: 5,
    min: 0,
    max: 5,
    labels: {
      offsetY: -8,
    },
  },
  plotOptions: {
    radar: {
      size: 175,
      polygons: {
        fill: {
          colors: ['transparent'],
        },
      },
    },
  },
  markers: {
    size: 3,
    colors: ['#7EB85B'],
  },
  stroke: {
    show: true,
    curve: 'smooth' as ApexStroke['curve'],
    lineCap: 'butt' as ApexStroke['lineCap'],
    colors: ['#7EB85B'],
    width: 1,
  },
};
