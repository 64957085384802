import React, { FC, useRef, useState, useEffect } from 'react';
import Scrollspy from 'react-scrollspy';
import useIsInView from 'hooks/useIsInView';
import { Container, Content, Item, Link } from './styles';
import { Props } from './types';

const SectionNav: FC<Props> = ({ items, ...rest }) => {
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const { showSectionNav } = useIsInView();

  useEffect(() => {
    if (ref?.current) {
      setHeight(ref.current.clientHeight);
    }
  }, [setHeight]);

  return (
    <Container {...rest}>
      <Content
        id="sectionNav"
        ref={ref}
        elementHeight={height}
        showSectionNav={showSectionNav}
      >
        <Scrollspy
          items={items.map(x => x.id)}
          currentClassName="active"
          offset={-300}
        >
          {items.map(({ name, id }) => (
            <Item key={id}>
              <Link href={`#${id}`}>{name}</Link>
            </Item>
          ))}
        </Scrollspy>
      </Content>
    </Container>
  );
};

export default SectionNav;
