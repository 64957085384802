import styled, { css } from 'styled-components';
import { ZIndex } from 'styles/metrics';
import { ContainerProps } from './types';

export const Container = styled.div`
  height: 100%;
  left: 3.0625rem;
  padding: 93rem 0 0;
  position: absolute;
  top: 0;

  @media screen and (max-width: 1439px) {
    left: 1.4375rem;
  }

  @media print {
    display: none;
  }
`;

export const Content = styled.div<ContainerProps>`
  opacity: 0;
  position: sticky;
  top: calc(50vh - ${({ elementHeight }) => elementHeight / 2}px);
  transition: opacity 0.25s ease-in-out;
  z-index: ${ZIndex.SECTION_NAV};

  ${({ showSectionNav }) =>
    showSectionNav &&
    css`
      opacity: 1;
    `};
`;

export const Item = styled.li`
  margin-bottom: 1.5rem;
  transition: transform 0.3s ease;
  color: ${({ theme }) => theme.colors.lightGray};

  &:first-child {
    display: none;
  }

  &.active {
    transform: scale(1.333) translateX(13%);
    color: ${({ theme }) => theme.colors.gray};

    a:before {
      background-color: ${({ theme }) => theme.colors.gray};
    }

    @media screen and (max-width: 1439px) {
      transform: scale(1.66);
    }
  }

  :not(.active)&:hover {
    transform: scale(1.333) translateX(13%);
  }

  @media screen and (max-width: 1439px) {
    margin-bottom: 0.5rem;

    :not(.active)&:hover {
      transform: scale(1.333);
    }
  }
`;

export const Link = styled.a`
  align-items: center;
  color: inherit;
  display: flex;
  font-size: 0.75rem;
  line-height: 150%;
  padding: 0;
  text-decoration: none;
  transition: transform 0.5s ease;

  @media screen and (max-width: 1439px) {
    font-size: 0;
    padding: 0.5rem;
  }

  &:before {
    background-color: ${({ theme }) => theme.colors.lightGray};
    content: ' ';
    height: 0.0625rem;
    width: 1rem;
    margin-right: 0.5rem;

    @media screen and (max-width: 1439px) {
      height: 1.5rem;
      width: 0.0625rem;
      margin-right: 0;
    }
  }
`;
