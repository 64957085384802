import styled from 'styled-components';
import DefaultLongevityWaves from 'components/vectors/LongevityWaves';
import PrintArea from 'components/PrintArea';
import DefaultTextHighlight from 'components/TextHighlight';
import DefaultAnimatedChart from 'components/AnimatedChart';
import Graph from 'components/vectors/Graph';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.lightIvory};
  position: relative;

  @media print {
    break-inside: avoid;
    min-height: 100vh;
  }
`;

export const Content = styled(PrintArea)`
  padding: 10rem 0;
  position: relative;
  z-index: 1;

  @media print {
    padding: 4rem 3rem;
  }
`;

export const Title = styled(DefaultTextHighlight)`
  margin-bottom: 10rem;

  @media print {
    break-inside: avoid;
    margin-bottom: 6rem;
  }
`;

export const GraphContent = styled.div`
  margin: 0 auto;
  max-width: 41.5625rem;

  @media print {
    break-inside: avoid;
    padding-top: 1rem;
  }
`;

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.darkerGray};
  font-weight: ${({ theme }) => theme.weights.medium};
  font-size: 1.5rem;
  line-height: 1.4;
  margin-bottom: 2rem;
`;

export const Info = styled.p`
  color: ${({ theme }) => theme.colors.darkerGray};
  font-size: 1rem;
  line-height: 2;
  margin-top: 2.125rem;
`;

export const LongevityWaves = styled(DefaultLongevityWaves)`
  position: absolute;
  top: 0;
  right: -35rem;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

export const AnimatedChart = styled(DefaultAnimatedChart)`
  max-width: 43.3125rem;
  margin-left: -0.5rem;

  @media print {
    display: none;
  }
`;

export const StaticChart = styled(Graph)`
  display: none;
  max-width: 100%;
  height: auto;

  @media print {
    display: block;
  }
`;
