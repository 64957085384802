import wavesBackground from 'components/vectors/reportWaves';
import styled, { css } from 'styled-components';
import { ZIndex } from 'styles/metrics';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.ivory};
  color: white;
  display: flex;
  flex: 1;
  padding: 10.125rem 0;
  overflow: hidden;
`;

export const CoverContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 0 1.25rem;

  @media print {
    justify-content: space-between;
    flex-direction: column;
  }
`;

export const CoverText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 5rem;
  z-index: ${ZIndex.OVER_BACKGROUND};

  @media print {
    order: 2;
    text-align: center;
  }
`;

export const CoverTitle = styled.h1`
  color: ${({ theme }) => theme.colors.darkerGray};
  font-family: ${({ theme }) => theme.fonts.aeonik};
  font-weight: bold;
  font-size: 4rem;
  line-height: 120%;
  letter-spacing: 0.01em;
  margin-bottom: 1rem;
  word-wrap: break-word;
  word-break: break-word;
`;

const labelDefault = css`
  color: ${({ theme }) => theme.colors.darkerGray};
  font-weight: ${({ theme }) => theme.weights.medium};
  font-size: 1.5rem;
  line-height: 140%;
`;

export const CoverLabel = styled.p`
  ${labelDefault};
  margin-bottom: 0.5rem;
`;

export const CoverLabelFaded = styled.p`
  ${labelDefault};
  opacity: 0.6;
`;

export const ScanImage = styled.img.attrs({
  'aria-hidden': true,
})`
  display: flex;
  flex-shrink: 0;
  max-width: 22rem;
  margin-left: 5rem;
  z-index: ${ZIndex.OVER_BACKGROUND};

  @media print {
    width: auto;
    max-height: 40vh;
    margin-left: 0;
    order: 1;
  }
`;

export const CoverBackground = styled(wavesBackground)`
  left: -5rem;
  position: absolute;
  top: -10.1rem;
  height: 59.75rem;
  width: 59.1875rem;

  @media print {
    height: 100vh;
  }
`;
