import DefaultImageBlock from 'components/ImageBlock';
import DefaultTextBlock from 'components/TextBlock';
import DefaultTreatment from 'components/Treatment';
import styled from 'styled-components';
import DefaultDataBlock from 'components/DataBlock';
import DefaultSimpleDataBlock from 'components/SimpleDataBlock';

export const Container = styled.div`
  padding-bottom: 3.5rem;
  > * + * {
    margin-top: 6rem;
  }
`;

export const DataBlock = styled(DefaultDataBlock)`
  @media print {
    break-inside: avoid;
  }
`;

export const SimpleDataBlock = styled(DefaultSimpleDataBlock)`
  @media print {
    break-inside: avoid;
  }
`;

export const ImageBlock = styled(DefaultImageBlock)`
  @media print {
    break-inside: avoid;
  }
`;

export const TextBlock = styled(DefaultTextBlock)`
  @media print {
    break-inside: avoid;
  }
`;

export const Treatment = styled(DefaultTreatment)`
  margin-top: 3.5rem;

  @media print {
    break-inside: avoid;
  }
`;
