import styled from 'styled-components';

export const Container = styled.section`
  margin-top: 6rem;
  display: none;

  @media print {
    display: block;
    break-after: always;
  }
`;

export const Contents = styled.ul`
  margin-top: 2rem;
  margin-left: 8rem;
`;

export const Item = styled.li`
  color: ${({ theme }) => theme.colors.gray};
  font-size: 1rem;
  line-height: 1.5;
  position: relative;

  :not(:last-child) {
    margin-bottom: 1rem;
  }

  :before {
    content: '';
    background-color: currentColor;
    width: 1rem;
    height: 1px;
    position: absolute;
    left: -1.5rem;
    top: 0.75rem;
  }
`;
