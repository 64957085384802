import gql from 'graphql-tag';

export default gql`
  query GetGlobals {
    concernLevels {
      color
    }
    header(id: 1) {
      action_items
      behavior
      conditions
      footer
      longevity_graph
      documents
    }
  }
`;
