import styled from 'styled-components';
import UpRightArrow from 'components/vectors/UpRightArrow';

export const Container = styled.div`
  max-width: 55rem;
  margin: 0 auto;
  background-color: transparent;
  :not(:first-child) {
    padding-top: 0.125rem;
  }
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.darkerGray};
  text-decoration: none;
`;

export const Content = styled.div`
  padding: 3rem 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightIvory};
  }
`;

export const ArrowIcon = styled(UpRightArrow)`
  color: ${({ theme }) => theme.colors.gray};
  width: 1rem;
  height: 1rem;
`;

export const Description = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  margin-top: 0.5rem;
`;

export const Text = styled.div``;

export const Heading = styled.h4`
  font-size: 1.5rem;
  line-height: 1.4;
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-bottom: 0.75rem;
`;

export const Url = styled.span`
  color: ${({ theme }) => theme.colors.gray};
  font-size: 1rem;
  line-height: 1.5;
`;
