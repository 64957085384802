import PrintArea from 'components/PrintArea';
import { ResultType } from 'model/Results';
import React, { FC } from 'react';
import {
  Container,
  ImageBlock,
  Images,
  PanelA,
  PanelB,
  PanelC,
  SectionTitle,
} from './styles';
import { Props } from './types';

const ResultBlocks: FC<Props> = ({ items, ...rest }) => (
  <Container {...rest}>
    {items &&
      items.map(result => {
        switch (result?.type) {
          case ResultType.SectionHeader:
            return (
              <PrintArea key={`${result.id}${result.type}`}>
                <SectionTitle title={result.name} />
              </PrintArea>
            );
          case ResultType.BlockImage:
            return (
              <Images key={`${result.id}${result.type}`}>
                <ImageBlock
                  description={result.subtitle}
                  image={result.file}
                  title={result.title}
                  type="results"
                />
              </Images>
            );
          case ResultType.TableA:
            return (
              <PrintArea key={`${result.id}${result.type}`}>
                <PanelA title={result.name} rows={result.rows} />
              </PrintArea>
            );
          case ResultType.TableB:
            return (
              <PrintArea key={`${result.id}${result.type}`}>
                <PanelB title={result.name} rows={result.rows} />
              </PrintArea>
            );
          case ResultType.TableC:
            return (
              <PrintArea key={`${result.id}${result.type}`}>
                <PanelC title={result.name} rows={result.rows} />
              </PrintArea>
            );
          default:
            return <PrintArea />;
        }
      })}
  </Container>
);

export default ResultBlocks;
