import React, { FC } from 'react';
import RowC from './RowC';
import {
  Body,
  Container,
  Head,
  HeadCell,
  Table,
  TableRow,
  Title,
} from './styles';
import { Props } from './types';

const tableHeaders = [
  'Health Category',
  'Gene',
  'Classification',
  'Associated Condition',
  'INH',
  'Zygosity',
  'Transcript',
  'Variant',
  'Allele Frequency',
];

const PanelC: FC<Props> = ({
  rows = [],
  title,
  headers = tableHeaders,
  ...rest
}) => (
  <Container {...rest}>
    <Title>{title}</Title>
    <Table>
      <Head>
        <TableRow>
          {headers?.map(t => (
            <HeadCell key={t}>{t}</HeadCell>
          ))}
        </TableRow>
      </Head>
      <Body>
        {rows.map(row => (
          <RowC key={row.id} {...row} />
        ))}
      </Body>
    </Table>
  </Container>
);

export default PanelC;
