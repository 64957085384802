import React, { FC } from 'react';
import RatingBar from './RatingBar';
import { Container, Label } from './styles';
import { Props } from './types';

const Rating: FC<Props> = ({ risk, ...rest }) => (
  <Container {...rest}>
    <Label>{risk}</Label>
    <RatingBar risk={risk} />
  </Container>
);

export default Rating;
