import React, { FC } from 'react';
import useConnect from './connect';
import { Container, ResultsHeader, ResultBlocks, Footer } from './styles';
import { Loader } from '../Reports/styles';

const Results: FC = () => {
  const { comprehensiveResultsBlocks, loading } = useConnect();
  return (
    <>
      <Loader loading={loading} />
      <Container>
        <ResultsHeader />
        <ResultBlocks items={comprehensiveResultsBlocks} />
        <Footer />
      </Container>
    </>
  );
};

export default Results;
