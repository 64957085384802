import ReportTitle from 'components/ReportTitle';
import React, { FC } from 'react';
import { Container, Contents, Item } from './styles';

const items = [
  'Biograph score',
  'Key findings',
  'Insights',
  'Action Items',
  'Longevity behaviors',
  'Documents',
];

const ContentsIndex: FC = () => (
  <Container>
    <ReportTitle title="Contents" />
    <Contents>
      {items.map(it => (
        <Item key={it}>{it}</Item>
      ))}
    </Contents>
  </Container>
);

export default ContentsIndex;
