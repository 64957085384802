import Markdown from 'containers/Markdown';
import DefaultBlockTitle from 'components/BlockTitle';
import styled, { css } from 'styled-components';

export const BlockTitle = styled(DefaultBlockTitle)`
  flex: 0 0 39%;
`;

export const Container = styled.div<{ textBlockType: 'findings' | 'insights' }>`
  border-top: 0.0625rem solid ${({ theme }) => theme.colors.ivory};
  padding-top: 1rem;
  width: 100%;
  display: flex;

  ${({ textBlockType }) =>
    textBlockType === 'insights' &&
    css`
      ${BlockTitle} {
        flex: 0 0 50%;
        margin-right: 1rem;
      }
    `};
`;

export const Description = styled(Markdown)`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  line-height: 1.5;
  flex: 1 0;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1.4;
    margin-bottom: 1rem;

    @media print {
      break-after: avoid;
    }
  }

  p:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  p:first-child {
    @media print {
      break-inside: avoid;
    }
  }

  *:first-child {
    @media print {
      break-after: avoid;
    }
  }

  strong {
    font-weight: ${({ theme }) => theme.weights.bold};
  }

  em {
    font-style: italic;
  }

  li {
    position: relative;
    margin-left: 3rem;
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  li::before {
    content: '';
    height: 0.125rem;
    background-color: ${({ theme }) => theme.colors.darkerGray};
    width: 2rem;
    position: absolute;
    top: 0.7rem;
    left: -3rem;
  }

  a {
    ${({ theme }) => theme.anchorElement};
  }
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.weights.semiBold};
  font-size: 1.125rem;
  line-height: 1.4;
  margin-bottom: 1rem;

  :first-letter {
    text-transform: capitalize;
  }
`;

export const CommentsContainer = styled.div``;

export const PrintLink = styled.span`
  font-size: 0.75rem;
  display: none;
  line-height: 1.5;
  padding-top: 0.5rem;

  @media print {
    display: block;
  }

  :last-child {
    padding-bottom: 0.75rem;
  }
`;
