import styled from 'styled-components';
import DefaultLogo from 'components/vectors/Logo';

const body = require('assets/cover/body1284.png');

export const Container = styled.div`
  color: white;
  display: flex;
  flex: 1;
  position: relative;
`;

export const Content = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 4rem;
  position: relative;
  z-index: 1;
`;

export const Logo = styled(DefaultLogo)`
  max-width: 34vh;
`;

export const Title = styled.h1`
  font-size: 6vh;
  font-style: normal;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0.02rem;
  line-height: 6.5vh;
  margin-top: 25vh;
`;

export const Info = styled.div`
  border-top: 0.0625rem solid ${({ theme }) => theme.colors.mediumGray};
  margin-top: auto;
`;

export const Name = styled.p`
  font-size: 2vh;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: 2vh;
  margin: 1vh 0;
`;

export const Date = styled.p`
  font-size: 2vh;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: 2vh;
  opacity: 0.6;
  margin: 0;
`;

export const BodyImg = styled.img.attrs({
  'aria-hidden': true,
  src: body,
})`
  bottom: 0;
  height: 100%;
  position: absolute;
  right: 0;
`;
