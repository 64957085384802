import styled from 'styled-components';
import DefaultTags from 'components/Tags';
import Markdown from 'containers/Markdown';

export const Container = styled.div`
  display: inline-flex;

  :not(:last-child) {
    margin-bottom: 3.5rem;
  }

  @media print {
    break-inside: avoid;
  }
`;

export const Content = styled.div`
  flex: 1;
`;

export const Checkbox = styled.div`
  width: 2rem;
  height: 2rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.darkGray};
  margin-right: 1rem;
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.darkerGray};
  align-items: center;
  display: flex;
  font-weight: ${({ theme }) => theme.weights.medium};
  font-size: 1.5rem;
  line-height: 1.4;
  margin-bottom: 0.75rem;
  &::first-letter {
    text-transform: capitalize;
  }
`;

export const Text = styled(Markdown)`
  span,
  p {
    color: ${({ theme }) => theme.colors.gray};
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 0.75rem;
  }

  strong {
    font-weight: ${({ theme }) => theme.weights.bold};
  }

  em {
    font-style: italic;
  }

  a {
    ${({ theme }) => theme.anchorElement};
  }
`;

export const Tags = styled(DefaultTags)``;

export const CommentsContainer = styled.div``;

export const PrintLink = styled.span`
  font-size: 0.75rem;
  display: none;
  line-height: 1.5;
  padding-bottom: 0.5rem;

  @media print {
    display: block;
  }

  :last-child {
    padding-bottom: 0.75rem;
  }
`;
