import React, { FC } from 'react';
import { Container, Index, Title } from './styles';
import { Props } from './types';

const BlockTitle: FC<Props> = ({ index, title, ...rest }) => (
  <Container {...rest}>
    {index && <Index>{index}</Index>}
    <Title>{title}</Title>
  </Container>
);

export default BlockTitle;
