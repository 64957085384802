import gql from 'graphql-tag';

export default gql`
  query GetPatient($patientId: ID!) {
    patient(id: $patientId) {
      id
      first_name
      last_name
      date
      results_type
      cover_image {
        url
      }
      summary {
        description
      }
      dashboard {
        biological_age
        calendar_age
        biological_age_index
      }
      action_items {
        id
        title
        description
        labels {
          id
          value
        }
      }
      longevity_behaviors {
        id
        name
      }
      documents {
        id
        title
        description
        file {
          url
        }
      }
      comprehensive_result {
        blocks {
          ... on ComponentTableTypeATable {
            id
            name
            rows {
              id
              examination
              concern_level {
                color
              }
              finding_label
              finding_data {
                value
                unit
                min
                max
                lower_bound
                upper_bound
              }
              comments
            }
          }
          ... on ComponentTableTypeBTable {
            id
            name
            rows {
              id
              therapeutic
              drug
              phenotype
              comments
              gene
              genotype
            }
          }
          ... on ComponentTableTypeCTable {
            id
            name
            rows {
              id
              health_category
              gene
              classification
              associated_condition
              inh
              zygosity
              transcript
              variant
              allele_frequency
            }
          }
          ... on ComponentTableSectionHeader {
            id
            name
          }
          ... on ComponentBlockImage {
            id
            file {
              url
            }
            title
            subtitle
          }
        }
      }
    }

    keyFindings(where: { patient: $patientId }) {
      id
      title
      description
      risk
      blocks {
        ... on ComponentBlockData {
          id
          title
          items {
            id
            name
            value
            min
            max
            unit
            concern_level {
              color
              name
            }
            lower_bound
            upper_bound
          }
        }
        ... on ComponentBlockText {
          id
          title
          description
        }
        ... on ComponentBlockImage {
          id
          title
          file {
            url
          }
          subtitle
        }
        ... on ComponentBlockTreatment {
          id
          description
        }
      }
      order
    }

    insights(where: { patient: $patientId }) {
      id
      title
      description
      score
      disabled
      blocks {
        ... on ComponentBlockSimpleData {
          id
          title
          items {
            id
            name
            value
            min
            max
            label
          }
        }
        ... on ComponentBlockText {
          id
          title
          description
        }
        ... on ComponentBlockImage {
          id
          title
          file {
            url
          }
          subtitle
        }
      }
      order
    }
  }
`;
