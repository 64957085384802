import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Container,
  Checkbox,
  Content,
  Tags,
  Text,
  Title,
  CommentsContainer,
  PrintLink,
} from './styles';
import Props from './types';

const ActionItem: FC<Props> = ({ id, title, description, labels, ...rest }) => {
  const element = useRef<HTMLDivElement>(null);
  const [urlList, setUrlList] = useState<string[]>([]);

  useEffect(() => {
    const urls: string[] = [];

    const anchors = Array.from(
      element.current?.getElementsByTagName('a') ?? [],
    );

    anchors.forEach(
      (anchor: HTMLAnchorElement) => anchor?.href && urls.push(anchor?.href),
    );
    setUrlList(urls);
  }, [element, setUrlList]);

  return (
    <Container {...rest}>
      <Checkbox />
      <Content>
        <Title>{title}</Title>
        <CommentsContainer ref={element}>
          <Text>{description}</Text>
          {urlList.length > 0 &&
            urlList?.map(url => <PrintLink key={url}>{url}</PrintLink>)}
        </CommentsContainer>

        {labels.length > 0 && <Tags items={labels} />}
      </Content>
    </Container>
  );
};

export default ActionItem;
