import React, { FC } from 'react';
import {
  Container,
  Content,
  Description,
  ReportSection,
  TreatmentInfo,
  Waves,
} from './styles';
import { Props } from './types';

const Treatment: FC<Props> = ({ description, subtitle, ...rest }) => (
  <Container {...rest}>
    <Content>
      <ReportSection subtitle={subtitle} title="Treatment">
        <TreatmentInfo>
          <Description>{description}</Description>
        </TreatmentInfo>
      </ReportSection>
      <Waves />
    </Content>
  </Container>
);

export default Treatment;
