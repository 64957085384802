import styled from 'styled-components';
import RadarChart from 'components/RadarChart';

export const Container = styled.div``;

export const DescriptionText = styled.p`
  color: ${({ theme }) => theme.colors.darkerGray};
  font-size: 0.75rem;
  line-height: 1.5;
  max-width: 19rem;
  margin-top: 1.5rem;

  @media print {
    margin-top: 1.5rem;
  }
`;

export const BiographChart = styled(RadarChart)``;
