import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      d="M9.5 0h-1v2h1V0zm9 1h1V0h-1v1zm-1 9v1h2v-1h-2zm-8-8h9V0h-9v2zm8-1v9h2V1h-2z"
      fill="currentColor"
    />
    <path
      d="M1.293 16.793l-.707.707L2 18.914l.707-.707-1.414-1.414zM19.207 1.707A1 1 0 0017.793.293l1.414 1.414zm-16.5 16.5l16.5-16.5L17.793.293l-16.5 16.5 1.414 1.414z"
      fill="currentColor"
    />
  </svg>
));
