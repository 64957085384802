import React, { FC } from 'react';
import RowB from './RowB';
import {
  Body,
  Container,
  Head,
  HeadCell,
  Table,
  TableRow,
  Title,
} from './styles';
import { Props } from './types';

const tableHeaders = [
  'Therapeutic',
  'Drug',
  'Phenotype',
  'Comments',
  'Gene',
  'Genotype',
];

const PanelB: FC<Props> = ({
  rows = [],
  title,
  headers = tableHeaders,
  ...rest
}) => (
  <Container {...rest}>
    <Title>{title}</Title>
    <Table>
      <Head>
        <TableRow>
          {headers?.map(t => (
            <HeadCell key={t}>{t}</HeadCell>
          ))}
        </TableRow>
      </Head>
      <Body>
        {rows.map(row => (
          <RowB key={row.id} {...row} />
        ))}
      </Body>
    </Table>
  </Container>
);

export default PanelB;
