import styled from 'styled-components';
import DefaultTableAChartBar from './PanelAChartBar';

export const Container = styled.div`
  width: 100%;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.darkerGray};
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.4;

  &::first-letter {
    text-transform: capitalize;
  }
`;

export const Chart = styled.div`
  display: flex;
  align-items: center;
`;

export const BarContainer = styled.div`
  flex: 0 1 14.4375rem;
  margin-top: -0.25rem;
`;

export const TableAChartBar = styled(DefaultTableAChartBar)``;

export const Value = styled.div`
  color: ${({ theme }) => theme.colors.lightGray};
  font-size: 0.75rem;
  line-height: 100%;
  text-transform: lowercase;
`;

export const TitleContainer = styled.div`
  align-items: baseline;
  display: flex;
  * + * {
    margin-left: 0.25rem;
  }
`;
