import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg fill="none" viewBox="0 0 26 32" {...props}>
    {children}
    <path
      d="M15.492 12.543l2.432-1.206c1.38-.688 2.26-2.052 2.294-3.558 0-.034.003-.067.003-.101 0-2.312-1.87-4.195-4.172-4.195H3.466v6.001c0 .857.546 1.607 1.36 1.864l14.803 4.688a8.076 8.076 0 014.111 2.96 8.145 8.145 0 011.573 4.833c0 4.505-3.646 8.171-8.126 8.171H0v-8.567a5.425 5.425 0 013.29-5.005l2.065-1.003 4.833 1.531L4.71 21.61l-.043.019a1.95 1.95 0 00-1.2 1.804v5.081H17.19c2.568 0 4.66-2.1 4.66-4.685a4.66 4.66 0 00-3.26-4.467L3.784 14.674A5.411 5.411 0 010 9.486V0h16.05c4.21 0 7.637 3.444 7.637 7.68 0 .062 0 .123-.002.185a7.553 7.553 0 01-3.473 6.17"
      fill="#666"
    />
  </svg>
));
