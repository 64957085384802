import styled from 'styled-components';

export const Container = styled.div`
  border-top: 0.0625rem solid ${({ theme }) => theme.colors.ivory};
  display: flex;
  padding-top: 1rem;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 35% auto;
  grid-gap: 2.25rem;
  width: 100%;
`;

export const Info = styled.div`
  align-items: flex-start;
  display: flex;
`;

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.gray};
  font-size: 1rem;
  line-height: 1.5;
  margin-right: 0.125rem;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.weights.medium};
  font-size: 1.5rem;
  line-height: 1.4;

  :first-letter {
    text-transform: capitalize;
  }
`;
