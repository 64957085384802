import React, { FC } from 'react';
import Container from './styles';
import { Props } from './types';

const PrintArea: FC<Props> = ({ children, dontBreak = false, ...rest }) => (
  <Container dontBreak={dontBreak} {...rest}>
    {children}
  </Container>
);

export default PrintArea;
