import Results from 'containers/Results';
import Reports from 'containers/Reports';
import React, { FC } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Container, Content, Navbar, NavContainer } from './styles';

const Main: FC = () => (
  <Container>
    <NavContainer>
      <Navbar />
    </NavContainer>
    <Content>
      <Switch>
        <Route path="/:id/results">
          <Results />
        </Route>
        <Route path="/:id/reports">
          <Reports />
        </Route>
        <Redirect to="/1/reports" />
      </Switch>
    </Content>
  </Container>
);

export default Main;
