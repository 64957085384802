import React, { FC } from 'react';
import {
  Container,
  Content,
  Document,
  PrintArea,
  ReportTitle,
  Title,
} from './styles';
import { Props } from './types';

const Documents: FC<Props> = ({ title, items, ...rest }) => (
  <Container {...rest}>
    {items && (
      <Content>
        <PrintArea dontBreak>
          <ReportTitle title="Documents" />
          <Title text={title} />
        </PrintArea>
        {items.map(document => (
          <Document
            key={document.id}
            id={document.id}
            title={document.title}
            description={document.description}
            file={document.file}
          />
        ))}
      </Content>
    )}
  </Container>
);

export default Documents;
