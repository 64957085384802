import DefaultLogo from 'components/vectors/Logo';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.darkerGray};
`;

export const Logo = styled(DefaultLogo)`
  height: 1.5rem;
`;

export const Navigation = styled.nav`
  height: 100%;
  display: flex;

  @media print {
    display: none;
  }
`;

export const NavItem = styled(NavLink)`
  margin-left: 3rem;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.darkerGray};
  text-decoration: none;
  display: flex;
  align-items: flex-start;

  &.active {
    border-bottom: 0.0625rem solid;
  }
`;
