import Markdown from 'containers/Markdown';
import styled, { css } from 'styled-components';

export const Row = styled.tr`
  border-top: 1px solid #e5e5e5;
`;

const cell = css`
  font-size: 0.75rem;
  line-height: 1.5;
  padding: 0.25rem 0 0.75rem;
  vertical-align: top;

  &:not(:last-child) {
    padding-right: 1rem;
  }
`;

export const TitleCell = styled.td`
  ${cell};
  font-weight: bold;
`;

export const TextCell = styled.td`
  ${cell};
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  hyphens: auto;
`;

export const FormattedText = styled(Markdown)`
  ${cell};
  padding-bottom: 0;

  p:not(:last-child) {
    margin-bottom: 0.75rem;
  }

  a {
    ${({ theme }) => theme.anchorElement};
  }

  @media print {
    display: block;

    p {
      display: block;
      break-inside: avoid;
    }
  }
`;

export const PrintLink = styled.span`
  font-size: 0.75rem;
  display: none;
  line-height: 1.5;
  padding-bottom: 0.5rem;

  @media print {
    display: block;
  }

  :last-child {
    padding-bottom: 0.75rem;
  }
`;
