import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Container,
  Description,
  Image,
  ImageContainer,
  PrintArea,
  Title,
  CommentsContainer,
  PrintLink,
} from './styles';
import { Props } from './types';

const ImageBlock: FC<Props> = ({
  description,
  image,
  index,
  title,
  type = 'reports',
  ...rest
}) => {
  const isReports = type === 'reports';
  const element = useRef<HTMLDivElement>(null);
  const [urlList, setUrlList] = useState<string[]>([]);

  useEffect(() => {
    const urls: string[] = [];
    const anchors = Array.from(
      element.current?.getElementsByTagName('a') ?? [],
    );
    anchors.forEach(
      (anchor: HTMLAnchorElement) => anchor?.href && urls.push(anchor?.href),
    );
    setUrlList(urls);
  }, [element, setUrlList]);

  return (
    <Container isReports={isReports} {...rest}>
      <PrintArea dontBreak>
        <Title index={index} title={title} isReports={isReports} />
        <ImageContainer>
          <Image src={image} />
        </ImageContainer>
        {description && (
          <CommentsContainer ref={element}>
            <Description>{description}</Description>
            {urlList.length > 0 &&
              urlList?.map(url => <PrintLink key={url}>{url}</PrintLink>)}
          </CommentsContainer>
        )}
      </PrintArea>
    </Container>
  );
};

export default ImageBlock;
