import React, { FC } from 'react';
import Main from 'containers/Main';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import themes from 'styles/themes';
import { ApolloProvider } from '@apollo/react-hooks';
import client from 'graphql/client';
import 'assets/fonts/stylesheet.css';

const App: FC = () => (
  <ApolloProvider client={client}>
    <ThemeProvider theme={themes.light}>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </ThemeProvider>
  </ApolloProvider>
);

export default App;
