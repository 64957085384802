import DefaultBlockTitle from 'components/BlockTitle';
import { Index } from 'components/BlockTitle/styles';
import DefaultPrintArea from 'components/PrintArea';
import styled, { css } from 'styled-components';
import Markdown from 'containers/Markdown';

export const Container = styled.div<{ isReports: boolean }>`
  background-color: ${({ isReports, theme }) =>
    isReports ? theme.colors.black : theme.colors.ivory};
  ${({ isReports, theme }) =>
    isReports
      ? css`
          background-color: ${theme.colors.black};
          color: ${theme.colors.lightGray};
        `
      : css`
          background-color: ${theme.colors.ivory};
          color: ${theme.colors.darkerGray};
        `};
  width: 100%;
  padding: 3.375rem 0;
`;

export const PrintArea = styled(DefaultPrintArea)`
  display: block;
`;

export const Title = styled(DefaultBlockTitle)<{ isReports: boolean }>`
  ${({ isReports }) =>
    isReports &&
    css`
      color: ${({ theme }) => theme.colors.white};
    `};

  ${Index} {
    color: ${({ theme }) => theme.colors.lightGray};
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  margin: 1.5rem auto;
  flex-shrink: 1;

  @media print {
    width: fit-content;
  }
`;

export const Image = styled.img.attrs({
  'aria-hidden': true,
})`
  width: 100%;
  margin: auto;

  @media print {
    max-height: 60vh;
    width: auto;
  }
`;

const commonStyles = css`
  font-size: 0.75rem;
  line-height: 1.5;
`;

export const Description = styled(Markdown)`
  max-width: 31rem;
  span,
  p {
    ${commonStyles};
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${({ theme }) => theme.colors.white};
    font-size: 1rem;
  }

  li {
    ${commonStyles};
    margin-left: 3rem;
    position: relative;
  }

  li::before {
    content: '';
    height: 0.125rem;
    background-color: ${({ theme }) => theme.colors.white};
    width: 2rem;
    position: absolute;
    top: 0.5rem;
    left: -3rem;
  }

  strong {
    font-weight: ${({ theme }) => theme.weights.bold};
  }

  em {
    font-style: italic;
  }

  a {
    ${({ theme }) => theme.anchorElement};
    margin: 1rem 0;
  }
`;

export const CommentsContainer = styled.div``;

export const PrintLink = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.75rem;
  display: none;
  line-height: 1.5;
  padding-bottom: 0.5rem;

  @media print {
    display: block;
  }

  :last-child {
    padding-bottom: 0.75rem;
  }
`;
