import styled from 'styled-components';

export const Svg = styled.svg``;

export const Circle = styled.circle`
  transform: rotateZ(-90deg) translateX(-100%);
`;

export const Text = styled.text`
  font-size: 150%;
`;
