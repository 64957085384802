import React, { FC } from 'react';
import { Line, Polygon, HighlightedBar, RangeText, Svg } from './styles';
import { Props } from './types';

const TRIANGLE_Y = 1.25;
const TRIANGLE_SIZE = 1.25;

const Bar: FC<Props> = ({
  type = 'number',
  highlightEnd,
  highlightStart,
  rangeMax,
  rangeMin,
  valuePosition,
  ...rest
}) => (
  <Svg viewBox="0 0 100 8" {...rest}>
    <Polygon
      fill="currentColor"
      points={`
        ${valuePosition - TRIANGLE_SIZE}, ${TRIANGLE_Y}
        ${valuePosition + TRIANGLE_SIZE}, ${TRIANGLE_Y}
        ${valuePosition}, ${TRIANGLE_Y + TRIANGLE_SIZE * 1.75}`}
    />
    <Line
      stroke="currentColor"
      strokeWidth="0.01rem"
      x1={0}
      y1={4.5}
      x2={100}
      y2={4.5}
    />
    <HighlightedBar
      stroke="#7EB85B"
      strokeWidth="0.05rem"
      x1={highlightStart}
      y1={4.5}
      x2={highlightEnd}
      y2={4.5}
    />
    {highlightEnd <= 100 && (
      <RangeText
        dominantBaseline="middle"
        fill="currentColor"
        textAnchor="middle"
        x={highlightEnd}
        y="7.5"
      >
        {type === 'percentage' ? `${highlightEnd}%` : highlightEnd}
      </RangeText>
    )}
    {highlightStart >= 0 && (
      <RangeText
        dominantBaseline="middle"
        fill="currentColor"
        textAnchor="middle"
        x={highlightStart}
        y="7.5"
      >
        {highlightStart}
      </RangeText>
    )}
  </Svg>
);

export default Bar;
