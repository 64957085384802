import React, { FC } from 'react';
import { HighlightedBar, Line, Svg } from './styles';
import { Props } from './types';

const SimpleDataBlockChartBar: FC<Props> = ({ percent, max, ...rest }) => (
  <Svg viewBox="0 0 100 1" {...rest}>
    <Line
      stroke="#ECE9E6"
      strokeWidth={0.65}
      x1={0}
      y1={0.5}
      x2={100}
      y2={0.5}
    />
    <HighlightedBar
      stroke="#7EB85B"
      strokeWidth={1.5}
      x1={0}
      y1={0.5}
      x2={max === 100 ? percent : (percent * 100) / (max ?? 100)}
      y2={0.5}
    />
  </Svg>
);

export default SimpleDataBlockChartBar;
