import React, { FC } from 'react';
import { FormattedText, Row, TextCell, TitleCell } from './styles';
import { Props } from './types';

const RowC: FC<Props> = ({
  healthCategory,
  gene,
  classification,
  associatedCondition,
  inh,
  zygosity,
  transcript,
  variant,
  alleleFrequency,
}) => {
  const textCells = [
    gene,
    classification,
    associatedCondition,
    inh,
    zygosity,
    transcript,
    variant,
    alleleFrequency,
  ];
  return (
    <Row>
      <TitleCell>{healthCategory}</TitleCell>
      {textCells.map((text, i) => (
        <TextCell key={text ?? i}>
          <FormattedText>{text}</FormattedText>
        </TextCell>
      ))}
    </Row>
  );
};

export default RowC;
