import React, { FC, useEffect, useRef, useState } from 'react';
import { Props } from './types';
import {
  Container,
  Content,
  ListIndex,
  Title,
  Text,
  CommentsContainer,
  PrintLink,
} from './styles';

const SectionTitle: FC<Props> = ({
  children,
  title,
  listIndex,
  description,
  disabledSection = false,
  ...rest
}) => {
  const element = useRef<HTMLDivElement>(null);
  const [urlList, setUrlList] = useState<string[]>([]);

  useEffect(() => {
    const urls: string[] = [];

    const anchors = Array.from(
      element.current?.getElementsByTagName('a') ?? [],
    );

    anchors.forEach(
      (anchor: HTMLAnchorElement) => anchor?.href && urls.push(anchor?.href),
    );
    setUrlList(urls);
  }, [element, setUrlList]);

  return (
    <Container {...rest}>
      <Content>
        {listIndex && (
          <ListIndex disabledSection={disabledSection}>{listIndex}</ListIndex>
        )}
        <Title disabledSection={disabledSection}>{title}</Title>
        <CommentsContainer ref={element}>
          <Text disabledSection={disabledSection}>{description}</Text>
          {!disabledSection &&
            urlList.length > 0 &&
            urlList?.map(url => <PrintLink key={url}>{url}</PrintLink>)}
        </CommentsContainer>
      </Content>
      {children}
    </Container>
  );
};

export default SectionTitle;
