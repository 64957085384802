import { useParams } from 'react-router';
import usePatient from 'queries/patients';

const useConnect = () => {
  const { id = '1' } = useParams();
  const { comprehensiveResultsBlocks, loading } = usePatient(id);

  return {
    comprehensiveResultsBlocks,
    loading,
  };
};

export default useConnect;
