import styled from 'styled-components';
import DefaultTag from 'components/Tag';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  * + * {
    margin-left: 0.5rem;
  }
`;

export const Tag = styled(DefaultTag)``;
