import useGlobals from 'queries/globals';

const useConnect = () => {
  const { allowedColors } = useGlobals();

  return {
    allowedColors,
  };
};

export default useConnect;
