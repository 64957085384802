import React, { FC } from 'react';
import { Container, Title } from './styles';
import { Props } from './types';

const ReportTitle: FC<Props> = ({ title, ...rest }) => (
  <Container {...rest}>
    <Title>{title}</Title>
  </Container>
);

export default ReportTitle;
