import {
  ActionItems,
  Documents,
  Footer,
  Insights,
  KeyFindings,
  SectionNav,
} from 'components';
import BehaviorList from 'components/BehaviorList';
import React, { FC } from 'react';
import { formatReportDate } from 'utils/formatReportDate';
import useConnect from './connect';
import { Container, ReportSummary, Cover, Loader } from './styles';

const Reports: FC = () => {
  const {
    firstName,
    lastName,
    date,
    summaryDescription,
    dashboard,
    actionItems,
    longevityBehaviors,
    keyFindings,
    documents,
    biographScore,
    insights,
    loading,
    nav,
    coverImage,
    resultsCoverLabel,
    headers,
  } = useConnect();

  return (
    <>
      <Loader loading={loading} />
      <SectionNav items={nav} />
      <Container>
        <Cover
          id="intro"
          name={`${firstName} ${lastName}`}
          date={formatReportDate(date)}
          scanImg={coverImage}
          resultsLabel={resultsCoverLabel}
        />
        <ReportSummary
          id="score"
          summaryDescription={summaryDescription}
          biographScore={biographScore}
          dashboard={dashboard}
        />
        <KeyFindings
          id="findings"
          description={headers.keyFindings}
          keyFindings={keyFindings}
          showLongevity
          longevityTitle={headers.longevity}
        />
        <Insights id="insights" insights={insights} />
        {actionItems && actionItems.length > 0 && (
          <ActionItems
            id="actions"
            description={headers.actionItems}
            actionListItems={actionItems}
          />
        )}
        {longevityBehaviors && longevityBehaviors.length > 0 && (
          <BehaviorList
            description={headers.behavior}
            id="longevity"
            behaviors={longevityBehaviors}
          />
        )}
        {documents && documents.length > 0 && (
          <Documents
            id="documents"
            title={headers.documents}
            items={documents}
          />
        )}
        <Footer title={headers.footer} />
      </Container>
    </>
  );
};

export default Reports;
