import React, { FC, useMemo } from 'react';
import { Risk } from '../types';
import {
  Container,
  Content,
  HighlightedBar,
  Label,
  Labels,
  Line,
  Polygon,
  Svg,
} from './styles';
import { Props } from './types';

const TRIANGLE_Y = 3;
const TRIANGLE_SIZE = 3;

const RatingBar: FC<Props> = ({ risk, ...rest }) => {
  const params = useMemo(() => {
    switch (risk) {
      case Risk.HIGH:
        return { color: '#F56B40', x1: 66, x2: 100, score: 84 };
      case Risk.INCREASED:
        return { color: '#F4E133', x1: 33, x2: 66, score: 50 };
      case Risk.LOW:
      default:
        return { color: '#7EB85B', x1: 0, x2: 33, score: 16 };
    }
  }, [risk]);

  return (
    <Container {...rest}>
      <Content>
        <Svg viewBox="0 0 100 6">
          <Polygon
            fill="currentColor"
            points={`
            ${params.score - TRIANGLE_SIZE}, ${TRIANGLE_Y}
            ${params.score + TRIANGLE_SIZE}, ${TRIANGLE_Y}
            ${params.score}, ${TRIANGLE_Y + TRIANGLE_SIZE * 1.75}`}
          />
        </Svg>
        <Svg viewBox="0 0 100 6">
          <Line
            stroke="currentColor"
            strokeWidth={0.5}
            x1={0}
            y1={0.5}
            x2={100}
            y2={0.5}
          />
          <HighlightedBar
            stroke={params.color}
            strokeWidth={2}
            x1={params.x1}
            y1={0.5}
            x2={params.x2}
            y2={0.5}
          />
        </Svg>
        <Labels>
          <Label>Low</Label>
          <Label>High</Label>
        </Labels>
      </Content>
    </Container>
  );
};

export default RatingBar;
