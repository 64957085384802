import styled from 'styled-components';

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.darkerGray};
`;

export const Label = styled.div`
  font-size: 1.125rem;
  line-height: 15px;
  font-weight: bold;

  &::first-letter {
    text-transform: capitalize;
  }
`;
