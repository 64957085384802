import PrintArea from 'components/PrintArea';
import Rating from 'components/Rating';
import ReportTitle from 'components/ReportTitle';
import React, { FC } from 'react';
import KeyFindingsBlocks from 'components/Blocks';
import {
  Container,
  Description,
  KeyFinding,
  Longevity,
  SectionTitle,
} from './styles';
import { Props } from './types';

const KeyFindings: FC<Props> = ({
  keyFindings,
  description,
  showLongevity,
  longevityTitle,
  ...rest
}) => (
  <Container {...rest}>
    <PrintArea dontBreak>
      <ReportTitle title="Key findings" />
      <Description>{description}</Description>
    </PrintArea>
    {keyFindings.map(
      ({ id, title, description: sectDescription, risk, blocks }) => (
        <KeyFinding key={id}>
          <PrintArea>
            <SectionTitle title={title} description={sectDescription}>
              <Rating risk={risk} />
            </SectionTitle>
          </PrintArea>
          <KeyFindingsBlocks blocks={blocks} blockType="findings" />
        </KeyFinding>
      ),
    )}
    {showLongevity && <Longevity title={longevityTitle} />}
  </Container>
);

export default KeyFindings;
