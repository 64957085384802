import DefaultPrintArea from 'components/PrintArea';
import DefaultReportTitle from 'components/ReportTitle';
import DefaultTextHighlight from 'components/TextHighlight';
import styled from 'styled-components';
import DefaultDocument from './Document';

export const Container = styled.div`
  padding: 9.875rem 0 10rem;
  background-color: ${({ theme }) => theme.colors.mediumIvory};

  @media print {
    page-break-before: always;
    padding: 3rem 0 2rem;
  }
`;

export const PrintArea = styled(DefaultPrintArea)`
  @media print {
    break-after: avoid;
  }
`;

export const Content = styled.div``;

export const ReportTitle = styled(DefaultReportTitle)`
  max-width: 45rem;
  margin-bottom: 6rem;
`;

export const Title = styled(DefaultTextHighlight)`
  margin-bottom: 4rem;
`;

export const Document = styled(DefaultDocument)`
  @media print {
    padding: 0 3rem;
    break-inside: avoid;
  }
`;
