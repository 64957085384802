import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  @media print {
    break-after: avoid;
  }
`;

export const Title = styled.h3`
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  font-weight: ${({ theme }) => theme.weights.medium};
  font-size: 1.5rem;
  justify-content: center;
  line-height: 1.4;
  text-align: center;
  width: 100%;
  :first-letter {
    text-transform: capitalize;
  }
  :after {
    content: '';
    border-top: 0.125rem solid ${({ theme }) => theme.colors.darkGray};
    margin: 0 0 0 1rem;
    flex: 1 0 1.5rem;
  }
`;
