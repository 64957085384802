import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg width={1376} height={504} viewBox="0 0 1376 504" fill="none" {...props}>
    <path
      d="M-145.038 309.116c.687.71 112.854 87.205 190.426 51.28 78.027-36.141 62.855-311.264 181.448-258.311 118.979 53.64 69.598 229.076 129.943 213.866 45.987-11.623 75.644-116.716 122.566-173.246 17.017-20.529 35.999-34.437 58.653-35.679 43.611-2.404 21.51 100.232 2.696 209.041-18.089 104.644-37.792 217.709 3.875 249.575 71.217 54.477 186.791-200.395 241.918-174.511 21.087 9.901 46.393 10.607 82.303 5.135 22.59-3.443 41.42-4.725 65.815-9.773 31.615-6.55 61.597-19.995 88.715-20.367 20.97-.304 38.65 5.055 50.2 13.196 58.99 41.624-48.76 340.073-23.63 355.873 25.26 15.874 67.5-137.849 112.4-130.151 44.8 7.842 8.5 108.182 67.79 125.252 28 8.06 83.26-47.272 136.85-101.831 62.54-63.73 125.56-125.508 146.94-94.775"
      stroke="#CDC3B0"
      strokeWidth={0.032}
      strokeMiterlimit={10}
    />
    <path
      d="M-138.221 289.872c1.372 1.411 110.51 85.535 186.086 51.735 76.524-34.226 62.668-299.989 177.622-247.475 116.21 53.089 68.425 224.102 128.562 208.891 47.568-12.1 77.519-118.46 124.692-173.5 17.069-19.981 35.81-32.784 58.032-32.916 42.774-.31 21.198 102.756 5.011 210.238-15.79 104.833-35.903 219.355 7.563 248.933 73.209 49.802 182.987-204.331 241.675-179.345 25.071 10.679 45.837 15.772 83.198 11.939 24.661-2.537 40.12.458 66.772-4.583 33.612-6.362 64.048-27.538 92.858-28.001 22.87-.408 41.45 7.404 51.86 15.853 54.2 43.985-48.96 331.273-25.3 347.096 23.89 15.972 64.67-133.143 107.8-125.189 43.03 7.944 9.09 103.038 65.11 122.817 27.15 9.585 83.64-46.496 137.05-100.513 62.08-62.921 125.5-121.216 147-91.216"
      stroke="#CDC3B0"
      strokeWidth={0.065}
      strokeMiterlimit={10}
    />
    <path
      d="M-131.425 270.612c2.063 2.13 108.166 83.874 181.731 52.207C125.341 290.524 112.652 34.383 224.1 86.24c113.327 52.723 67.241 219.106 127.183 203.87 49.137-12.572 79.398-120.222 126.82-173.78 17.136-19.44 35.619-31.122 57.409-30.137 41.943 1.802 20.929 105.314 7.336 211.442-13.453 105.05-34.075 221.035 11.266 248.288 75.101 45.155 179.339-208.54 241.434-184.219 29.121 11.41 45.27 21.074 84.093 18.773 26.738-1.585 38.807 5.672 67.731.642 35.62-6.185 66.508-35.132 97.028-35.697 24.77-.5 44.19 9.742 53.52 18.531 49.21 46.367-49.15 322.421-26.96 338.279 22.51 16.085 61.8-128.352 103.18-120.213 41.24 8.106 9.68 98.077 62.4 120.381 26.28 11.114 84.04-45.714 137.28-99.201 61.61-62.099 125.43-116.9 147.06-87.627"
      stroke="#CDC3B0"
      strokeWidth={0.097}
      strokeMiterlimit={10}
    />
    <path
      d="M-124.603 251.297c2.754 2.854 105.832 82.208 177.363 52.684 73.556-30.362 62.024-276.802 169.955-225.618 110.429 52.367 66.059 214.07 125.792 198.833 50.726-13.058 81.354-121.916 128.958-174.053 17.226-18.861 35.419-29.452 56.782-27.34 41.096 3.936 20.705 107.902 9.668 212.66-11.094 105.301-32.289 222.746 14.982 247.659 76.9 40.533 175.778-212.944 241.192-189.129 33.202 12.097 44.684 26.487 84.982 25.665 28.821-.588 37.496 10.908 68.686 5.913 37.629-6.022 68.973-42.766 101.203-43.434 26.68-.604 46.88 12.026 55.2 21.227 44.02 48.722-49.37 313.527-28.63 329.416 21.13 16.184 58.92-123.523 98.54-115.194 39.45 8.283 6.34 100.289 59.71 117.923 27.42 9.06 84.43-44.919 137.5-97.874 61.13-61.284 125.36-112.558 147.11-84.028"
      stroke="#CDC3B0"
      strokeWidth={0.129}
      strokeMiterlimit={10}
    />
    <path
      d="M-117.786 231.945c3.444 3.569 103.5 80.537 172.982 53.166 72.077-28.403 61.693-265.102 166.105-214.59 107.528 52.023 64.878 209.022 124.408 193.77 52.309-13.549 83.255-123.692 131.1-174.333 17.288-18.302 35.227-27.77 56.155-24.526 40.26 6.076 20.516 110.522 11.999 213.906-8.693 105.567-30.547 224.493 18.705 247.022 78.594 35.961 172.278-217.516 240.947-194.081 37.327 12.746 44.092 31.991 85.874 32.605 30.892.452 36.186 16.169 69.649 11.208 39.654-5.881 71.442-50.465 105.392-51.223 28.59-.71 52.13 11.843 56.87 23.942 50.81 36.098-49.58 304.573-30.3 320.503 19.72 16.294 56.04-118.663 93.9-110.161 37.64 8.462 5.89 97.115 56.98 115.463 27.1 9.736 84.89-44.035 137.72-96.539 60.73-60.358 125.29-108.194 147.17-80.396"
      stroke="#CDC3B0"
      strokeWidth={0.161}
      strokeMiterlimit={10}
    />
    <path
      d="M-110.974 212.573c4.14 4.303 101.179 78.887 168.596 53.641 70.626-26.437 61.368-253.33 162.235-203.5 104.61 51.68 63.684 203.948 123.011 188.681 53.89-14.033 85.156-125.474 133.244-174.636 17.349-17.74 35.034-26.078 55.525-21.694 39.423 8.23 20.378 113.162 14.342 215.156-6.256 105.861-28.868 226.296 22.431 246.402 80.2 31.429 168.819-222.208 240.698-199.061 41.492 13.353 43.489 37.571 86.78 39.583 32.975 1.528 34.874 21.465 70.608 16.551 41.684-5.728 73.924-58.217 109.594-59.08 30.51-.815 55.41 13.723 58.55 26.677 48.24 35.441-49.8 295.55-31.98 311.541 18.3 16.404 53.59-114.698 89.23-105.084 35.4 9.549 5.43 93.984 54.27 112.978 26.8 10.421 85.29-43.228 137.93-95.201 60.27-59.52 125.22-103.802 147.22-76.743"
      stroke="#CDC3B0"
      strokeWidth={0.194}
      strokeMiterlimit={10}
    />
    <path
      d="M-102.868 192.584c4.834 5.032 98.787 77.07 164.069 54.02 69.135-24.403 60.982-240.97 158.234-191.936 101.616 51.228 62.455 198.43 121.532 183.179 55.447-14.499 86.99-127.006 135.287-174.568 17.405-17.135 34.814-24.324 54.856-18.816 38.544 10.375 20.256 115.604 16.679 215.983-3.78 105.945-22.582 219.516 26.166 245.263 74.573 39.38 165.247-226.527 240.263-203.67 45.637 13.91 42.831 43.149 87.608 46.523 35.02 2.642 33.537 26.731 71.528 21.875 43.686-5.585 76.346-65.875 113.696-66.842 32.41-.909 58.67 15.567 60.2 29.38 45.63 34.71-50.02 285.874-33.63 301.898 16.85 16.477 50.73-109.719 84.5-99.779 33.47 9.856 4.93 90.731 51.5 110.264 26.48 11.104 85.64-42.328 138.04-93.671 59.76-58.547 125.07-99.177 147.18-72.925"
      stroke="#CDC3B0"
      strokeWidth={0.226}
      strokeMiterlimit={10}
    />
    <path
      d="M-94.41 172.462c5.523 5.745 96.392 75.221 159.5 54.366 67.645-22.346 60.574-228.425 154.194-180.23 98.597 50.743 61.202 192.777 120.015 177.551 56.983-14.96 88.812-128.477 137.298-174.409 17.447-16.521 34.546-22.493 54.168-15.908 37.604 12.627 20.187 117.968 19.02 216.669-1.249 105.964-20.693 219.852 29.887 243.983 75.655 36.095 158.491-223.995 239.762-208.168 51.821 10.09 42.16 48.739 88.417 53.448 37.052 3.769 32.183 31.991 72.425 27.197 45.674-5.43 78.754-73.523 117.804-74.592 34.29-1.014 61.9 17.421 61.81 32.059 43 33.953-50.21 276.001-35.27 292.049 15.39 16.525 47.27-103.433 79.73-94.391 32.15 8.952 4.41 87.491 48.72 107.462 26.17 11.796 85.96-41.401 138.12-92.072 59.24-57.545 124.86-94.484 147.08-69.045"
      stroke="#CDC3B0"
      strokeWidth={0.258}
      strokeMiterlimit={10}
    />
    <path
      d="M-85.948 152.348c6.217 6.473 93.998 73.379 154.926 54.709 66.181-20.277 60.155-215.85 150.153-168.51 95.568 50.27 59.957 187.109 118.49 171.919C396.15 195.047 428.238 80.532 476.93 36.241c17.49-15.906 34.316-20.717 53.478-12.995 36.71 14.784 20.161 120.326 21.372 217.345 1.311 105.983-18.943 220.301 33.625 242.676 76.823 32.704 154.965-228.271 239.264-212.658 56.061 10.379 41.467 54.356 89.229 60.37 39.074 4.92 30.842 37.245 73.314 32.535 47.678-5.296 81.168-81.177 121.898-82.337 36.19-1.118 65.14 19.263 63.44 34.742 40.37 33.194-50.43 266.066-36.91 282.158 13.92 16.566 44.32-98.197 74.96-89.003 30.33 9.1 3.87 84.303 45.96 104.664 25.86 12.505 86.27-40.457 138.18-90.464 58.71-56.542 124.66-89.777 146.98-65.168"
      stroke="#CDC3B0"
      strokeWidth={0.29}
      strokeMiterlimit={10}
    />
    <path
      d="M-77.477 132.25c6.91 7.202 91.615 71.559 150.35 55.037 64.731-18.199 59.736-203.227 146.09-156.75 92.53 49.8 58.701 181.414 116.975 166.254 60.058-15.868 92.427-131.393 141.308-174.029 17.532-15.289 34.07-18.927 52.787-10.077 35.819 16.932 20.19 122.664 23.709 218.013 3.914 105.993-17.34 220.897 37.354 241.346 78.069 29.205 151.455-232.622 238.758-217.136 60.282 10.69 40.77 60.001 90.044 67.309 41.083 6.098 29.484 42.514 74.207 37.867 49.665-5.152 83.575-88.834 125.995-90.097 38.09-1.223 68.38 21.119 65.07 37.425 37.75 32.433-50.66 256.091-38.54 272.244 12.44 16.58 41.37-92.958 70.2-83.597 28.48 9.26 3.28 81.199 43.17 101.839 25.56 13.228 86.58-39.524 138.25-88.846 58.18-55.531 124.47-85.054 146.88-61.267"
      stroke="#CDC3B0"
      strokeWidth={0.323}
      strokeMiterlimit={10}
    />
    <path
      d="M-68.928 112.157c7.6 7.915 89.247 69.742 145.756 55.366 63.289-16.1 59.308-190.54 142.024-144.945 89.493 49.325 57.456 175.668 115.443 160.548C395.885 166.801 428.52 50.31 477.607 9.317c17.574-14.667 33.837-17.14 52.092-7.154 34.928 19.093 20.25 124.977 26.052 218.63 6.56 105.977-21.009 233.04 41.077 239.979 86.559 9.673 147.977-237.03 238.236-221.577 64.49 11.037 40.055 65.651 90.842 74.241 43.085 7.287 28.13 47.768 75.094 43.208 51.66-5.018 85.97-96.471 130.07-97.838 39.97-1.316 71.62 22.959 66.69 40.104 35.11 31.662-50.9 246.012-40.17 262.265 10.93 16.573 38.41-87.694 65.4-78.16 26.65 9.406 2.68 78.14 40.38 99.004 25.27 13.978 86.89-38.58 138.32-87.222 57.64-54.511 124.24-80.314 146.77-57.363"
      stroke="#CDC3B0"
      strokeWidth={0.355}
      strokeMiterlimit={10}
    />
    <path
      d="M-60.342 92.066c8.292 8.643 86.871 67.942 141.155 55.689 61.874-13.976 58.87-177.81 137.95-133.116 86.438 48.853 56.194 169.91 113.907 154.821C395.798 152.681 428.685 35.22 477.965-4.1c17.605-14.046 33.592-15.353 51.4-4.24 34.032 21.239 20.358 127.262 28.395 219.222 9.254 105.936-19.559 234.463 44.814 238.577 87.618 5.611 144.507-241.466 237.703-225.999 68.693 11.396 39.333 71.311 91.643 81.159 45.08 8.488 26.78 53.008 75.98 48.539 53.66-4.884 88.38-104.115 134.16-105.573 41.86-1.421 74.85 24.812 68.31 42.78 32.47 30.889-51.16 235.868-41.81 252.251 9.44 16.537 35.47-82.415 60.64-72.72 24.81 9.553 2.03 75.154 37.6 96.146 24.97 14.741 87.18-37.633 138.37-85.574 57.1-53.484 124.03-75.559 146.65-53.435"
      stroke="#CDC3B0"
      strokeWidth={0.387}
      strokeMiterlimit={10}
    />
    <path
      d="M-51.73 71.999c8.985 9.37 84.512 66.18 136.548 56.007 60.469-11.832 58.433-165.04 133.871-121.267 83.387 48.378 54.938 164.132 112.374 149.078 64.65-17.222 97.806-135.639 147.283-173.291 17.65-13.434 33.35-13.55 50.7-1.312 33.135 23.383 20.504 129.518 30.732 219.804 11.996 105.869-18.127 235.924 48.536 237.157 88.634 1.631 141.043-245.929 237.161-230.398 72.882 11.777 38.607 76.992 92.435 88.083 47.068 9.701 25.432 58.26 76.86 53.878 55.64-4.75 90.77-111.753 138.24-113.312 43.75-1.526 78.08 26.649 69.93 45.453 29.85 30.101-51.42 225.651-43.44 242.204 7.94 16.462 32.51-77.114 55.84-67.26 22.97 9.712 1.35 72.23 34.8 93.287 24.67 15.529 87.48-36.682 138.41-83.918 56.56-52.451 123.81-70.794 146.53-49.503"
      stroke="#CDC3B0"
      strokeWidth={0.419}
      strokeMiterlimit={10}
    />
    <path
      d="M-43.108 51.953c9.674 10.082 82.151 64.452 131.941 56.308 59.079-9.656 57.98-152.242 129.778-109.405 80.322 47.923 53.672 158.337 110.83 143.317 66.184-17.673 99.591-137.04 149.272-173.02 17.689-12.807 33.102-11.758 49.998 1.618 32.237 25.527 20.697 131.75 33.074 220.356 14.783 105.764-16.728 237.44 52.273 235.71 89.62-2.251 137.602-250.435 236.611-234.782 77.068 12.184 39.393 79.446 93.234 95.005 47.694 13.772 24.079 63.496 77.747 59.203 57.63-4.612 93.16-119.373 142.31-121.034 45.63-1.618 81.31 28.499 71.54 48.124 27.21 29.321-48.35 213.035-45.07 232.135 9.51 13.536 29.57-71.814 51.05-61.805 21.15 9.861.64 69.372 32.02 90.409 24.38 16.332 87.78-35.739 138.46-82.253 56.01-51.413 123.58-66.023 146.4-45.576"
      stroke="#CDC3B0"
      strokeWidth={0.452}
      strokeMiterlimit={10}
    />
    <path
      d="M-34.457 31.922C-24.09 42.73 45.346 94.67 92.868 88.537c57.701-7.448 57.513-139.404 125.689-97.53 77.263 47.467 52.413 152.53 109.291 137.546 67.708-18.125 101.363-138.43 151.241-172.722 17.718-12.183 32.864-9.964 49.294 4.546 31.339 27.67 20.919 133.952 35.417 220.89 17.607 105.632-15.345 239.011 55.992 234.25 90.574-6.047 136.669-260.729 236.054-239.145 79.241 17.208 38.611 85.251 94.026 101.906 49.676 14.929 22.721 68.738 78.618 64.523 59.63-4.489 95.56-126.995 146.39-128.758 47.52-1.723 84.54 30.332 73.15 50.789 24.57 28.536-48.28 202.722-46.7 222.042 8.27 13.346 26.63-66.504 46.26-56.328 19.33 10.021-.13 66.588 29.22 87.532 24.07 17.168 88.07-34.782 138.49-80.582 55.46-50.373 123.35-61.244 146.27-41.634"
      stroke="#CDC3B0"
      strokeWidth={0.484}
      strokeMiterlimit={10}
    />
    <path
      d="M-25.796 11.92c11.059 11.533 77.449 61.093 122.705 56.92 56.333-5.196 57.044-126.555 121.596-85.646 74.188 47.016 51.153 146.709 107.74 131.76 69.233-18.562 103.141-139.809 153.214-172.41 17.748-11.557 32.615-8.17 48.59 7.476 30.448 29.811 21.185 136.112 37.752 221.416 20.486 105.465-13.979 240.625 59.717 232.759 91.508-9.765 133.09-264.983 235.483-243.477 83.462 17.529 37.817 91.074 94.81 108.811 51.669 16.088 21.367 73.964 79.489 69.85 61.62-4.353 97.95-134.607 150.45-136.462 49.4-1.827 87.76 32.178 74.76 53.452 21.93 27.753-48.23 192.403-48.33 211.931 7.02 13.168 23.7-61.189 41.46-50.861 17.52 10.17-.92 63.871 26.44 84.636 23.75 18.031 88.35-33.821 138.53-78.915 54.91-49.339 123.12-56.471 146.13-37.699"
      stroke="#CDC3B0"
      strokeWidth={0.516}
      strokeMiterlimit={10}
    />
    <path
      d="M-17.113-8.06C-5.365 4.181 57.975 51.425 100.97 49.16c54.972-2.902 56.561-113.675 117.499-73.758 71.105 46.573 49.883 140.876 106.187 125.966 70.752-19.012 104.907-141.18 155.18-172.1 17.79-10.94 32.374-6.374 47.884 10.404 29.547 31.95 21.479 138.244 40.092 221.916 23.404 105.247-12.606 242.29 63.443 231.266 92.442-13.401 129.522-269.227 234.912-247.803 87.701 17.827 37.007 96.899 95.592 115.706 53.661 17.221 20.013 79.184 80.361 75.16 63.61-4.228 100.33-142.196 154.51-144.153 51.29-1.932 90.98 34.006 76.36 56.122 19.29 26.966-48.17 182.071-49.94 201.809 5.78 12.976 20.76-55.855 36.67-45.377 15.71 10.347-6.49 68.395 23.64 81.749 18.34 24.979 88.64-32.873 138.56-77.232 54.35-48.29 122.88-51.685 145.99-33.75"
      stroke="#CDC3B0"
      strokeWidth={0.548}
      strokeMiterlimit={10}
    />
    <path
      d="M-8.423-28.013C4.017-15.047 64.313 29.906 105.04 29.49c53.606-.554 56.067-100.765 113.392-61.869 68.018 46.154 48.626 135.026 104.639 120.167 72.27-19.46 106.676-142.552 157.145-171.763 17.819-10.312 32.18-4.634 47.192 13.32 28.733 33.986 21.818 140.337 42.431 222.4 26.374 104.995-11.245 243.99 67.165 229.743 93.362-16.97 125.947-273.465 234.334-252.11 91.934 18.108 34.849 105.375 96.386 122.583 56.886 15.917 18.664 84.383 81.236 80.476 65.6-4.104 102.71-149.786 158.57-151.845 53.18-2.021 94.21 35.847 77.97 58.778 16.66 26.178-48.1 171.731-51.56 191.673 4.54 12.783 17.84-50.516 31.87-39.906 13.92 10.513-7.33 65.565 20.85 78.842 17.7 26.039 88.91-31.91 138.58-75.544 53.79-47.253 122.64-46.895 145.86-29.81"
      stroke="#CDC3B0"
      strokeWidth={0.581}
      strokeMiterlimit={10}
    />
    <path
      d="M.291-47.941C13.421-34.253 70.647 8.48 109.126 9.853c52.233 1.87 55.565-87.841 109.291-49.978C283.341 5.618 265.771 89.052 321.498 74.233 395.292 54.33 429.939-69.679 480.6-97.195c17.851-9.696 31.928-2.84 46.483 16.246 27.84 36.118 22.183 142.383 44.763 222.879 29.385 104.69-9.872 245.753 70.879 228.215 94.294-20.48 122.37-277.682 233.751-256.398 96.168 18.377 34.081 111.045 97.162 129.458 58.822 17.181 17.309 89.587 82.112 85.771 67.57-3.979 105.09-157.362 162.62-159.51 55.05-2.125 97.42 37.669 79.57 61.426 14.02 25.387-48.04 161.381-53.18 181.528 3.31 12.592 14.91-45.15 27.07-34.434 12.13 10.692-8.18 62.74 18.06 75.945 17.07 27.11 89.19-30.955 138.61-73.85 53.24-46.21 122.4-42.103 145.7-25.859"
      stroke="#CDC3B0"
      strokeWidth={0.613}
      strokeMiterlimit={10}
    />
    <path
      d="M9.012-67.857C22.829-53.463 76.984-12.882 113.218-9.775c50.839 4.356 55.046-74.884 105.189-38.092C280.216-2.515 264.497 75.46 319.928 60.681 395.23 40.344 430.132-84.585 480.986-110.39c17.884-9.079 31.685-1.045 45.775 19.168 26.937 38.247 22.567 144.4 47.099 223.334 32.43 104.351-8.499 247.545 74.605 226.668 95.247-23.927 118.782-281.885 233.163-260.67 100.406 18.632 33.314 116.706 97.937 136.321 60.765 18.445 15.955 94.785 82.975 91.059 69.56-3.854 107.46-164.913 166.67-167.177 56.93-2.228 100.63 39.503 81.18 64.07 11.37 24.596-47.98 151.03-54.81 171.378 2.06 12.41 12.01-39.78 22.28-28.948 10.35 10.902-9.03 59.902 15.27 73.031 16.44 28.18 89.46-30.001 138.63-72.166 52.67-45.167 122.15-37.311 145.54-21.91"
      stroke="#CDC3B0"
      strokeWidth={0.645}
      strokeMiterlimit={10}
    />
    <path
      d="M17.747-87.742C32.255-72.63 87.9-43.358 117.324-29.375c42.76 20.313 54.51-61.922 101.085-26.211 58.692 44.985 44.816 117.472 99.968 102.736C395.2 26.372 430.347-99.47 481.379-123.555c17.911-8.451 31.442.749 45.065 22.087 26.044 40.375 22.986 146.377 49.435 223.777 35.529 103.951-7.118 249.389 78.32 225.105 96.213-27.34 115.211-286.09 232.569-264.924 104.648 18.874 32.532 122.366 98.716 143.161 62.696 19.704 14.606 99.961 83.836 96.35 71.54-3.73 109.84-172.463 170.71-174.816 58.81-2.332 103.84 41.319 82.78 66.709 8.74 23.805-47.91 140.663-56.43 161.225.83 12.216 9.1-34.37 17.49-23.479 8.57 11.137-9.88 57.063 12.47 70.127 15.8 29.248 89.73-29.048 138.65-70.465 52.11-44.123 121.9-32.523 145.39-17.973"
      stroke="#CDC3B0"
      strokeWidth={0.677}
      strokeMiterlimit={10}
    />
    <path
      d="M26.487-107.608c15.198 15.832 67.743 43.63 94.942 58.634 41.01 22.62 53.957-48.93 96.972-14.34 55.548 44.65 43.551 111.616 98.405 96.922 78.332-21.22 113.718-147.958 164.956-170.341 17.943-7.835 31.192 2.553 44.355 25.003 25.135 42.507 23.424 148.321 51.771 224.22 38.653 103.513-5.708 251.265 82.036 223.546 97.197-30.704 111.625-290.266 231.971-269.16 108.895 19.103 31.764 128.005 99.495 150.001 64.62 20.975 13.26 105.127 84.7 101.619 73.53-3.616 112.21-179.996 174.75-182.438 60.68-2.423 107.05 43.145 84.37 69.34 6.1 23-47.85 130.308-58.05 151.071-.41 12.02 6.19-28.926 12.69-18.002 6.8 11.401-10.73 54.235 9.68 67.212 15.18 30.313 90-28.093 138.67-68.764 51.54-43.077 121.65-27.734 145.23-14.027"
      stroke="#CDC3B0"
      strokeWidth={0.71}
      strokeMiterlimit={10}
    />
    <path
      d="M35.242-127.453c15.883 16.534 65.214 43.059 90.312 58.912 39.323 24.832 53.376-35.912 92.868-2.476 52.377 44.347 42.276 105.76 96.849 91.111C395.115-1.553 430.74-129.207 482.173-149.864c17.975-7.219 30.939 4.341 43.65 27.903 24.241 44.627 23.88 150.223 54.104 224.642 41.83 103.017-4.289 253.174 85.746 221.966 98.203-34.031 108.053-294.44 231.37-273.385 113.127 19.317 30.976 133.658 100.264 156.83 66.543 22.254 11.903 110.297 85.573 106.895 75.5-3.492 114.57-187.506 178.78-190.06 62.56-2.527 110.26 44.954 85.97 71.968 3.46 22.206-47.78 119.959-59.65 140.922-1.65 11.825 3.24-23.424 7.9-12.537 5.02 11.727-11.57 51.394 6.89 64.307 14.53 31.373 90.26-27.14 138.67-67.061 50.97-42.032 121.41-22.948 145.09-10.093"
      stroke="#CDC3B0"
      strokeWidth={0.742}
      strokeMiterlimit={10}
    />
    <path
      d="M44.013-147.266c16.573 17.249 62.529 42.648 85.682 59.187 37.729 26.93 52.778-22.869 88.763 9.377 49.191 44.078 41.016 99.894 95.283 85.299 81.35-22.087 117.233-150.644 168.835-169.57 18.012-6.615 30.694 6.131 42.939 30.812 23.347 46.743 24.361 152.08 56.434 225.05 45.028 102.469-2.845 255.1 89.447 220.386 99.238-37.331 104.471-298.593 230.755-277.59 117.369 19.531 30.193 139.284 101.039 163.629 68.45 23.531 10.6 115.282 86.43 112.146 77.53-3.529 116.94-195.01 182.81-197.653 64.43-2.629 113.46 46.774 87.57 74.59.83 21.415-47.72 109.612-61.27 130.773-2.89 11.63.26-17.868 3.11-7.067 3.2 12.125-12.42 48.554 4.08 61.4 13.91 32.422 90.54-26.195 138.69-65.356 50.41-40.996 121.14-18.168 144.92-6.153"
      stroke="#CDC3B0"
      strokeWidth={0.774}
      strokeMiterlimit={10}
    />
    <path
      d="M52.781-167.066c17.252 17.961 55.577 48.7 81.051 59.46 43.186 18.243 52.155-9.806 84.66 21.217C264.45-42.527 258.233 7.651 312.208-6.9c82.855-22.526 118.98-151.97 170.78-169.185 18.051-5.997 30.448 7.917 42.226 33.716 22.445 48.852 24.854 153.907 58.758 225.461 48.272 101.863-1.381 257.061 93.162 218.791C777.444 261.28 778.018-.864 907.279 20.103c121.611 19.731 29.405 144.913 101.801 170.429 70.37 24.805 9.25 120.446 87.29 117.399 79.49-3.392 119.3-202.499 186.83-205.244 66.3-2.733 116.66 48.575 89.16 77.206-1.81 20.621-47.65 99.27-62.88 120.617-4.13 11.446-2.78-12.228-1.67-1.612 1.33 12.585-10.05 42.029 1.31 58.485 19.31 28.011 90.79-25.241 138.69-63.664 49.84-39.962 120.88-13.39 144.75-2.215"
      stroke="#CDC3B0"
      strokeWidth={0.806}
      strokeMiterlimit={10}
    />
    <path
      d="M61.554-186.833c17.946 18.661 52.961 47.605 76.428 59.718 41.391 21.363 51.495 3.275 80.553 33.03 42.689 43.703 38.475 88.19 92.153 73.697 84.36-22.948 120.743-153.312 172.705-168.785 18.083-5.382 30.203 9.702 41.515 36.616 21.551 50.959 25.363 155.69 61.086 225.853 51.538 101.219.111 259.031 96.858 217.2C784.254 246.643 780.153-16.376 912.38 4.552c125.84 19.918 28.616 150.53 102.58 177.218 72.27 26.077 7.89 125.598 88.14 122.629 81.47-3.252 121.66-209.972 190.85-212.806 68.17-2.823 119.86 50.387 90.76 79.818-4.45 19.826-50.04 87.226-64.49 110.48-6.68 10.747-5.93-6.551-6.46 3.835-.67 13.123-11.29 39.818-1.49 55.58 18.39 29.533 91.04-24.301 138.7-61.956 49.27-38.928 120.63-8.617 144.59 1.704"
      stroke="#CDC3B0"
      strokeWidth={0.839}
      strokeMiterlimit={10}
    />
    <path
      d="M70.334-206.588c18.632 19.373 50.27 46.557 71.799 59.987 39.465 24.606 50.789 16.396 76.451 44.842 39.344 43.617 37.2 82.343 90.584 67.894 85.861-23.384 122.491-154.636 174.644-168.389 18.119-4.779 29.948 11.482 40.803 39.511 20.648 53.061 25.882 157.442 63.406 226.248 54.832 100.514 1.623 261.035 100.557 215.591 102.537-47.084 93.724-310.992 228.913-290.105C1047.58 9.097 945.309 145.124 1020.83 172.97c74.18 27.359 6.54 130.74 89.01 127.851 83.42-3.104 124.01-217.42 194.87-220.352 70.03-2.926 123.05 52.18 92.34 82.434-7.09 19.035-49.33 76.985-66.11 100.352-7.53 10.498-9.16-.876-11.24 9.287-2.8 13.653-12.6 37.646-4.27 52.668 17.27 31.192 91.3-23.36 138.7-60.252 48.69-37.893 120.36-3.851 144.42 5.633"
      stroke="#CDC3B0"
      strokeWidth={0.871}
      strokeMiterlimit={10}
    />
    <path
      d="M79.121-226.326c19.31 20.08 47.514 45.546 67.173 60.253 37.39 27.967 50.042 29.544 72.35 56.638 35.91 43.616 35.934 76.503 89.016 62.098 87.369-23.816 124.251-155.965 176.565-167.98 18.149-4.166 29.702 13.262 40.091 42.402 19.749 55.172 26.403 159.162 65.729 226.625 58.156 99.761 3.171 263.039 104.258 213.993C798.007 217.386 784.442-47.4 922.598-26.55 1056.92-6.272 949.629 135.178 1026.7 164.187c76.08 28.638 5.19 135.874 89.86 133.074 85.4-2.965 126.37-224.863 198.88-227.896 71.9-3.027 126.25 53.986 93.92 85.033-9.71 18.242-48.66 66.927-67.71 90.233-8.45 10.343-12.45 4.71-16.02 14.722-5.03 14.103-13.94 35.465-7.06 49.767 15.9 33.038 91.55-22.421 138.7-58.544 48.12-36.861 120.11.911 144.26 9.543"
      stroke="#CDC3B0"
      strokeWidth={0.903}
      strokeMiterlimit={10}
    />
    <path
      d="M87.902-246.03c20.002 20.777 44.693 44.546 62.548 60.518 35.144 31.428 49.23 42.726 68.25 68.418 32.366 43.72 34.66 70.667 87.457 56.31 88.867-24.25 126.015-157.368 178.492-167.566 18.187-3.591 29.45 15.051 39.385 45.276 18.846 57.265 26.941 160.84 68.052 226.997 61.499 98.959 4.739 265.054 107.951 212.379 104.891-53.525 86.569-319.212 227.677-298.39 138.556 20.435 26.234 167.309 104.866 197.483 77.98 29.915 3.84 140.986 90.72 138.278 87.36-2.827 128.72-232.292 202.88-235.415 73.77-3.13 126.59 51.838 95.51 87.628-13.92 16.028-48.09 57.007-69.31 80.127-9.42 10.266-15.75 10.179-20.81 20.161-7.31 14.419-15.35 33.282-9.85 46.857 14.21 35.068 91.79-21.566 138.71-56.853 47.53-35.913 119.85 5.668 144.09 13.463"
      stroke="#CDC3B0"
      strokeWidth={0.935}
      strokeMiterlimit={10}
    />
    <path
      d="M96.69-265.718c20.689 21.483 41.804 43.54 57.931 60.768 32.74 34.964 48.337 55.935 64.143 80.179 28.645 43.963 33.394 64.838 85.889 50.525 90.366-24.669 127.762-158.648 180.412-167.162 18.217-2.978 29.205 16.826 38.673 48.157 17.954 59.357 27.475 162.498 70.368 227.374 64.863 98.108 6.328 267.078 111.646 210.775 106.123-56.743 83.072-323.502 227.055-302.515 142.873 20.403 25.38 173 105.643 204.207 79.8 31.307 2.49 146.1 91.56 143.481 89.32-2.676 131.07-239.693 206.9-242.927 75.62-3.22 131.36 55.532 97.09 90.216-15.61 15.806-47.62 47.181-70.92 70.031-10.46 10.253-19 15.519-25.57 25.582-9.54 14.586-16.82 31.061-12.63 43.964 12.11 37.311 92.04-20.6 138.71-55.148 46.96-34.851 119.58 10.415 143.92 17.363"
      stroke="#CDC3B0"
      strokeWidth={0.968}
      strokeMiterlimit={10}
    />
    <path
      d="M105.477-285.386C258.06-127.013 211.301-62.583 303.162-87.682c91.861-25.1 129.509-159.923 182.335-166.741 52.826-6.818 42.4 181.56 110.648 278.768 68.248 97.208 7.953 269.104 115.335 209.156 107.381-59.948 79.407-327.383 226.432-306.628 147.028 20.754 24.644 178.454 106.398 210.926 81.75 32.473 1.14 151.204 92.42 148.664 91.28-2.54 133.41-247.093 210.89-250.414 77.49-3.321 135.8 59.35 98.68 92.8-37.12 33.451-127.76 92.245-118.29 132.011 9.47 39.766 92.31-19.645 138.71-53.447 46.4-33.802 119.32 15.159 143.74 21.27"
      stroke="#CDC3B0"
      strokeMiterlimit={10}
    />
  </svg>
));
