import PrintArea from 'components/PrintArea';
import React, { FC } from 'react';
import Behavior from './Behavior';
import { Container, Description, List, ListPrintArea, Title } from './styles';
import { Props } from './types';

const BehaviorList: FC<Props> = ({ behaviors, description, ...rest }) => (
  <Container {...rest}>
    <PrintArea dontBreak>
      <Title title="Longevity Behaviors" />
      <Description text={description} />
    </PrintArea>
    <ListPrintArea>
      {behaviors && behaviors.length > 0 && (
        <List>
          {behaviors?.map(({ id, name }) => (
            <Behavior key={id} name={name} />
          ))}
        </List>
      )}
    </ListPrintArea>
  </Container>
);

export default BehaviorList;
