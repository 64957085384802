import React, { FC } from 'react';
import RowA from './RowA';
import {
  Body,
  Container,
  Head,
  HeadCell,
  HeadTitle,
  Table,
  TableRow,
  Title,
} from './styles';
import { Props } from './types';

const tableHeaders = ['Examination', '', 'Finding', 'Comments'];

const PanelA: FC<Props> = ({
  headers = tableHeaders,
  rows,
  title,
  ...rest
}) => (
  <Container {...rest}>
    <Title>{title}</Title>
    <Table>
      <Head>
        <TableRow>
          {headers.map(t => (
            <HeadCell key={t}>
              <HeadTitle>{t}</HeadTitle>
            </HeadCell>
          ))}
        </TableRow>
      </Head>
      <Body>
        {rows.map(row => (
          <RowA key={row.id} {...row} />
        ))}
      </Body>
    </Table>
  </Container>
);

export default PanelA;
