import React, { FC } from 'react';
import { Props } from './types';

const PentagonGraph: FC<Props> = ({ value, ...rest }) => (
  <svg width={138} height={142} viewBox="0 0 138 142" {...rest}>
    <g fill="none" fillRule="evenodd">
      {value >= 3 && (
        <path
          fill="#7EB85B"
          fillRule="nonzero"
          opacity={0.6}
          d="M70 78l36 50H34z"
        />
      )}
      {value >= 4 && (
        <path
          fill="#7EB85B"
          fillRule="nonzero"
          opacity={0.8}
          d="M70.63 78.583l-36.074 49.418-22.158-68.194z"
        />
      )}
      {value === 5 && (
        <path
          fill="#72A553"
          fillRule="nonzero"
          opacity={0.8}
          d="M70.684 79.053L12.537 60.016l58.01-42.146z"
        />
      )}
      {value >= 2 && (
        <path
          fill="#7EB85B"
          fillRule="nonzero"
          opacity={0.4}
          d="M70.373 78.533l58.223-18.723-22.125 68.093z"
        />
      )}
      {value >= 1 && (
        <path
          fill="#7EB85B"
          fillRule="nonzero"
          opacity={0.2}
          d="M70.417 78.99l.186-61.16 57.922 42.084z"
        />
      )}
      <path
        stroke="#A2A09F"
        d="M12.588 59.59L70.5 17.617l57.912 41.972-22.12 67.911H34.708z"
      />
      <path
        d="M67.961 6.804c.156 1.488 1.152 2.292 2.544 2.292.804 0 1.44-.264 1.92-.78.48-.528.72-1.212.72-2.052 0-.84-.228-1.524-.684-2.04-.456-.528-1.08-.792-1.884-.792-.564 0-1.02.192-1.38.564l.312-2.436h3.132V.6h-4.008l-.456 4.584.636.144c.468-.624 1.032-.936 1.692-.936 1.008 0 1.608.732 1.608 1.872 0 1.14-.6 1.872-1.608 1.872-.852 0-1.416-.528-1.524-1.524l-1.02.192zM135.132 52.908v1.104l1.548-.9V60h.984v-8.4h-.372zM115.018 141v-.96h-3.444l2.616-3.384c.444-.588.672-1.188.672-1.824 0-.66-.18-1.2-.528-1.596-.432-.492-1.044-.732-1.824-.732-.648 0-1.2.216-1.656.636-.444.42-.696.948-.732 1.572l1.008.192c.084-.864.6-1.44 1.38-1.44.444 0 .78.132 1.008.408.204.252.312.564.312.96 0 .528-.156.924-.684 1.596l-3.204 4.104V141h5.076zM27.763 138.696c.072.72.36 1.296.864 1.74.504.444 1.104.66 1.812.66.804 0 1.452-.252 1.956-.756.504-.516.756-1.176.756-1.98 0-.768-.216-1.392-.636-1.86-.42-.48-.948-.72-1.596-.72a1.13 1.13 0 00-.336.036l2.136-2.892v-.324h-4.56v.96h3.036l-1.92 2.616.42.564c.216-.108.468-.156.744-.156 1.008 0 1.68.66 1.68 1.776s-.672 1.776-1.68 1.776c-.96 0-1.512-.528-1.668-1.572l-1.008.132zM4.874 62v-1.788h1.044v-.96H4.874V53.6H3.746L.194 59.792v.42h3.648V62h1.032zm-1.032-6.816v4.068h-2.28l2.28-4.068z"
        fill="#A2A09F"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default PentagonGraph;
