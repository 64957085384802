import DefaultFooter from 'components/Footer';
import DefaultResultsHeader from 'components/ResultsHeader';
import DefaultResultBlocks from 'components/ResultBlocks';
import styled from 'styled-components';

export const Container = styled.div``;

export const ResultsHeader = styled(DefaultResultsHeader)`
  @media print {
    break-inside: avoid;
    overflow: hidden;
  }
`;

export const ResultBlocks = styled(DefaultResultBlocks)``;

export const Footer = styled(DefaultFooter)`
  margin-top: 3.5rem;
`;
