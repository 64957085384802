import { ElementProps } from 'utils/ElementProps';

export enum Risk {
  LOW = 'Low risk',
  INCREASED = 'Increased risk',
  HIGH = 'High risk',
}
export interface Props extends ElementProps<'div'> {
  risk: Risk;
}
