import React, { FC } from 'react';
import { HighlightedBar, Line, Polygon, RangeText, Svg } from './styles';
import { Props } from './types';

const TRIANGLE_Y = -5;
const TRIANGLE_SIZE = 1.5;

const PanelAChartBar: FC<Props> = ({
  value = 0,
  min = 0,
  max = 100,
  color,
  lowerBound,
  upperBound,
  ...rest
}) => {
  const onChartMaxValue =
    max > 100 || min > 0 ? ((value - min) / (max - min)) * 100 : value;
  const onChartLowerBound =
    lowerBound && (max > 100 || min > 0)
      ? (Math.abs(min - lowerBound) / Math.abs(min - max)) * 100
      : lowerBound;
  const onChartUpperBound =
    upperBound && (max > 100 || min > 0)
      ? ((upperBound - min) / (max - min)) * 100
      : upperBound;

  return (
    <Svg viewBox="0 0 100 1" {...rest}>
      {onChartLowerBound !== undefined && onChartUpperBound !== undefined ? (
        <>
          <Polygon
            fill="currentColor"
            points={`
        ${onChartLowerBound - TRIANGLE_SIZE}, ${TRIANGLE_Y}
        ${onChartLowerBound + TRIANGLE_SIZE}, ${TRIANGLE_Y}
        ${onChartLowerBound}, ${TRIANGLE_Y + TRIANGLE_SIZE}`}
          />
          <Polygon
            fill="currentColor"
            points={`
        ${onChartUpperBound - TRIANGLE_SIZE}, ${TRIANGLE_Y}
        ${onChartUpperBound + TRIANGLE_SIZE}, ${TRIANGLE_Y}
        ${onChartUpperBound}, ${TRIANGLE_Y + TRIANGLE_SIZE}`}
          />
        </>
      ) : (
        <Polygon
          fill="currentColor"
          points={`
        ${onChartMaxValue - TRIANGLE_SIZE}, ${TRIANGLE_Y}
        ${onChartMaxValue + TRIANGLE_SIZE}, ${TRIANGLE_Y}
        ${onChartMaxValue}, ${TRIANGLE_Y + TRIANGLE_SIZE}`}
        />
      )}
      <Line
        stroke="#ECE9E6"
        strokeWidth={0.65}
        x1={0}
        y1={0.5}
        x2={100}
        y2={0.5}
      />
      {onChartLowerBound !== undefined && onChartUpperBound !== undefined ? (
        <HighlightedBar
          stroke={color}
          strokeWidth={1.5}
          x1={onChartLowerBound}
          y1={0.5}
          x2={onChartUpperBound}
          y2={0.5}
        />
      ) : (
        <HighlightedBar
          stroke={color}
          strokeWidth={1.5}
          x1={0}
          y1={0.5}
          x2={onChartMaxValue}
          y2={0.5}
        />
      )}
      <RangeText
        dominantBaseline="middle"
        fill="currentColor"
        textAnchor="middle"
        x={0}
        y={6}
      >
        {min}
      </RangeText>
      {max && (
        <RangeText
          dominantBaseline="middle"
          fill="currentColor"
          textAnchor="end"
          x={max <= 100 ? max : 100}
          y={6}
        >
          {max}
        </RangeText>
      )}
    </Svg>
  );
};

export default PanelAChartBar;
