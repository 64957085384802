import React, { FC } from 'react';
import ReportTitle from 'components/ReportTitle';
import {
  Bar,
  BiologicalAgeContainer,
  BiologicalAgeIndexContainer,
  Container,
  Content,
  Description,
  DescriptionText,
  Graph,
  GraphContent,
  Info,
  Subtitle,
  Title,
  Value,
} from './styles';
import { Props } from './types';

const BiologicalAge: FC<Props> = ({ age, calendar, ageIndex, ...rest }) => (
  <Container {...rest}>
    <ReportTitle title="Your Biological age" />
    <Content>
      <DescriptionText>
        Biological age index shows you are in the {ageIndex}th percentile of
        your age, it means you are younger than {ageIndex}% of people your age.
      </DescriptionText>
      <GraphContent>
        {age > 0 && calendar > 0 && (
          <BiologicalAgeContainer>
            <Info>
              <Value>{age}</Value>
              <Description>
                <Title>Biological age</Title>
                <Subtitle>vs. calendar age of {calendar}</Subtitle>
              </Description>
            </Info>
            <Graph>
              <Bar
                rangeMin={0}
                rangeMax={age + 20}
                highlightStart={0}
                highlightEnd={calendar}
                valuePosition={age}
              />
            </Graph>
          </BiologicalAgeContainer>
        )}
        {ageIndex > 0 && (
          <BiologicalAgeIndexContainer>
            <Info>
              <Value>{ageIndex}%</Value>
              <Description>
                <Title>Biological age index</Title>
                <Subtitle>percentile</Subtitle>
              </Description>
            </Info>
            <Graph>
              <Bar
                type="percentage"
                rangeMin={0}
                rangeMax={ageIndex + 20}
                highlightStart={0}
                highlightEnd={ageIndex}
                valuePosition={ageIndex}
              />
            </Graph>
          </BiologicalAgeIndexContainer>
        )}
      </GraphContent>
    </Content>
  </Container>
);

export default BiologicalAge;
