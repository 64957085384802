import DefaultLongevity from 'components/Longevity';
import DefaultSectionTitle from 'components/SectionTitle';
import styled from 'styled-components';

export const Container = styled.section`
  padding-top: 10rem;

  @media print {
    padding-top: 4rem;
  }
`;

export const KeyFinding = styled.div``;

export const SectionTitle = styled(DefaultSectionTitle)`
  margin-bottom: 4rem;
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.darkerGray};
  font-weight: ${({ theme }) => theme.weights.medium};
  font-size: 2.5rem;
  line-height: 140%;
  padding: 4rem 0 6rem;
  max-width: 45rem;

  @media print {
    font-size: ${({ theme }) => theme.fontSize.printHeader};
  }
`;

export const Longevity = styled(DefaultLongevity)`
  @media print {
    break-before: page;
  }
`;
