import { ImageBlock as DefaultImageBlock, ReportTitle } from 'components';
import DefaultPanelA from 'components/PanelA';
import DefaultPanelB from 'components/PanelB';
import DefaultPanelC from 'components/PanelC';
import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const SectionTitle = styled(ReportTitle)`
  margin: 10rem 0 6rem;

  @media print {
    break-before: page;
    margin-top: 0;
  }
`;

const panelStyles = css`
  margin-top: 3.5rem;

  @media print {
    break-inside: avoid;
  }
`;

export const PanelA = styled(DefaultPanelA)`
  ${panelStyles}
`;

export const PanelB = styled(DefaultPanelB)`
  ${panelStyles}
`;

export const PanelC = styled(DefaultPanelC)`
  ${panelStyles}
`;

export const Images = styled.div`
  margin-top: 3.5rem;
`;

export const ImageBlock = styled(DefaultImageBlock)`
  :not(:last-child) {
    margin-bottom: 2rem;
  }

  @media print {
    break-inside: avoid;
  }
`;
