import React, { FC } from 'react';
import {
  Container,
  Title,
  Chart,
  BarContainer,
  SimpleDataBlockBar,
  Value,
} from './styles';
import { Props } from './types';

const SimpleDataBlockChart: FC<Props> = ({
  id,
  name,
  value,
  min,
  max,
  label,
  ...rest
}) => (
  <Container {...rest}>
    <Title>{name}</Title>
    <Chart>
      <BarContainer>
        <SimpleDataBlockBar percent={value} max={max ?? 100} />
      </BarContainer>
      <Value>{label || `${value}/${max}`}</Value>
    </Chart>
  </Container>
);

export default SimpleDataBlockChart;
