import React, { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Container, Logo, Navigation, NavItem } from './styles';
import { Props, Params } from './types';

const Navbar: FC<Props> = ({ ...rest }) => {
  const match = useRouteMatch<Params>({
    path: '/:id/',
  });

  const navItems = match
    ? [
        {
          title: 'Results summary',
          path: `/${match.params.id}/reports`,
        },
        {
          title: 'Comprehensive results',
          path: `/${match.params.id}/results`,
        },
      ]
    : [];

  return (
    <Container {...rest}>
      <Logo />
      <Navigation>
        {navItems.map(item => (
          <NavItem key={item.path} to={item.path}>
            {item.title}
          </NavItem>
        ))}
      </Navigation>
    </Container>
  );
};

export default Navbar;
