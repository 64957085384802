/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ENUM_KEYFINDING_RISK {
  high = "high",
  increased = "increased",
  low = "low",
}

export enum ENUM_PATIENT_RESULTS_TYPE {
  Final = "Final",
  Preliminary = "Preliminary",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
