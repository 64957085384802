import styled from 'styled-components';
import DefaultLogo from 'components/vectors/Logo';
import DefaultBar from 'components/Bar';

export const Container = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 5rem;
`;

export const Logo = styled(DefaultLogo)`
  max-width: 50%;
`;

export const Title = styled.h2`
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0.01rem;
  line-height: 140%;
  margin-top: 10rem;
`;

export const Description = styled.p`
  font-size: 1.5rem;
  line-height: 160%;
  margin-top: 2rem;
`;

export const Results = styled.div`
  border-top: 0.125rem solid rgba(255, 255, 255, 0.2);
  margin-top: 10rem;
  padding-top: 1rem;
  display: grid;
  grid-template-columns: 70% auto;
  grid-gap: 5rem;
`;

export const ResultsLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Bar = styled(DefaultBar)`
  margin-bottom: 1rem;
  margin-top: -1rem;
`;

export const Tip = styled.p`
  font-size: 0.9rem;
  line-height: 160%;
  opacity: 0.6;

  strong {
    font-weight: ${({ theme }) => theme.weights.bold};
  }

  em {
    font-style: italic;
  }
`;

export const ResultsRight = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ResultTitle = styled.span`
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: 116%;
  margin-bottom: 1rem;
`;

export const Info = styled.div`
  border-top: 0.125rem solid ${({ theme }) => theme.colors.mediumGray};
  margin-top: auto;
`;

export const Name = styled.p`
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  margin: 0.5rem 0;
`;

export const Date = styled.p`
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  opacity: 0.6;
  margin: 0;
`;
